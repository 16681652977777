import React from 'react'
import PropTypes from 'prop-types'
import SvgAnimationWrapper from '../../../components/SvgAnimationWrapper'

const techcrunch = ({ className, entered, width, delay }) => (
  <SvgAnimationWrapper
    entered={entered}
    className={className}
    viewBox="0 0 923.9 131"
    width={width}
    strokeOffset={520}
    delay={delay}
    strokeWidth={3}
  >
    <g>
      <path d="M347.4,51.4v57.7h-21.1V51.4H305V33.1h63.6v18.4H347.4z" />
      <path
        d="M417.8,86.2h-33.4c0.7,6.3,4.3,9.1,9.7,9.1c5,0,9.1-1.1,14.8-4l7.5,12.5c-7.1,4.1-14.3,6.5-23.2,6.5
			c-17.2,0-26.6-12.2-26.6-29.6c0-19.6,11.1-29.7,26.1-29.7c15.6,0,25.4,10.3,25.4,31.5C417.9,84,417.9,85.2,417.8,86.2z M392,65.3
			c-4.5,0-6.9,3-7.6,8.7h16.3C399.7,68.2,397.7,65.3,392,65.3z"
      />
      <path
        d="M454.2,110.3c-16.7,0-26.7-10.6-26.7-29.9c0-17.5,8.9-29.5,27.1-29.5c10.8,0,16.9,5,21.9,13l-13.5,10.2
			c-2.7-5-4.6-7.4-8.8-7.4c-5,0-7.5,5-7.5,14c0,9,2.7,13.7,8,13.7c3.7,0,6.3-2.1,9.9-7.4l12.4,9.7
			C470.5,106.5,464.3,110.3,454.2,110.3z"
      />
      <path
        d="M520.8,109.1V76.7c0-7.4-2.5-9.3-6.5-9.3c-4.1,0-6.6,2-6.6,9.1v32.6h-19.5V37.7l19.5-7.8v26
			c3.6-2.7,7.6-5,14.3-5c12.5,0,18.5,8.4,18.5,23.3v34.9H520.8z"
      />
      <path
        d="M587.3,110.3c-22.6,0-34.6-16.7-34.6-39.2c0-24.4,14.3-39.2,34.7-39.2c18.7,0,26.1,8,31.3,22.4l-19.1,7.5
			c-2.7-7.1-5.3-11.5-12.3-11.5c-8.8,0-12.6,8.8-12.6,20.9c0,11.6,3.7,20.8,12.8,20.8c6.6,0,9.4-3.6,13.6-10.8l17.8,9.4
			C613.8,100.6,605.3,110.3,587.3,110.3z"
      />
      <path
        d="M667,73.6c-2.5-2.7-5.3-4.8-9.1-4.8c-4.8,0-7.7,2.3-7.7,9.2v31.1h-19.5v-57h19.5v4.1
			c3.4-3.3,7.2-5.3,12.2-5.3c4.1,0,6.9,1,9.2,2.4L667,73.6z"
      />
      <path
        d="M713.4,109.1v-3.8c-3.5,2.7-7.6,5-14.2,5c-12.5,0-18.5-8.4-18.5-23.3V52.1h19.7v32.4c0,7.4,2.5,9.3,6.5,9.3
			c4,0,6.5-2,6.5-9.1V52.1h19.7v57L713.4,109.1L713.4,109.1z"
      />
      <path
        d="M779.9,109.1V76.7c0-7.4-2.5-9.3-6.5-9.3c-4.1,0-6.6,2-6.6,9.1v32.6h-19.5v-57h19.5v3.8c3.6-2.7,7.6-5,14.3-5
			c12.5,0,18.5,8.4,18.5,23.3v34.9H779.9z"
      />
      <path
        d="M837.6,110.3c-16.7,0-26.7-10.6-26.7-29.9c0-17.5,8.9-29.5,27.1-29.5c10.8,0,16.9,5,21.9,13l-13.5,10.2
			c-2.7-5-4.6-7.4-8.8-7.4c-5,0-7.5,5-7.5,14c0,9,2.7,13.7,8,13.7c3.7,0,6.3-2.1,9.9-7.4l12.4,9.7
			C853.9,106.5,847.7,110.3,837.6,110.3z"
      />
      <path
        d="M904.3,109.1V76.7c0-7.4-2.5-9.3-6.5-9.3c-4.1,0-6.6,2-6.6,9.1v32.6h-19.5V37.7l19.5-7.8v26
			c3.6-2.7,7.6-5,14.3-5c12.5,0,18.5,8.4,18.5,23.3v34.9H904.3z"
      />
    </g>
    <g>
      <polygon points="0,0 0,44 44,44 44,131 87,131 87,44 131,44 131,0 		" />
      <polygon points="173,89 173,44 131,44 131,131 260,131 260,89 		" />
      <rect x="173" width="87" height="44" />
    </g>
  </SvgAnimationWrapper>
)

techcrunch.propTypes = {
  className: PropTypes.string,
  entered: PropTypes.bool,
  width: PropTypes.number,
  delay: PropTypes.number,
}

export default techcrunch
