import React from 'react'
import styled from 'styled-components'

import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Hero from '../components/Hero'
import ImageListSection from '../components/ImageListSection'
import ImageSlider from '../components/ImageSlider'
import SectionIndicatorBase from '../components/SectionIndicator'
import TextSliderBase from '../components/TextSlider'
import ThreeModelView from '../components/ThreeModelView'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Section from '../components/Section'
/* awards list images import */
import awww from '../static/images/awards/awww'
import behance from '../static/images/awards/behance'
import caarts from '../static/images/awards/caarts'
import cddda from '../static/images/awards/cddda'
import fast from '../static/images/awards/fast'
import forbes from '../static/images/awards/forbes'
import fwa from '../static/images/awards/fwa'
import incmagazine from '../static/images/awards/incmagazine'
import invision from '../static/images/awards/invision'
import mindsparkle from '../static/images/awards/mindsparkle'
import mtn from '../static/images/awards/mtn'
import smashing from '../static/images/awards/smashing'
import techcrunch from '../static/images/awards/techcrunch'
import tnw from '../static/images/awards/tnw'
import uxpin from '../static/images/awards/uxpin'
/* client list images import */
// import accenture from '../static/images/clients/accenture.svg'
import accenture from '../static/images/clients/accenture'
import bcx from '../static/images/clients/bcx'
import brainfarm from '../static/images/clients/brainfarm'
import cashflow_capital from '../static/images/clients/cashflow-capital'
import colab from '../static/images/clients/colab'
import discovery from '../static/images/clients/discovery'
import jacarandafm from '../static/images/clients/jacarandafm'
import lifetech from '../static/images/clients/lifetech'
import menlynmaine from '../static/images/clients/menlyn-maine'
import motorhappy from '../static/images/clients/motorhappy'
import yoco from '../static/images/clients/yoco'
import ranktank from '../static/images/clients/ranktank'
import redbull from '../static/images/clients/redbull'
import vitality from '../static/images/clients/vitality'
import wunderman from '../static/images/clients/wunderman'
/* culture slider images */
import culture_1 from '../static/images/culture/1.jpg'
import culture_10 from '../static/images/culture/10.jpg'
import culture_11 from '../static/images/culture/11.jpg'
import culture_12 from '../static/images/culture/12.jpg'
import culture_13 from '../static/images/culture/13.jpg'
import culture_14 from '../static/images/culture/14.jpg'
import culture_15 from '../static/images/culture/15.jpg'
import culture_2 from '../static/images/culture/2.jpg'
import culture_3 from '../static/images/culture/3.jpg'
import culture_4 from '../static/images/culture/4.jpg'
import culture_5 from '../static/images/culture/5.jpg'
import culture_6 from '../static/images/culture/6.jpg'
import culture_7 from '../static/images/culture/7.jpg'
import culture_8 from '../static/images/culture/8.jpg'
import culture_9 from '../static/images/culture/9.jpg'
import AboutRockGif from '../static/images/gifs/about_rock.gif'
/* people slider images import */
import bevan from '../static/images/people/bevan.jpg'
import gerhard from '../static/images/people/gerhard.jpg'
import ilian from '../static/images/people/ilian.jpg'
import jason from '../static/images/people/jason.jpg'
import ryan from '../static/images/people/ryan.jpg'
import sphiwe from '../static/images/people/sphiwe.jpg'
import { gutter, screenSizes, colors } from '../theme'

const TextSlider = styled(TextSliderBase)`
  padding: 50px ${gutter.mobile}px;

  @media (min-width: ${screenSizes.tablet}px) {
    padding: 70px ${gutter.tablet}px;
  }

  @media (min-width: ${screenSizes.desktop}px) {
    padding: 10vh ${gutter.desktop}px;
  }

  @media (min-width: ${screenSizes.desktopLarge}px) {
    padding: 10vh ${gutter.desktopLarge}px;
  }
`

const ETHOS_SLIDES = [
  {
    title: 'No BS.',
    body: `We respect each other, our partners and the creative process; and expect the same respect in return. At Teamgeek, all differences, ideas and views are heard and valued.`,
  },
  {
    title: 'Ownership',
    body: (
      <span>
        Ownership is owning your own shit. We only hire self-starters &mdash;
        people who thrive in an autonomous culture, take responsibility for
        their decisions and can own a role and run with it.
      </span>
    ),
  },
  {
    title: 'Collaborative',
    body: `We’re a flat company, with no hierarchy and no high horses. We believe in the team. Everyone in the business, as well as our partners, have a place and a voice around the table.`,
  },
  {
    title: 'Pitch, please',
    body: `We don’t pitch for work. It’s just not how we do business. Our way of working is collaborative, and pitches simply don’t allow for us to get to know your business and brand inside and out.`,
  },
  {
    title: 'We the People',
    body: `We love all things AI, but there’s nothing quite like a human touch. We build things that make people's lives simpler, better or cooler through design and technology.`,
  },
]

const PEOPLE_SLIDES = [
  {
    bodyTitle: 'Ryan Brüssow',
    bodyText: 'CEO  /  ECD',
    image: ryan,
  },
  {
    bodyTitle: 'Ilian Todorov',
    bodyText: 'Senior Tech Lead',
    image: ilian,
  },
  {
    bodyTitle: 'Bevan Wentzel',
    bodyText: 'Chief Operating Officer',
    image: bevan,
  },
  {
    bodyTitle: 'Sphiwe Molala',
    bodyText: 'Tech Lead',
    image: sphiwe,
  },
  {
    bodyTitle: 'Gerhard janse van Rensburg',
    bodyText: 'Senior Tech Lead',
    image: gerhard,
  },
  {
    bodyTitle: 'Jason Che',
    bodyText: 'Senior Tech Lead',
    image: jason,
  },
]

const CULTURE_SLIDES = [
  {
    image: culture_1,
    footer: '',
  },
  {
    image: culture_2,
    footer: '',
  },
  {
    image: culture_3,
    footer: '',
  },
  {
    image: culture_4,
    footer: '',
  },
  {
    image: culture_5,
    footer: '',
  },
  {
    image: culture_6,
    footer: '',
  },
  {
    image: culture_7,
    footer: '',
  },
  {
    image: culture_8,
    footer: '',
  },
  {
    image: culture_9,
    footer: '',
  },
  {
    image: culture_10,
    footer: '',
  },
  {
    image: culture_11,
    footer: '',
  },
  {
    image: culture_12,
    footer: '',
  },
  {
    image: culture_13,
    footer: '',
  },
  {
    image: culture_14,
    footer: '',
  },
  {
    image: culture_15,
    footer: '',
  },
]

const AWARDS = [
  { src: fwa, extraProps: { width: 100 } },
  { src: awww, extraProps: { width: 150 } },
  { src: cddda, extraProps: { width: 180 } },
  { src: invision, extraProps: { width: 120 } },
  { src: fast, extraProps: { width: 150 } },
  { src: techcrunch, extraProps: { width: 180 } },
  { src: uxpin, extraProps: { width: 70 } },
  { src: behance, extraProps: { width: 120 } },
  { src: tnw, extraProps: { width: 90 } },
  { src: forbes, extraProps: { width: 100 } },
  { src: incmagazine, extraProps: { width: 70 } },
  { src: smashing, extraProps: { width: 100 } },
  { src: mtn, extraProps: { width: 70 } },
  { src: caarts, extraProps: { width: 140 } },
  { src: mindsparkle, extraProps: { width: 140 } },
]

const ThreeModelContainer = styled.div`
  position: absolute;
  right: 0;
  max-width: 2048px;
  max-height: 1080px;
  z-index: 1;
  width: 100%;
  height: 100vh;
`

const Gif = styled.img`
  position: absolute;
  transition: all ease 300ms;
  opacity: ${props => (props.active ? '1' : '0')};
  top: 20vh;
  right: calc(35vw - 20px);
  z-index: -1;
  height: 50vh;
  display: none;

  @media (min-width: ${screenSizes.tablet}px) {
    display: block;
  }
`

const CLIENTS = [
  { src: yoco, extraProps: { width: 100 } },
  { src: colab, extraProps: { width: 100 } },
  { src: menlynmaine, extraProps: { width: 50 } },
  { src: discovery, extraProps: { width: 150 } },
  { src: lifetech, extraProps: { width: 150 } },
  { src: redbull, extraProps: { width: 120 } },
  { src: wunderman, extraProps: { width: 200 } },
  { src: bcx, extraProps: { width: 60 } },
  { src: jacarandafm, extraProps: { width: 180 } },
  { src: vitality, extraProps: { width: 100 } },
  { src: brainfarm, extraProps: { width: 120 } },
  {
    src: accenture,
    extraProps: { width: 150, style: { marginBottom: 18 } },
  },
  { src: cashflow_capital, extraProps: { width: 140 } },
  { src: motorhappy, extraProps: { width: 150 } },
  { src: ranktank, extraProps: { width: 80 } },
]

const AwardsList = styled(ImageListSection)`
  padding-top: 150px;
`

const SectionIndicator = styled(SectionIndicatorBase)`
  vertical-align: middle;

  @media (min-width: ${screenSizes.desktop}px) {
    margin-bottom: 0;
    padding-left: 20px;
  }
`

class AboutPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      partyMode: false,
    }
    this.handlePartyMode = this.handlePartyMode.bind(this)
    this.onMenuActive = this.onMenuActive.bind(this)
  }

  handlePartyMode() {
    this.setState({ partyMode: true })
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeout)
  }

  onMenuActive(menuActive) {
    if (menuActive) {
      this.model.stop()
      // this.hero.rollingText.stop()
    } else {
      this.timeout = window.setTimeout(() => {
        // this.hero.rollingText.start()
        this.model.start()
      }, 500)
    }
  }

  render() {
    const { partyMode } = this.state

    return (
      <Layout onMenuActive={this.onMenuActive}>
        <SEO title="About" keywords={[`Teamgeek`]} />
        <ThreeModelContainer>
          <ThreeModelView
            ref={model => (this.model = model)}
            src="/models/hand.glb"
            onPartyModeEngaged={this.handlePartyMode}
            partyModeEnabled={partyMode}
            rotationSpeed={{ x: 0.8, y: 0.5 }}
            cameraPos={{
              x: 0,
              y: 3,
              z: 15,
            }}
            cameraPosResponsive={{
              [screenSizes.desktop]: {
                x: -4,
                y: 2.3,
                z: 13,
              },
              [screenSizes.desktopExtraLarge]: {
                x: -3,
                y: 2.3,
                z: 13,
              },
            }}
          />
        </ThreeModelContainer>
        <Gif active={partyMode} src={AboutRockGif} alt="Teamgeek" />
        <Hero
          ref={hero => {
            this.hero = hero
          }}
          body={`We’re a tech agency of software savants, who breathe existence into digital experiences using code and wizardry.
          `}
          rollTitle
        />
        <ImageSlider
          slides={PEOPLE_SLIDES}
          sectionTitle="Leadership"
          swiperTitle={['Leadership Team']}
          titleGridArea="1 / 2 / 3 / 6"
          bodyGridArea="10 / 2 / 10 / 12"
          autoWidth
        />
        <TextSlider slides={ETHOS_SLIDES} sectionTitle="Ethos" />
        <ImageListSection title="Clients" description=" " imageList={CLIENTS} />
        <ImageSlider
          autoWidth
          hideSlideNumber
          slideOpacity={1}
          activeSlideOpacity={1}
          slides={CULTURE_SLIDES}
          renderSectionTitle={() => (
            <a
              href="https://www.instagram.com/teamgeek.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                style={{
                  verticalAlign: 'middle',
                  marginTop: 2,
                  fontSize: '20px',
                }}
                size="1x"
                icon={faInstagram}
              />
              <SectionIndicator>Pics</SectionIndicator>
            </a>
          )}
          swiperParamsOveride={{
            freeModeSticky: false,
            freeModeMomentumRatio: 0.8,
            autoHeight: true,
            breakpoints: {
              [screenSizes.tablet]: {
                touchRatio: 3,
                autoHeight: false,
              },
            },
          }}
          extraSpaceBetween={60}
          footerOnSlide
        />
        <Section bodyBackgroundColor={colors.primary}>
          <AwardsList
            title="Toot, Toot, Toot."
            description="Ready? We’re about to toot our own horn. We’re proud of the company and clients we keep - innovative organisations making real impact in their respective industries, and to everyday lives. Working with partners like these have allowed us to create work worthy of respected international awards. We’re super proud of our metal, the media recognition and merits. Tooting over."
            imageList={AWARDS}
          />
        </Section>
      </Layout>
    )
  }
}

export default AboutPage
