import PropTypes from 'prop-types'
import React from 'react'

import SvgAnimationWrapper from '../../../components/SvgAnimationWrapper'

const cashflowcapital = ({ className, entered, width, delay }) => (
  <SvgAnimationWrapper
    entered={entered}
    className={className}
    width={width}
    viewBox="0 0 270 62.2"
    strokeOffset={280}
    delay={delay}
  >
    <g>
      <polygon points="34.8,24.1 40.5,24.1 49.6,1 43.9,1 " />
      <polygon points="59.1,24.1 53.4,24.1 44.3,1 50,1 " />
      <path
        d="M17.5,8.4c-0.8-2.1-2.9-3.9-5.8-3.9c-4,0-6.9,3.2-6.9,7.6c0,4.3,2.9,7.4,6.9,7.4
	c2.9,0,5.2-1.6,6.2-3.7l4.3,2c-1.8,3.9-5.8,6.3-10.5,6.3C5.3,24.1,0,18.8,0,12.1S5.3,0,11.7,0c7.1,0,9.6,5.3,10.1,6.8L17.5,8.4z"
      />
      <path
        d="M84.5,6.9C84,5.2,82,4.3,80.3,4.3c-1.6,0-3,0.7-3.5,2.2c-0.4,1.2,0.2,2.6,2.1,3
	c1.1,0.3,2.2,0.4,3.2,0.6c4,0.7,6.4,3.2,6.4,7c0,4.7-4.2,7.1-8.4,7.1c-4,0-8.3-2.1-8.5-6.4l4.2-0.6c0.3,1.9,2.6,2.8,4.4,2.8
	c2.1,0,3.8-1,3.8-2.7c0-1-0.6-2-1.8-2.4c-1-0.4-3.1-0.7-4.5-1c-3.3-0.7-5.3-2.9-5.3-6.6C72.4,3.1,76,0,80.2,0c4.5,0,7.9,2.7,8.5,5.8
	L84.5,6.9z"
      />
      <polygon
        points="123.1,0.5 123.1,23.7 118.9,23.7 118.9,14 109.1,14 109.1,23.7 104.9,23.7 104.9,0.5
	109.1,0.5 109.1,9.9 118.9,9.9 118.9,0.5 "
      />
      <polygon
        points="145,4.7 145,10.3 153.8,10.3 153.8,14.4 145,14.4 145,23.7 140.8,23.7 140.8,0.5 145,0.5
	155.4,0.5 155.4,4.8 "
      />
      <polygon points="186.4,19.5 186.4,23.7 172.5,23.7 171.9,23.7 171.9,0.5 176.1,0.5 176.1,19.5 " />
      <path
        d="M217.2,12.2c0-4.6-2.6-7.6-6.8-7.6c-4,0-6.7,3.1-6.7,7.6s2.7,7.4,6.7,7.4
	C214.7,19.6,217.2,16.8,217.2,12.2 M222,12.2c0,7-4.9,12-11.5,12c-6.4,0-11.5-5.1-11.5-12c0-7,4.9-12.2,11.4-12.2
	C217,0,222,5.2,222,12.2"
      />
      <polygon
        points="270,0.5 262.1,23.7 257.8,23.7 252.2,7.7 246.6,23.7 242.3,23.7 234.5,0.5 239.5,0.5
	244.5,16.9 249.7,0.5 254.7,0.5 259.9,16.9 264.9,0.5 "
      />
      <g id="Group-19" transform="translate(0.000000, 45.997191)">
        <g id="Clip-18" />
        <path
          d="M7.6,1.1c2.8,0,4.9,1.6,5.8,3.9l1-0.4C13.4,2,10.8,0,7.6,0C3.4,0,0,3.7,0,8.1
		c0,4.5,3.4,8.1,7.6,8.1c3,0,5.5-1.7,6.6-4.2l-0.9-0.4c-1,2-3.1,3.5-5.7,3.5C4,15.2,1,12,1,8.1C1,4.3,4,1.1,7.6,1.1"
        />
      </g>
      <path
        d="M49.2,56.2l3.8-9.1l3.7,9.1C56.7,56.2,49.2,56.2,49.2,56.2z M53.3,46.4h-1l-6.4,15.5h1l2-4.8h8.2
	l2,4.8h1l-6.4-15.5H53.3z"
      />
      <path
        d="M98.9,54.6h-4.3v-7.2h4.3c2.3,0,3.7,1.2,3.7,3.6C102.6,53.2,101.1,54.6,98.9,54.6 M99,46.4h-4.5
	h-1v15.5h1v-6.3H99c2.7-0.1,4.5-1.9,4.5-4.6l0,0C103.6,48,101.8,46.5,99,46.4"
      />
      <g id="Clip-25" />
      <polygon points="137.7,61.9 138.7,61.9 138.7,46.4 137.7,46.4 " />
      <polygon
        points="172.3,46.4 172.3,47.4 177.9,47.4 177.9,61.9 178.9,61.9 178.9,47.4 184.4,47.4
	184.4,46.4 178.9,46.4 "
      />
      <path
        d="M217.4,56.2l3.8-9.1l3.7,9.1C224.9,56.2,217.4,56.2,217.4,56.2z M221.5,46.4h-1l-6.4,15.5h1l2-4.8
	h8.2l2,4.8h1l-6.4-15.5H221.5z"
      />
      <polygon points="262.8,60.9 262.8,46.4 261.8,46.4 261.8,61.9 261.9,61.9 269.2,61.9 269.2,60.9 " />
    </g>
  </SvgAnimationWrapper>
)

cashflowcapital.propTypes = {
  className: PropTypes.string,
  entered: PropTypes.bool,
  width: PropTypes.number,
  delay: PropTypes.number,
}

export default cashflowcapital
