import React from 'react'
import PropTypes from 'prop-types'
import SvgAnimationWrapper from '../../../components/SvgAnimationWrapper'

const incmagazine = ({ className, entered, width, delay }) => (
  <SvgAnimationWrapper
    entered={entered}
    className={className}
    viewBox="0 0 799.9 276.4"
    width={width}
    strokeOffset={1500}
    delay={delay}
    strokeWidth={3}
  >
    <g>
      <polygon
        points="0,0 0,63.6 35.4,63.6 35.4,212.1 0,212.1 0,271.2 188.7,271.2 188.7,212.1 153.4,212.1 153.4,63.6
		188.7,63.6 188.7,0 	"
      />
      <path
        d="M198.5,63.7v59.2h26.7v89.2h-26.7v59.1h147.7v-59.1H318v-51.2c0-21.7,16.3-37.7,38.1-37.7
		c17.1,0,30.4,8.4,30.4,27v61.9h-27.4v59.1h144.2V213c-0.1-0.3-0.3-0.6-0.4-0.9h-23.6v-78.2c0-49.9-36.5-68.1-81.4-68.1
		c-32,0-56.3,8.8-77.6,33.1c-1.5,1.9-3.4,4.2-5.3,5.7V63.7H198.5z"
      />
      <path
        d="M625.3,65.1c-65.8,0-130.5,32.3-130.5,105.7c0,15.6,3,29.4,8.2,41.3h0.4v0.9c19.7,43.7,70.5,63.4,121.9,63.4
		c41.9,0,100.2-10.1,113.3-74.6l0.6-6.9l-76.8,0l-2.6,0c0,21.8-15.8,32.1-33.8,32.1c-34.3,0-36.9-28.6-36.9-56v-0.6v0
		c0-27.4,2.7-55.9,36.9-55.9c26.9,0,31.5,23.4,32.5,45.5l79.7-8.8C729.7,70.1,649.8,65,629.8,65C626.9,65,625.3,65.1,625.3,65.1"
      />
      <path
        d="M732.7,238.6c0,18.6,15.1,33.6,33.6,33.6c18.6,0,33.6-15,33.6-33.6c0-18.6-15.1-33.6-33.6-33.6
		C747.7,205,732.7,220.1,732.7,238.6"
      />
    </g>
  </SvgAnimationWrapper>
)

incmagazine.propTypes = {
  className: PropTypes.string,
  entered: PropTypes.bool,
  width: PropTypes.number,
  delay: PropTypes.number,
}

export default incmagazine
