import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import Swiper from 'react-id-swiper'

import { gutter, screenSizes, colors } from '../theme'
import SectionIndicatorBase from './SectionIndicator'
import RollingText from './RollingText'
import JustifiedText from './JustifiedText'
import WithScrollAnimation from './WithScrollAnimation'

const Container = WithScrollAnimation(
  styled.div`
    display: grid;
    margin-top: 50px;
    padding-top: 100px;
    padding: 0px ${gutter.mobile}px;
    grid-row-gap: 0px;
    grid-template-columns: repeat(1, 1fr);
    opacity: 0;
    transform: translate(0, 100px);
    transition: all ease 500ms;

    .svg-inline--fa {
      float: right;
    }

    @media (min-width: ${screenSizes.tablet}px) {
      grid-template-columns: repeat(2, 1fr) ${gutter.tablet}px;
      padding-left: ${gutter.tablet}px;
      padding-right: 0;
    }

    @media (min-width: ${screenSizes.desktop}px) {
      .svg-inline--fa {
        float: none;
      }
      grid-row-gap: 20px;
      padding-left: ${gutter.desktop}px;
      padding-right: 0;
      grid-template:
        30px repeat(8, minmax(50px, 5vh)) 30px / 120px repeat(9, 1fr)
        auto ${gutter.desktop}px;
    }

    @media (min-width: ${screenSizes.desktopLarge}px) {
      padding-left: ${gutter.desktopLarge}px;
      grid-template:
        30px repeat(8, minmax(30px, 6vh)) 30px / 120px repeat(9, 1fr)
        auto ${gutter.desktopLarge}px;
    }

    ${props =>
      props.entered &&
      `
      transform: translate(0, 0);
      opacity: 1;
    `}
  `
)

const SwiperContainer = styled.div`
  height: 100%;
  width: 100%;
  grid-area: 2 / 1 / 2 / 3;
  margin-top: 30px;

  .swiper-slide {
    max-width: 100%;
    opacity: ${props => props.slideOpacity};
    transition: all linear 500ms;
    overflow: hidden;
    &:last-child {
      min-width: 100%;
    }

    p {
      font-size: 12pt;
      text-align: left;
    }
  }

  .swiper-slide-active {
    opacity: ${props => props.activeSlideOpacity};
  }

  .swiper-container {
    width: 100%;
    height: 100%;
  }

  .swiper-wrapper {
    padding-right: 30vw;
  }

  img {
    height: auto;
    width: 100%;
    pointer-events: none;
    transition: all linear 500ms;
  }

  @media (min-width: ${screenSizes.tablet}px) {
    grid-area: 2 / 1 / 2 / 4;
    .swiper-slide {
      width: ${props =>
        props.autoWidth ? 'auto' : 'calc(100% - 120px - 60px)'} !important;
      margin-right: 60px;
      height: 600px;
    }

    img {
      width: auto;
      height: 100%;
    }
  }

  @media (min-width: ${screenSizes.desktop}px) {
    grid-area: 2 / 2 / 10 / 13;
    margin-top: 0;
    .swiper-slide {
      width: ${props => (props.autoWidth ? 'auto' : '400px')} !important;
      height: 100%;
      margin-right: ${props =>
        props.extraSpaceBetween ? 60 + props.extraSpaceBetween : 60}px;
    }
    ${props =>
      props.active &&
      css`
        .swiper-slide {
          &.swiper-slide-active,
          &.swiper-slide-next,
          &.swiper-slide-prev {
            margin-right: ${props.extraSpaceBetween
              ? 15 + props.extraSpaceBetween
              : 15}px;
          }
          img {
            transform: scale(1.1);
          }
        }
      `}
  }

  @supports (-moz-appearance: none) {
    & {
      .swiper-slide p {
        text-align: left !important;
      }
    }
  }
`

const TitleContainer = styled.div`
  display: none;
  @media (min-width: ${screenSizes.desktop}px) {
    display: block;
    grid-area: ${({ titleGridArea }) =>
      titleGridArea ? titleGridArea : `3 / 1 / 3 / 6`};
  }
`

const TitleText = styled.h2`
  font-size: 30px;
  line-height: 30pt;
`

const BodyHeader = styled.p`
  pointer-events: none;
  z-index: 5;
  grid-row-start: 5;
  font-size: 10pt;
  margin-bottom: 0;

  @media (min-width: ${screenSizes.desktop}px) {
    grid-area: 6 / 5 / 6 / 8;
  }
`

const BodyContainer = styled.div`
  pointer-events: none;
  z-index: 5;
  grid-column-start: 1;

  p {
    margin-bottom: 0;
  }

  span {
    word-wrap: break-word;
  }

  @media (min-width: ${screenSizes.tablet}px) {
    grid-area: 3 / 1 / 3 / 4;
  }

  @media (min-width: ${screenSizes.desktop}px) {
    grid-area: ${({ gridArea }) => (gridArea ? gridArea : `5 / 5 / 5 / 12`)};
  }
`

const BodyTitle = styled.h3`
  margin-bottom: 5px;

  span {
    font-size: 16pt;
  }

  @media (min-width: ${screenSizes.tablet}px) {
    margin-bottom: 0px;
  }
`

const BodyText = styled.p`
  margin-bottom: 10px;

  span {
    font-size: 10pt;
  }
`

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (min-width: ${screenSizes.desktop}px) {
    grid-area: 10 / 1 / 10 / 12;
    align-self: center;
  }
`

const SlideNumberContainer = styled.div`
  display: flex;
  grid-area: 1 / 2 / 1 / 2;
  justify-content: flex-end;
  p,
  span {
    line-height: 7pt;
    font-size: 7pt;
    font-weight: normal;
    margin-bottom: 0;
  }

  @media (min-width: ${screenSizes.desktop}px) {
    grid-area: 1 / 8 / 1 / 11;
  }
`

const SectionTitleContainer = styled.div`
  grid-area: 1 / 1 / 1 / 3;

  @media (min-width: ${screenSizes.desktop}px) {
    grid-area: 1 / 11 / 1 / 11;
  }
`

const SectionIndicator = styled(SectionIndicatorBase)`
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  line-height: 7pt;
  @media (min-width: ${screenSizes.desktop}px) {
    margin-bottom: 0;
    padding-left: 40px;
    color: ${colors.primaryText};
  }
`

const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: ${screenSizes.desktop}px) {
    grid-area: 10 / 11 / 10 / 12;
  }
`

class ImageSlider extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeSlide: 0,
      animate: false,
      progress: 0,
    }

    this.timeout = null
    this.swiper = null
    this.progressBar = null
    this.updateSliderProgress = this.updateSliderProgress.bind(this)
    this.onSliderMove = this.onSliderMove.bind(this)
    this.onSlideChangeEnd = this.onSlideChangeEnd.bind(this)
    this.onTouchStart = this.onTouchStart.bind(this)
    this.onTouchEnd = this.onTouchEnd.bind(this)
    this.onResize = this.onResize.bind(this)
  }

  static propTypes = {
    slides: PropTypes.array,
    swiperTitle: PropTypes.array,
    sectionTitle: PropTypes.string,
    footerText: PropTypes.string,
    bodyGridArea: PropTypes.string,
    titleGridArea: PropTypes.string,
    autoWidth: PropTypes.bool,
    activeSlideOpacity: PropTypes.number,
    slideOpacity: PropTypes.number,
    swiperParamsOveride: PropTypes.object,
    footerOnSlide: PropTypes.bool,
    hideSlideNumber: PropTypes.bool,
    extraSpaceBetween: PropTypes.number,
    renderSectionTitle: PropTypes.func,
  }

  static defaultProps = {
    autoWidth: false,
    activeSlideOpacity: 1,
    slideOpacity: 1,
    extraSpaceBetween: 0,
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeout)
    if (this.swiper) {
      this.swiper.destroy()
    }
  }

  updateSliderProgress() {
    if (this.swiper && this.progressBar) {
      this.progressBar.updateProgress(this.swiper.progress * 100)
    }
  }

  onSliderMove(event) {
    window.clearTimeout(this.timeout)
    if (event && (event.movementX || event.type === 'touchmove')) {
      this.setState({ animate: true })
    }
  }

  onSlideChangeEnd() {
    window.clearTimeout(this.timeout)
    if (this.swiper) {
      let activeSlide = this.swiper.activeIndex
      this.setState({ activeSlide }, () => {
        this.timeout = window.setTimeout(() => {
          this.setState({ animate: false })
        }, 300)
      })
    }
  }

  onTouchStart() {
    window.clearTimeout(this.timeout)
    this.setState({ active: true })
  }

  onTouchEnd() {
    window.clearTimeout(this.timeout)
    this.setState({ active: false }, () => {
      this.timeout = window.setTimeout(() => {
        this.setState({ animate: false })
      }, 300)
    })
  }

  onResize() {
    window.setTimeout(() => {
      this.swiper.update()
    }, 500)
  }

  render() {
    const { activeSlide, animate, active } = this.state
    const {
      slides,
      swiperTitle,
      sectionTitle,
      footerText,
      titleGridArea,
      bodyGridArea,
      autoWidth,
      activeSlideOpacity,
      slideOpacity,
      swiperParamsOveride,
      footerOnSlide,
      hideSlideNumber,
      extraSpaceBetween,
      renderSectionTitle,
    } = this.props

    const swiperParams = {
      touchStartPreventDefault: false,
      breakpointsInverse: true,
      slidesPerView: 'auto',
      speed: 200,
      preventInteractionOnTransition: true,
      normalizeSlideIndex: true,
      breakpoints: {
        [screenSizes.tablet]: {
          touchRatio: 3,
        },
      },
      on: {
        slideChangeTransitionStart: this.onSliderMove,
        slideChangeTransitionEnd: this.onSlideChangeEnd,
        sliderMove: event => {
          this.onSliderMove(event)
          this.updateSliderProgress()
        },
        transitionEnd: this.onSlideChangeEnd,
        progress: this.updateSliderProgress,
        touchStart: this.onTouchStart,
        touchEnd: this.onTouchEnd,
        resize: this.onResize,
      },
      ...swiperParamsOveride,
    }

    return (
      <Container>
        <TitleContainer titleGridArea={titleGridArea}>
          {swiperTitle ? (
            <JustifiedText textList={swiperTitle} />
          ) : (
            <TitleText>{slides[activeSlide].title}</TitleText>
          )}
        </TitleContainer>

        {!hideSlideNumber && (
          <SlideNumberContainer>
            <p>
              <RollingText
                numbersOnly
                animate={animate}
                text={(activeSlide + 1).toString()}
              />{' '}
              &mdash; {slides.length}
            </p>
          </SlideNumberContainer>
        )}

        <SectionTitleContainer>
          {renderSectionTitle ? (
            renderSectionTitle()
          ) : (
            <SectionIndicator>{sectionTitle}</SectionIndicator>
          )}
        </SectionTitleContainer>

        <SwiperContainer
          active={active}
          activeSlideOpacity={activeSlideOpacity}
          slideOpacity={slideOpacity}
          autoWidth={autoWidth}
          extraSpaceBetween={extraSpaceBetween}
        >
          <Swiper
            {...swiperParams}
            ref={node => {
              if (node) this.swiper = node.swiper
            }}
          >
            {slides.map((slide, index) => {
              return (
                <div key={index}>
                  <img src={slide.image} alt="Teamgeek" />
                  <p
                    style={{
                      textTransform: 'uppercase',
                      fontSize: '8pt',
                    }}
                  >
                    {footerOnSlide && slide.footer}
                  </p>
                </div>
              )
            })}
          </Swiper>
        </SwiperContainer>

        <ProgressBarContainer>
          {/* <ProgressBar ref={ref => (this.progressBar = ref)} horizontal /> */}
        </ProgressBarContainer>

        {slides[activeSlide].bodyHeader && (
          <BodyHeader>
            <SectionIndicatorBase>
              <RollingText
                numbersOnly
                animate={animate}
                text={slides[activeSlide].bodyHeader}
              />
            </SectionIndicatorBase>
          </BodyHeader>
        )}

        <BodyContainer gridArea={bodyGridArea}>
          {slides[activeSlide].bodyTitle && (
            <BodyTitle>
              <RollingText
                animate={animate}
                text={slides[activeSlide].bodyTitle}
              />
            </BodyTitle>
          )}
          {slides[activeSlide].bodyText && (
            <BodyText>
              <RollingText
                animate={animate}
                text={slides[activeSlide].bodyText}
              />
            </BodyText>
          )}
        </BodyContainer>

        {!footerOnSlide && footerText ? (
          <Footer>
            <p>
              <SectionIndicatorBase>{footerText}</SectionIndicatorBase>
            </p>
          </Footer>
        ) : (
          !footerOnSlide &&
          (slides[activeSlide].footer && (
            <Footer>
              <p>
                <RollingText
                  animate={animate}
                  text={slides[activeSlide].footer}
                />
              </p>
            </Footer>
          ))
        )}
      </Container>
    )
  }
}

export default ImageSlider
