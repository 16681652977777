import React from 'react'
import PropTypes from 'prop-types'
import SvgAnimationWrapper from '../../../components/SvgAnimationWrapper'

const fast = ({ className, entered, width, delay }) => (
  <SvgAnimationWrapper
    entered={entered}
    className={className}
    viewBox="0 0 2279.4 339.2"
    width={width}
    strokeOffset={4700}
    delay={delay}
    strokeWidth={5}
  >
    <g>
      <path
        d="M1083.2,91.7c0,45.8-4.6,187.9-4.6,187.9c0,27.5,9.2,39.7,35.1,39.7v12.2h-102.4v-12.2
		c26,0,39.7-12.2,39.7-39.7l9.2-232.2c-7.6-18.3-15.3-24.4-35.1-26V10.7h77.9l82.5,216.9l84-216.9h70.3v10.7
		c-19.9,0-30.5,9.2-30.5,30.6l9.2,224.6c0,33.6,6.1,42.8,33.6,42.8v12.2h-117.6v-12.2c22.9,0,33.6-7.6,32.1-42.8
		c0,0-4.6-157.4-6.1-184.9l-56.5,155.8c-8.5,22-15.6,44.4-21.4,67.2l-18.3,1.5c-4.6-16.8-16.8-47.4-16.8-47.4S1100,136,1083.2,91.7
		L1083.2,91.7z M986.9,333.1c-6.1-1.5-12.2-1.5-18.3-1.5c-12.2,0-33.6,6.1-59.6,6.1c-99.3,0-172.6-59.6-172.6-169.6
		c0-93.2,68.8-165,174.2-165c24.4,0,51.9,4.6,74.9,13.7c4.6-4.6,6.1-7.6,10.7-12.2h9.2c-1.5,16.8-7.6,71.8-7.6,71.8h-9.2
		c-6.1-32.1-22.9-53.5-76.4-53.5c-64.2,0-122.2,50.4-122.2,143.6c0,105.4,59.6,151.2,125.3,151.2c42.8,0,68.8-24.4,85.6-55l10.7,4.6
		L986.9,333.1L986.9,333.1z M698.2,70.3h-10.7c-1.5-29-10.7-38.2-33.6-38.2h-44.3v239.9c0,39.7,7.6,47.4,33.6,47.4v12.2H528.6v-12.2
		c26,0,33.6-7.6,33.6-48.9V32.1h-44.3c-29,0-33.6,7.6-41.3,38.2H466l7.6-68.8l7.6,1.5c3,7.6,9.2,7.6,27.5,7.6h148.2
		c27.5,0,33.6,0,39.7-9.2h9.2L698.2,70.3L698.2,70.3z"
      />
      <path
        d="M374.3,339.2c-21.4,0-38.2-3-50.4-7.6c-12.2-3-21.4-7.6-26-10.7c-6.1,6.1-10.7,13.7-10.7,13.7l-9.2-3l7.6-71.8
		l10.7,1.5c6.1,33.6,29,58.1,76.4,58.1c36.7,0,61.1-30.5,61.1-64.2c0-27.5-10.7-41.3-47.4-68.8c-12.2-7.6-22.9-13.7-32.1-21.4
		c-33.6-21.4-55-45.8-55-85.5c0-45.8,38.2-76.4,88.6-76.4c29,0,47.4,9.2,53.5,12.2l10.7-12.2l7.6,1.5l-7.6,65.7l-10.7-1.5
		c0-30.5-18.3-44.3-48.9-44.3c-29,0-50.4,15.3-50.4,44.3c0,26,19.9,42.8,42.8,58.1c12.2,7.6,21.4,13.7,30.5,19.9
		c53.5,38.2,65.7,62.6,65.7,99.3C481.2,299.4,440,339.2,374.3,339.2L374.3,339.2z M200.1,70.3h-12.2c0-27.5-12.2-38.2-36.7-38.2
		H82.5v120.7h44.3c22.9,0,27.5-12.2,30.6-32.1H168v85.6h-10.7c-3-22.9-7.6-32.1-32.1-32.1H82.5v102.4c0,38.2,9.2,42.8,33.6,42.8
		v12.2H0v-12.2c29,0,33.6-7.6,33.6-42.8V51.9c0-21.4-9.2-30.6-33.6-30.6V10.7h158.9c27.5,0,33.6-1.5,38.2-10.7h10.7L200.1,70.3z
		 M1848.6,88.6v183.3c0,39.7,9.1,47.4,33.6,47.4v12.2h-180.3v-12.2c13.8,0,26-4.6,26-13.7c0-6.1-1.5-16.8-4.6-27.5l-13.8-44.3
		h-100.8c-1.5,6.1-19.9,56.5-19.9,70.3c0,12.2,13.8,15.3,22.9,15.3v12.2h-84v-12.2c15.3,0,27.5-1.5,38.2-33.6l77.9-249
		c-4.6-10.7-10.7-13.7-21.4-15.3V10.7h68.8l82.5,261.2c7.7,27.5,15.3,41.3,27.5,47.4c18.3-6.1,22.9-12.2,22.9-47.4v-220
		c-13.8-18.3-15.3-30.6-41.3-30.6V10.7h77.9l128.3,218.5V62.6c0-33.6-7.6-41.3-33.6-41.3V10.7h171.1v10.7
		c-10.7,0-19.9,4.6-19.9,15.3c0,7.6,3.1,15.3,6.1,24.4l48.9,105.4l47.4-102.4c7.6-13.7,9.2-21.4,9.2-29c0-9.2-6.1-13.8-19.9-13.8
		V10.7h81v10.7c-19.9,0-32.1,18.3-41.2,39.7l-62.6,128.3v84c0,36.7,9.2,45.8,33.6,45.8v12.2H2093v-12.2c24.4,0,33.6-9.2,33.6-45.8
		v-71.8l-67.2-142.1c-12.2-27.5-16.8-33.6-24.4-38.2c-15.3,6.1-19.9,16.8-19.9,41.3v235.3c0,0,1.5,18.3,3.1,39.7l-19.9,1.5
		L1848.6,88.6L1848.6,88.6z M1657.6,73.3l-42.8,139h88.6L1657.6,73.3z"
      />
      <path
        d="M1468.2,212.4h-22.9V278c0,33.6,9.1,41.3,35.1,41.3v12.2h-117.6v-12.2c24.4,0,33.6-9.2,33.6-42.8V61.1
		c0-33.6-9.2-39.7-33.6-39.7V10.7h88.6c85.5,0,135.9,26,135.9,96.2C1587.3,180.3,1526.2,212.4,1468.2,212.4z M1452.9,32.1h-7.6
		v157.3h13.7c50.4,0,76.4-22.9,76.4-81S1512.5,32.1,1452.9,32.1z M935,270.4c-58.1,0-97.8-41.3-97.8-100.8
		c0-56.5,41.3-100.8,97.8-100.8c55,0,96.2,42.8,96.2,100.8C1031.2,226.1,988.5,270.4,935,270.4z M935,87.1
		c-39.7,0-53.5,38.2-53.5,82.5c0,41.3,12.2,82.5,53.5,82.5c39.7,0,51.9-41.3,51.9-84C986.9,126.8,971.7,87.1,935,87.1z M262.8,252.1
		v-10.7c15.3,0,18.3-4.6,18.3-9.2c0-6.1-1.5-10.7-3.1-15.3c0,0-3.1-12.2-6.1-21.4h-59.6l-6.1,19.9c-1.5,4.6-3,9.2-3,15.3
		c0,6.1,6.1,10.7,15.3,10.7v10.7h-59.6v-10.7c15.3,0,21.4-4.6,27.5-21.4l45.8-137.5c-1.5-6.1-6.1-10.7-15.3-10.7V61.1h48.9
		c16.7,48.9,33.5,97.8,50.4,146.7c7.6,26,15.3,33.6,27.5,33.6v10.7H262.8z M241.4,103.9l-22.9,74.9h47.4L241.4,103.9z"
      />
    </g>
  </SvgAnimationWrapper>
)

fast.propTypes = {
  className: PropTypes.string,
  entered: PropTypes.bool,
  width: PropTypes.number,
  delay: PropTypes.number,
}

export default fast
