import React from 'react'
import PropTypes from 'prop-types'
import SvgAnimationWrapper from '../../../components/SvgAnimationWrapper'

const wunderman = ({ className, entered, width, delay }) => (
  <SvgAnimationWrapper
    entered={entered}
    className={className}
    viewBox="0 0 5512 886"
    width={width}
    strokeOffset={3200}
    delay={delay}
    strokeWidth={32}
  >
    <g>
      <path
        d="M406,886c-8.5-1.3-17-2.7-25.5-4c-49.6-7.3-96.6-22.5-140.9-45.8c-1.2-0.6-2.2-1.4-4.3-2.8
		c69.3-259,138.7-518.1,208.1-777.2c0.7,0,1.4-0.1,2.1-0.1C514.9,315,584.2,573.9,653.7,833.6c-3.3,1.9-6.4,3.7-9.6,5.3
		c-48.7,24.6-100.1,39.8-154.4,45.3c-2.6,0.3-5.1,1.1-7.7,1.7C456.7,886,431.3,886,406,886z"
      />
      <path
        d="M420,0c-6.2,23.8-12.2,47.7-18.6,71.5C340.3,299.8,279.2,528.1,218,756.4c-1.1,4-2.3,8-5.3,11.7
		c-5.5-20.5-11.1-40.9-16.5-61.4c-43-160.8-86-321.6-129.2-482.3c-1.6-6.1-1.7-11.1,2.1-16.8C142.9,94.4,246.2,26.5,379.5,4.4
		c9.5-1.6,19-2.9,28.5-4.4C412,0,416,0,420,0z"
      />
      <path
        d="M482,0c11.8,1.9,23.6,3.7,35.3,5.8c130.5,23.5,231.1,91.7,303.3,202.6c4,6.1,2.7,11.2,1.1,16.9
		c-35.5,132.5-71,265.1-106.5,397.6c-12.2,45.7-24.5,91.3-36.8,137c-0.8,2.8-1.7,5.6-4,8.5c-0.9-3.4-1.9-6.7-2.8-10.1
		C610.1,528.7,548.5,299,487,69.3c-6.2-23.1-12.7-46.2-19-69.3C472.7,0,477.3,0,482,0z"
      />
      <path
        d="M5512,674c-5.4,1.6-7.7-2.6-10.4-5.9c-25.2-30.2-50.4-60.4-75.6-90.6c-65.8-78.8-131.5-157.6-197.3-236.4
		c-2.3-2.8-4.7-5.6-8.6-10.1c-0.5,4.4-1.1,7.1-1.1,9.8c-0.1,23,0,46,0,69c0,81,0,162,0,243c0,4.2,0,8.5,0,13.1
		c-16.6,0-31.9,0-47.1,0c-2.4-8.2-3.5-411.8-1.3-448.6c8.3-5.1,8.8-5.1,14.6,1.9c78.5,93.8,157,187.6,235.5,281.3
		c12,14.3,23.9,28.6,35.9,42.9c1.9,2.2,3.9,4.2,7.5,8.1c0.2-5.8,0.5-9.5,0.5-13.3c0-101,0-202,0-302.9c0-4.2,0-8.5,0-13.3
		c16.6,0,31.9,0,47.2,0C5512,372.7,5512,523.3,5512,674z"
      />
      <path
        d="M0,414c1.3-5.4,3.1-10.8,3.7-16.3c4.5-43.4,15.2-85.1,32-125.3c0.9-2.1,2.1-4.1,4.1-8.1
		c48,179.2,95.4,356.4,143.5,535.8c-3.9-2.4-6.1-3.5-8-4.9C84.6,723.9,28.2,631.8,7.8,518c-1.9-10.5-2.7-21.1-4.3-31.6
		c-0.8-4.8-2.3-9.6-3.5-14.3C0,452.7,0,433.3,0,414z"
      />
      <path
        d="M1269,674.5c-3.6-0.4-6.5-0.7-10.1-1c-40.7-150.2-81.3-300.2-122.2-451.1c16.8,0,32.6,0,49.3,0
		c28.9,107.5,57.7,215.1,86.6,322.6c0.7,0.1,1.3,0.3,2,0.4c1.4-3.2,2.8-6.3,4.1-9.5c40.4-101.5,80.7-203,121-304.6
		c1.2-3.1,2.2-6.4,3.9-9.2c1-1.7,3.2-3.9,4.7-3.8c1.7,0.1,3.5,2.4,4.7,4c1.1,1.6,1.6,3.6,2.4,5.5c40.9,102,81.8,204,122.7,306.1
		c1.3,3.4,2.9,6.6,5.4,12.4c29.4-109.5,58.1-216.7,86.9-324.2c16.5,0,32.4,0,49.4,0c-40.8,150.7-81.5,300.7-122.2,451.1
		c-3.2,0.5-6.1,0.9-10.1,1.4c-46.5-116.4-93-232.5-140.4-351.1C1360.4,441.6,1315.2,557.6,1269,674.5z"
      />
      <path
        d="M4166.7,356.6c-1.8,102.8-3.6,205.6-5.3,309c-16.6,0-32.4,0-49.3,0c5.7-150.5,11.3-300.5,16.9-450.3
		c9.3-2.1,9.4-2.1,13.8,5.6c35.2,61.2,70.4,122.4,105.7,183.6c25.6,44.5,51.2,88.9,76.8,133.4c1.6,2.8,3.3,5.5,5.7,9.4
		c2.7-4.6,4.7-8,6.6-11.4c58.9-104.9,117.7-209.8,176.6-314.6c4.4-7.8,4.4-7.8,12.9-6.6c3.3,6.2,17.7,435.6,17.9,450.4
		c-7.7,2.3-15.7,0.8-23.5,1.1c-8,0.2-15.9,0.1-25.1,0.1c-2.2-103.6-4.5-206.6-6.7-309.5c-0.5-0.2-1.1-0.3-1.6-0.5
		c-1.7,2.7-3.6,5.2-5.2,8c-48.4,82.5-96.9,164.9-144.9,247.5c-7,12.1-11,12.4-18.4-0.3c-48-82.3-96.6-164.2-145-246.3
		c-1.8-3.1-3.8-6.1-5.7-9.1C4168.1,356.2,4167.4,356.4,4166.7,356.6z"
      />
      <path
        d="M2276.3,665.6c0-150.4,0-299.6,0-448.7c9-4.5,9.1-4.5,15,2.6c67.8,81,135.6,162,203.4,243
		c22.5,26.8,44.9,53.7,67.4,80.5c2.1,2.5,4.3,4.8,7.6,8.5c1.8-13.2,0.7-25,0.9-36.7c0.2-12.3,0-24.7,0-37c0-12,0-24,0-36
		c0-12.3,0-24.7,0-37c0-12,0-24,0-36c0-12.3,0-24.7,0-37c0-12,0-24,0-36c0-12.3,0-24.7,0-37c0-11.9,0-23.9,0-36.4
		c16.5,0,32,0,48.2,0c0.2,3,0.6,5.9,0.6,8.8c0,144.3,0,288.6,0,432.9c0,1.3,0.3,2.8-0.1,4c-0.7,2-1.7,5.4-2.8,5.5
		c-2.2,0.2-5-1-6.7-2.5c-2.9-2.6-5.3-6-7.8-9.1c-89.4-107.2-178.9-214.4-268.3-321.6c-2.3-2.8-4.7-5.5-6.8-8c-1,0.2-1.3,0.2-1.6,0.4
		c-0.3,0.1-0.6,0.4-0.6,0.6c-0.2,0.6-0.2,1.3-0.3,2c0,1,0,2,0,3c0,107.6,0,215.3-0.1,322.9c0,0.3,0,0.7-0.1,1
		c-0.1,0.7-0.2,1.3-0.4,2c-0.1,0.3-0.3,0.5-0.9,1.4C2308.1,665.6,2292.7,665.6,2276.3,665.6z"
      />
      <path
        d="M2777.2,662.5c0-145.6,0-290.4,0-436.4c9.2-1.1,18.4-2.6,27.5-3.4c39.9-3.3,79.8-6.1,119.8-2.7
		c45.9,3.9,89.2,16.2,127.5,42.4c58.9,40.3,90.8,96.3,94.1,167.7c2.9,63.3-15.4,119.8-58.9,166.8c-31.5,34.1-71.2,54.2-115.9,65
		c-36.3,8.8-73.3,9.3-110.3,7.4c-25.6-1.3-51.2-3.7-76.7-5.7C2782.2,663.5,2780.3,663,2777.2,662.5z M2825.5,264.4
		c0,117,0,232.2,0,347.5c0,3.3,0.4,6.6,0.6,9.9c0,0.3,0.4,0.5,0.6,0.8c0.2,0.3,0.5,0.5,1.7,1.8c14.1,1.1,29.4,2.7,44.6,3.2
		c29.3,1.1,58.7,1.2,87.5-5.7c64.6-15.5,106.9-54.6,125-118.8c8-28.5,10-57.6,6.8-87.1c-7.7-71.9-51.8-125.2-121.1-145.8
		c-35.7-10.6-72.2-11.5-108.9-9.1C2850.4,261.9,2838.6,263.2,2825.5,264.4z"
      />
      <path
        d="M3875.8,482.2c42,60.5,84.3,121.3,127.5,183.4c-20.7,0-39.5,0-59.1,0c-10.6-15.6-21.4-31.6-32.2-47.6
		c-26.8-39.8-53.7-79.5-80.3-119.4c-3.6-5.4-7.2-7.2-13.8-7c-20,0.6-40,0.2-60,0.1c-3.3,0-6.6,0-10.9,0c0,58.1,0,115.5,0,173.8
		c-16.5,0-32,0-47.6,0c-1.9-6.8-2.5-427.4-0.4-439.9c15.6-1.6,31.4-3.7,47.3-4.8c27.9-2,55.9-3.5,83.8-0.7
		c24.7,2.4,48.6,7.8,71.1,18.8c51.9,25.4,74.6,72.9,69.6,127c-5,53.7-34.1,89.6-83.1,110.2c-3.7,1.5-7.4,3.1-11,4.6
		C3876.6,480.7,3876.5,481.1,3875.8,482.2z M3747.2,447.9c28.7,2.8,56.2,4.4,83.7,1c15.3-1.9,30-6,43.7-13.2
		c21.5-11.4,35.4-28.9,41.2-52.5c1.8-7.4,2.9-15.1,3.2-22.7c1.3-36-12.4-64.4-44.3-82.2c-10-5.6-21.3-9.5-32.5-12.1
		c-27.3-6.4-54.9-3.9-82.4-1c-4.2,0.4-8.3,1.5-12.6,2.3C3747.2,327.9,3747.2,387.3,3747.2,447.9z"
      />
      <path
        d="M3338.5,418.7c63,0,125.5,0,188.8,0c0,14.8,0,28.6,0,43.8c-20.9,0.5-41.7,0.1-62.5,0.2c-21,0.1-42,0-63,0
		c-20.9,0-41.8,0-63.3,0c0,53.6,0,106,0,159.4c69.9,0,139.5,0,208.7,0c2.5,7.9,2.9,32.6,0.8,43.6c-85.7,0-171.6,0-257.4,0
		c-2.3-7.1-2.7-433-0.4-443.5c85.8,0,171.7,0,258.3,0c0,14.4,0,28.4,0,43.4c-70.2,0-139.7,0-209.9,0
		C3338.5,316.6,3338.5,366.7,3338.5,418.7z"
      />
      <path
        d="M2075.7,222.2c16.4,0,31.6,0,47.6,0c0.3,2.6,0.8,5.1,0.8,7.6c0,94.3,0.1,188.6-0.1,282.9
		c0,24.6-4.9,48.4-15.1,70.8c-20.1,44.2-54.5,71.5-101,83.7c-37.2,9.8-74.7,9.2-111.6-1.7c-55.9-16.5-89.5-54.5-101.9-111
		c-3.2-14.8-4.7-30.4-4.8-45.6c-0.4-92.6-0.2-185.3-0.2-277.9c0-2.6,0.2-5.3,0.4-8.6c15.7,0,31.2,0,48.1,0c0,3.8,0,7.7,0,11.6
		c0,88.3,0,176.6,0,264.9c0,17.4,1.2,34.6,5.7,51.5c11.9,44.8,42.8,71.7,88.6,77.8c18,2.4,35.9,2.4,53.7-1.1
		c46.9-9.1,76.9-39,85.6-86.1c2.6-14.3,3.8-29.1,3.8-43.7c0.3-87.3,0.2-174.6,0.2-261.9C2075.7,231.4,2075.7,227.4,2075.7,222.2z"
      />
      <path
        d="M4859.5,214.9c66,149.7,132.1,299.9,198.7,450.9c-18.1,0-34.7,0-52.4,0c-17.3-40.3-34.8-81-52.3-121.8
		c-66.3,0-132.3,0-199.1,0c-17.4,40.5-34.8,81.1-52.3,121.7c-16.8,0-33,0-50,0c1.5-3.8,2.5-6.9,3.8-9.8
		c63.7-144.5,127.3-289,191-433.5C4851.1,213.2,4851.2,212.6,4859.5,214.9z M4854.9,313.9c-6.6,9.5-79.4,180.3-80.2,187.6
		c15.3,1.8,152.1,1.1,160.1-1.1C4908.2,438.4,4881.7,376.4,4854.9,313.9z"
      />
      <path
        d="M850.7,266.5c19.4,43.6,31.1,89.3,35.2,136.9c7.9,92.3-10.8,178.9-56.6,259.4
		c-30.8,54.2-71.5,99.6-123.1,135.7C753.5,621.3,801,444,848.5,266.7C849.2,266.6,849.9,266.6,850.7,266.5z"
      />
    </g>
  </SvgAnimationWrapper>
)

wunderman.propTypes = {
  className: PropTypes.string,
  entered: PropTypes.bool,
  width: PropTypes.number,
  delay: PropTypes.number,
}

export default wunderman
