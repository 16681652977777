import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import RollingText from './RollingText'
import { screenSizes } from '../theme'

const TextContainer = styled.div`
  position: relative;
  z-index: 2;
  /* text-transform: uppercase; */
  font-family: 'Moderat';
  pointer-events: none;

  p {
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 18pt;
    font-size: 10pt;
  }

  @media (min-width: ${screenSizes.tablet}px) {
    p {
      font-size: 22pt;
      line-height: 35pt;
    }
  }

  @media (min-width: ${screenSizes.desktop}px) {
    margin-top: -5pt;
    p {
      font-size: 20pt;
      line-height: 25pt;
      margin-bottom: 30pt;
    }
  }

  @media (min-width: ${screenSizes.desktopLarge}px) {
    p {
      font-size: 22pt;
      line-height: 27pt;
      margin-bottom: 30pt;
    }
  }
`

class JustifiedText extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    textList: PropTypes.array,
    animate: PropTypes.bool,
    fade: PropTypes.bool,
  }

  render() {
    const { className, textList, animate, fade } = this.props

    return (
      <div className={className}>
        {textList.map((text, index) => (
          <TextContainer key={index}>
            <p>
              <RollingText fade={fade} animate={animate} text={text} />
            </p>
          </TextContainer>
        ))}
      </div>
    )
  }
}

export default JustifiedText
