import React from 'react'
import PropTypes from 'prop-types'
import SvgAnimationWrapper from '../../../components/SvgAnimationWrapper'

const fwa = ({ className, entered, width, delay }) => (
  <SvgAnimationWrapper
    entered={entered}
    className={className}
    viewBox="0 0 106.4 31.6"
    width={width}
    strokeOffset={700}
    delay={delay}
    strokeWidth={2}
  >
    <g>
      <rect x="75.2" y="18.2" width="13.2" height="3.9" />
      <path
        d="M99.3,8.9h-1.6V7c0-2.6-1.4-5-3.7-6.2C93,0.3,91.8,0,90.7,0H69.3c-1.5,0-3.1,0.5-4.3,1.5
		c-0.8,0.6-1.5,1.4-2,2.3l0,0l0,0L60,9.3c0,0-3.4-6.7-4.4-7.5C54.3,0.7,52.7,0,51,0c-1,0-2.1,0.2-3,0.7c-1.8,0.9-2.8,2.5-3.7,4.4
		L42,9.3L39.3,4c-0.7-1.4-1.9-2.6-3.3-3.3C35,0.2,34,0,32.9,0H15.7c-1.2,0-2.3,0.3-3.4,0.9C10.1,2.1,8.7,4.5,8.7,7v1.8H7
		c-3.9,0-7,3.2-7,7c0,3.9,3.2,7,7,7h1.6v1.6c0,3.9,3.2,7,7,7c3.9,0,7-3.2,7-7v-1.6H29c1.4,0,2.6-0.4,3.7-1l2.8,5.4
		c0.1,0.3,0.3,0.6,0.5,0.9c1.3,2.1,3.6,3.4,6,3.4c1,0,2.1-0.2,3-0.7c1.7-0.8,3.2-2.2,4-4l2-4.5l2.5,4.9c0.1,0.3,0.3,0.6,0.5,0.9
		c1.3,2.1,3.6,3.4,6,3.4c1,0,2.1-0.2,3-0.7c1.6-0.8,2.9-2.1,3.5-3.8c1.1,2.6,3.6,4.4,6.5,4.4l17.7,0c3.9,0,7-3,7-6.9v-1.6h1.6
		c3.9,0,7-3.2,7-7C106.4,12,103.2,8.9,99.3,8.9z M99.3,18.2H93v6.2c0,1.3-1,2.3-2.3,2.3c0,0-0.3,0-0.7,0c0,0,0,0,0,0H73h0
		c-1.3,0-2.3-1-2.3-2.3v-8.6h0c0.1-1.2,1.1-2.2,2.3-2.2h15.4V9.3H70.6L62.2,25c-0.1,0.7-0.6,1.3-1.3,1.6c-0.1,0-0.2,0.1-0.2,0.1
		c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
		c-0.1,0-0.2,0-0.3,0c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2,0c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.2-0.1c0,0,0,0,0,0c-0.1,0-0.2-0.1-0.2-0.1
		c0,0,0,0-0.1,0c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0,0c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0,0,0,0c-0.1-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0-0.1
		c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.3-0.2-0.4L51,12.2L44.2,25c-0.1,0.7-0.6,1.3-1.3,1.6c-0.1,0-0.2,0.1-0.2,0.1
		c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
		c-0.1,0-0.2,0-0.3,0c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.2-0.1c0,0,0,0,0,0c-0.1,0-0.2-0.1-0.2-0.1
		c0,0,0,0-0.1,0c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0-0.1,0c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0,0,0,0c-0.1-0.1-0.1-0.1-0.1-0.2
		c0,0,0,0,0-0.1c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.3-0.2-0.4L31.6,9.3H18v4.3h11c1.3,0,2.3,1,2.3,2.3c0,1.3-1,2.3-2.3,2.3H18
		v6.4c0,1.3-1,2.3-2.3,2.3c-1.3,0-2.3-1-2.3-2.3v-6.4H7c-1.3,0-2.3-1-2.3-2.3c0-1.3,1-2.3,2.3-2.3h6.4V7v0c0-0.9,0.5-1.6,1.2-2
		c0.3-0.2,0.7-0.3,1.1-0.3h17.2c0.4,0,0.7,0.1,1,0.2c0.5,0.2,0.9,0.6,1.1,1.1l6.8,13.4l6.8-12.6c0,0,0-0.1,0-0.1
		C49.1,6,49.3,5.4,50,5c0.1,0,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.2-0.1c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0
		c0.1,0,0.2,0,0.3,0c0,0,0,0,0.1,0c0.1,0,0.2,0,0.3,0.1c0,0,0,0,0.1,0c0.1,0,0.2,0.1,0.3,0.1c0,0,0,0,0,0c0.1,0,0.2,0.1,0.3,0.1
		c0,0,0,0,0.1,0c0.1,0.1,0.2,0.1,0.2,0.2c0.4,0.3,0.7,0.8,0.8,1.3l6.5,12.8L67.1,6c0.2-0.3,0.4-0.6,0.7-0.8c0.4-0.3,0.9-0.5,1.5-0.5
		h21.3c0.4,0,0.8,0.1,1.1,0.3C92.5,5.4,93,6.2,93,7v0v6.6h6.4c1.3,0,2.3,1,2.3,2.3C101.6,17.2,100.6,18.2,99.3,18.2z"
      />
    </g>
  </SvgAnimationWrapper>
)

fwa.propTypes = {
  className: PropTypes.string,
  entered: PropTypes.bool,
  width: PropTypes.number,
  delay: PropTypes.number,
}

export default fwa
