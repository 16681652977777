import React from 'react'
import PropTypes from 'prop-types'
import SvgAnimationWrapper from '../../../components/SvgAnimationWrapper'

const discovery = ({ className, entered, width, delay }) => (
  <SvgAnimationWrapper
    entered={entered}
    className={className}
    viewBox="0 0 364.3 73.3"
    width={width}
    strokeOffset={220}
    delay={delay}
    strokeWidth={3}
  >
    <g>
      <path
        d="M358.7,27.4H354l-9.5,21l-8.3-21l-6.1,0.8l10.1,25c0,0,0.8,1.8,0.8,3.4c0,2.2-2.9,7.5-10.1,7V68h1.1
		c7.2,0,11.1-6.2,13.3-11L358.7,27.4z M326.4,33l-0.6-4.5c-0.6-0.1-4.2-0.9-7.8-0.7c-3,0.2-5.1,1.6-6.3,2.4v-2.8l-6.4,0.8v28.4h6.4
		V33.7c1.6-1.2,3.3-2.2,6.1-2.4C322.5,31.1,326.4,33,326.4,33 M278.2,40.1h21v-1.2c0-7.3-5.5-11.4-12.5-11.4
		c-10.8,0-14.9,5.3-14.9,14c0,10.1,6.3,15.8,17.2,15.8c5.9,0,9.3-1.2,9.3-1.2v-2.6c0,0-3.2,0.8-5.5,0.8c-4.8,0-9.2-1.7-11.6-4.4
		C278.7,47,278.2,43.1,278.2,40.1 M278.4,37.4c0.3-2.3,1.7-6.9,7.5-6.9c4,0,6.4,3.4,6.4,6.9H278.4z M256.3,57.2L270,28.1h-4.8
		l-9.8,21.3l-10.3-21.3l-6.4,0.8l13.7,28.3H256.3z M236.3,42.4c0-9.1-5.8-15-15.5-15c-10.8,0-15.3,6.2-15.3,15
		c0,8.7,5.5,14.7,15.5,14.7C231.3,57.2,236.3,51.5,236.3,42.4 M229.3,42.4c0,4.7-0.6,11.7-8.4,11.7c-7.3,0-8.6-7.1-8.6-11.7
		c0-5,0.4-12,8.4-12C228.1,30.4,229.3,37.4,229.3,42.4 M193,57.2c4.8,0,8.1-1.1,8.1-1.1v-2.4c0,0-2.4,0.4-4.4,0.4
		c-4.7,0-9.5-2-11.9-4.7c-2.4-2.8-2.6-6.4-2.6-10.1c0-3.2,1.6-9,9.3-9c4.2,0,9,2.5,9,2.5v-4.4c0,0-4.2-1.1-10.6-1.1
		c-10.7,0-14.6,6.4-14.6,14C175.3,50.6,180.1,57.2,193,57.2 M170.3,48.3c0-4.9-4.5-7.1-8.7-8.8c-1.1-0.4-3.6-1.3-5.8-2.5
		c-1.6-0.8-2.5-1.8-2.5-3.2c0-2.6,2.5-3.5,5.6-3.5c4.2,0,9.1,2.5,9.1,2.5v-4.4c0,0-4.6-1.1-8.4-1.1c-8.6,0-12.2,3.3-12.2,8.2
		c0,4.2,4.5,6.2,8.7,7.9c1.1,0.4,3.6,1.4,5.8,2.6c1.6,0.9,2.5,2,2.5,3.4c0,4-4.3,4.6-6.7,4.7c-4.1,0.3-10.4-0.5-10.4-0.5v2.4
		c0,0,3.7,1.1,9.7,1.1C164.5,57.2,170.3,54.6,170.3,48.3 M139.8,27.3l-6.4,0.8v28.3h6.4V27.3z M140.8,18.7c0-2-2.1-3.7-4.4-3.7
		c-2.5,0-4.5,1.6-4.5,3.7c0,2,2,3.7,4.5,3.7C138.7,22.3,140.8,20.8,140.8,18.7 M127.3,36.1c0-5.8-2.6-11.7-7.4-15.4
		c-4.4-3.5-10.8-5-17.4-5h-12v40.9h12c6.6,0,13-1.6,17.5-5C124.8,47.8,127.3,42,127.3,36.1 M119.5,36.1c0,6.6-3.3,12.8-9.9,15.7
		c-3,1.3-7.4,1.7-12,1.7V18.7c4.6,0,9,0.3,12,1.7C116.2,23.3,119.5,29.5,119.5,36.1"
      />
      <g>
        <path
          d="M40.9,4.5c-17.4,0-32,14.9-32,32.2c0,17.3,14.6,32.2,32,32.2c17.4,0,32-14.8,32-32.2
			C73,19.3,58.4,4.4,40.9,4.5z M41,9.6c2.3,1.6,4.6,3.4,6.7,5.2c-2.3,1.9-4.5,3.8-6.7,5.8c-2.2-2-4.4-4-6.7-5.9
			C36.5,13,38.7,11.2,41,9.6z M28.5,19.9c4.2,4,8.4,8,12.5,12.1C45.2,28,49.3,24,53.5,20c1.9,1.8,3.8,3.7,5.5,5.7
			C53.2,32,47.2,38.2,41,44.2c-6.2-6-12.2-12.2-18-18.6C24.8,23.7,26.6,21.8,28.5,19.9z M41,63.7c-10.4-7.4-19.7-16.5-27.1-26.9
			c1.3-1.9,2.7-3.7,4.1-5.5c6.9,8.6,14.7,16.5,23,23.8C49.3,47.8,57.1,40,64,31.4c1.4,1.8,2.8,3.6,4,5.4
			C60.6,47.2,51.4,56.4,41,63.7z"
        />
        <path
          d="M40.9,1.1C21.4,1.1,5.6,17,5.6,36.7c0,19.6,15.8,35.5,35.3,35.5c19.5,0,35.3-15.9,35.3-35.5
			C76.3,17,60.5,1.1,40.9,1.1z M40.9,69.8c-18.2,0-33-14.9-33-33.2c0-18.3,14.8-33.2,33-33.2c18.2,0,33,14.9,33,33.2
			C73.9,55,59.2,69.8,40.9,69.8z"
        />
      </g>
    </g>
  </SvgAnimationWrapper>
)

discovery.propTypes = {
  className: PropTypes.string,
  entered: PropTypes.bool,
  width: PropTypes.number,
  delay: PropTypes.number,
}

export default discovery
