import React from 'react'
import PropTypes from 'prop-types'
import SvgAnimationWrapper from '../../../components/SvgAnimationWrapper'

const menlynmaine = ({ className, entered, width, delay }) => (
  <SvgAnimationWrapper
    entered={entered}
    className={className}
    width={width}
    viewBox="0 0 123.7 123.7"
    strokeOffset={560}
    delay={delay}
    strokeWidth={2}
  >
    <path
      d="M61.8,0.5C28,0.5,0.5,28,0.5,61.8c0,33.9,27.5,61.4,61.4,61.4c33.9,0,61.3-27.5,61.3-61.4
	C123.2,28,95.7,0.5,61.8,0.5z M104.8,75.1h-6.3c-0.1-4.2,0-9.4,0-13.4c0-3.8-3-6.7-6.8-6.7c-3.9,0-6.8,3-6.8,6.7l0,13.4h-6.3V61.7
	c0-3.8-3-6.7-6.8-6.7c-3.8,0-6.8,3-6.8,6.7c0,4,0,9.4,0,13.4h-6.3V61.7c0,0,0,0,0,0c0-3.8-3-6.7-6.8-6.7c-3.9,0-6.8,3-6.8,6.7v13.4
	h-6.3V61.7c0-3.8-3-6.7-6.8-6.7c-3.8,0-6.8,3-6.8,6.7c0,4,0,9.4,0,13.4h-6.3V61.7c0-7.2,5.9-13,13.2-13c4,0,7.5,1.8,9.9,4.6
	c2.4-2.8,6-4.6,9.9-4.6c4,0,7.6,1.8,10,4.6c0,0,0,0,0,0c2.4-2.8,6-4.6,10-4.6c4,0,7.5,1.8,9.9,4.6c2.4-2.8,6-4.6,9.9-4.6
	c7.3,0,13.2,5.9,13.2,13V75.1z"
    />
  </SvgAnimationWrapper>
)

menlynmaine.propTypes = {
  className: PropTypes.string,
  entered: PropTypes.bool,
  width: PropTypes.number,
  delay: PropTypes.number,
}

export default menlynmaine
