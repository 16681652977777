import React from 'react'
import PropTypes from 'prop-types'
import SvgAnimationWrapper from '../../../components/SvgAnimationWrapper'

const smashing = ({ className, entered, width, delay }) => (
  <SvgAnimationWrapper
    entered={entered}
    className={className}
    viewBox="0 0 149.1 41.6"
    width={width}
    strokeOffset={240}
    delay={delay}
    strokeWidth={1}
  >
    <g>
      <g id="SMASHING_SCHRIFTZUG">
        <path
          id="SMASHING"
          d="M125.9,11.5c0-6,4.8-10.9,10.8-11c4.1,0,7.8,2.2,9.7,5.9l-4,1.3c-2.1-3.1-6.3-3.9-9.4-1.8
			c-3.1,2.1-3.9,6.3-1.8,9.4c2.1,3.1,6.3,3.9,9.4,1.8c0.5-0.4,1-0.8,1.4-1.3l-2.4,0.4l-2.9-2.9l6.2-1l6.2-1l-1,6.2l-1,6.2l-3-3
			l0.3-1.7c-4.2,4.3-11.1,4.3-15.4,0C127,17.1,125.9,14.3,125.9,11.5L125.9,11.5z M0,14.7c-0.1,1.2,0.2,2.3,0.7,3.4
			c0.5,0.9,1.2,1.7,2,2.4c0.9,0.6,1.9,1.1,2.9,1.4c1.1,0.3,2.3,0.5,3.4,0.5c1.3,0,2.6-0.1,3.8-0.5c1-0.3,1.9-0.8,2.8-1.4
			c0.7-0.6,1.3-1.3,1.7-2.2c0.4-0.9,0.6-1.8,0.6-2.7c0-1-0.2-2-0.8-2.9c-0.5-0.7-1.1-1.4-1.8-1.8c-0.6-0.4-1.4-0.8-2.1-1
			c-0.5-0.2-1.1-0.3-1.6-0.4L8.3,8.5C7.7,8.3,7,8.1,6.4,7.9C6,7.7,5.7,7.5,5.5,7.1c-0.2-0.3-0.3-0.7-0.2-1c0-0.4,0.1-0.8,0.3-1.1
			C5.7,4.7,6,4.5,6.3,4.3c0.3-0.2,0.6-0.3,1-0.4C7.7,3.8,8,3.7,8.4,3.7c0.5,0,1.1,0,1.6,0.1c0.5,0.1,0.9,0.3,1.3,0.5
			c0.4,0.2,0.7,0.6,0.9,1c0.2,0.5,0.4,1,0.4,1.5h4.5c0-1.1-0.2-2.2-0.7-3.1c-0.4-0.9-1.1-1.6-1.9-2.1c-0.8-0.6-1.8-1-2.7-1.2
			C10.8,0.1,9.7,0,8.7,0c-1,0-1.9,0.1-2.9,0.4C4.9,0.6,4,1,3.3,1.6c-0.8,0.5-1.4,1.2-1.8,2C0.9,4.4,0.7,5.4,0.7,6.4
			c0,0.9,0.2,1.7,0.5,2.5c0.4,0.7,0.9,1.2,1.5,1.7c0.6,0.5,1.3,0.8,2,1.1C5.5,12,6.3,12.3,7,12.5c0.8,0.2,1.5,0.4,2.3,0.6
			c0.7,0.2,1.3,0.4,2,0.6c0.5,0.2,1,0.5,1.4,0.9c0.4,0.4,0.6,0.9,0.6,1.4c0,0.5-0.1,1-0.4,1.3c-0.3,0.3-0.6,0.6-1,0.8
			c-0.4,0.2-0.9,0.3-1.3,0.4c-0.4,0.1-0.9,0.1-1.3,0.1c-0.6,0-1.2,0-1.8-0.2c-0.5-0.1-1.1-0.4-1.5-0.7c-0.4-0.3-0.8-0.7-1-1.2
			c-0.3-0.6-0.4-1.2-0.4-1.8L0,14.7z M51.9,5.7l2.7,7.8h-5.5L51.9,5.7z M41.6,21.5v-21H35l-4.8,14.6l-5-14.6h-6.5v21.2h4.4l0.1-14.9
			l5.2,14.9h3.6l5.3-15.1v15.1h9l1.7-4.7h7.9l1.6,4.7h4.8l-8-21.2h-4.8L41.6,21.5z M60.8,14.8c0,1.2,0.2,2.3,0.7,3.4
			c0.5,0.9,1.2,1.7,2,2.4c0.9,0.6,1.9,1.1,2.9,1.4c1.1,0.3,2.3,0.5,3.4,0.5c1.3,0,2.6-0.1,3.8-0.5c1-0.3,1.9-0.8,2.8-1.4
			c0.7-0.6,1.3-1.3,1.7-2.2c0.4-0.9,0.6-1.8,0.6-2.7c0-1-0.2-2-0.8-2.9c-0.5-0.7-1.1-1.4-1.8-1.8c-0.6-0.4-1.4-0.8-2.1-1
			c-0.5-0.2-1.1-0.3-1.6-0.4l-3.2-0.8c-0.7-0.2-1.3-0.4-1.9-0.7c-0.4-0.1-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.7-0.2-1
			c0-0.4,0.1-0.8,0.3-1.1c0.2-0.3,0.4-0.5,0.7-0.7c0.3-0.2,0.6-0.3,1-0.4c0.4-0.1,0.8-0.1,1.1-0.1c0.5,0,1.1,0,1.6,0.1
			c0.5,0.1,0.9,0.3,1.3,0.5c0.4,0.2,0.7,0.6,0.9,1c0.3,0.5,0.4,1,0.4,1.5h4.5c0-1.1-0.2-2.2-0.7-3.1c-0.4-0.9-1.1-1.6-1.9-2.1
			c-0.8-0.6-1.8-1-2.7-1.2c-1-0.3-2.1-0.4-3.2-0.3c-1,0-1.9,0.1-2.9,0.4c-0.9,0.2-1.8,0.6-2.6,1.2c-0.7,0.5-1.4,1.2-1.8,2
			c-0.5,0.9-0.7,1.8-0.7,2.8c0,0.9,0.2,1.7,0.5,2.5c0.4,0.7,0.9,1.2,1.5,1.7c0.6,0.5,1.3,0.8,2,1.1c0.8,0.3,1.5,0.5,2.3,0.7
			c0.8,0.2,1.5,0.4,2.3,0.6c0.7,0.2,1.3,0.4,2,0.6c0.5,0.2,1,0.5,1.4,0.9c0.4,0.4,0.6,0.9,0.6,1.4c0,0.5-0.1,1-0.4,1.3
			c-0.3,0.3-0.6,0.6-1,0.8c-0.4,0.2-0.9,0.3-1.3,0.4c-0.4,0.1-0.9,0.1-1.3,0.1c-0.6,0-1.2-0.1-1.8-0.2c-0.5-0.1-1.1-0.4-1.5-0.7
			c-0.4-0.3-0.8-0.7-1-1.2c-0.3-0.6-0.4-1.2-0.4-1.8L60.8,14.8z M79.4,21.8h4.7v-9.1h8.6v9.2h4.7V0.6h-4.7v8.1h-8.6V0.6h-4.7
			L79.4,21.8z M99.5,21.8h4.7V0.6h-4.7V21.8z M106.3,21.8h4.4l0.1-14.2l8.8,14.2h4.7V0.6h-4.4l-0.1,14.3L111,0.6h-4.6L106.3,21.8z"
        />
      </g>
      <g id="Text_MAGAZINE">
        <path d="M2.9,28.9h1.7l4.2,10.9l4.2-10.9h1.7v12.5h-1.2V30.5l0,0L9.4,41.3H8.3L4.1,30.5l0,0v10.8H2.9V28.9z" />
        <path d="M24.3,28.9h1.3l4.8,12.5h-1.3l-1.5-3.9H22l-1.5,3.9h-1.2L24.3,28.9z M22.4,36.4h4.8L24.9,30L22.4,36.4z" />
        <path
          d="M45.4,41.3h-0.8L44.4,39l0,0c-0.8,1.6-2.4,2.6-4.2,2.5c-3.9,0-5.8-3.1-5.8-6.5s1.9-6.5,5.8-6.5
			c2.5-0.2,4.8,1.6,5.1,4.1h-1.2c-0.4-1.9-2-3.2-3.9-3.1c-3.2,0-4.6,2.8-4.6,5.5s1.4,5.5,4.6,5.5c2.7,0,4.3-1.9,4.2-4.6h-4.2v-1h5.2
			V41.3z"
        />
        <path d="M54.7,28.9H56l4.8,12.5h-1.2L58,37.4h-5.6l-1.5,3.9h-1.2L54.7,28.9z M52.8,36.4h4.8L55.3,30L52.8,36.4z" />
        <path d="M64.7,28.9h8.8v1l-8.1,10.4h8.2v1h-9.5v-1l8.1-10.4h-7.5V28.9z" />
        <path d="M78.7,28.9h1.2v12.5h-1.2V28.9z" />
        <path d="M85.8,28.9h1.3l7.1,10.5l0,0V28.9h1.2v12.5h-1.3L87,30.8l0,0v10.5h-1.2V28.9z" />
        <path d="M101.4,28.9h8.5v1h-7.3v4.5h6.8v1h-6.8v4.9h7.4v1h-8.6V28.9z" />
      </g>
    </g>
  </SvgAnimationWrapper>
)

smashing.propTypes = {
  className: PropTypes.string,
  entered: PropTypes.bool,
  width: PropTypes.number,
  delay: PropTypes.number,
}

export default smashing
