import React from 'react'
import PropTypes from 'prop-types'
import SvgAnimationWrapper from '../../../components/SvgAnimationWrapper'

const fwa = ({ className, entered, width, delay }) => (
  <SvgAnimationWrapper
    entered={entered}
    className={className}
    viewBox="0 0 202.5 31.2"
    width={width}
    strokeOffset={1137}
    delay={delay}
    strokeWidth={1}
  >
    <g>
      <g>
        <defs>
          <filter
            id="Adobe_OpacityMaskFilter"
            filterUnits="userSpaceOnUse"
            x="0"
            y="6.7"
            width="202.5"
            height="24.5"
          >
            <feColorMatrix
              type="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
            />
          </filter>
        </defs>
        <mask
          maskUnits="userSpaceOnUse"
          x="0"
          y="6.7"
          width="202.5"
          height="24.5"
          id="b_2_"
        >
          <g>
            <path id="a_2_" d="M0,0h202.5v31.2H0V0z" />
          </g>
        </mask>
        <path
          d="M20.1,25.8V7.2h-3.4L10,22.3L3.5,7.2H0v18.6h2.5V11.4l6.3,14.4h2.4l6.3-14.4v14.4H20.1z M26.9,13.2h-2.4v12.6
			h2.4V13.2z M24,8.4c0,1,0.8,1.8,1.8,1.8c0,0,0,0,0,0c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8C24.7,6.7,24,7.5,24,8.4L24,8.4z
			 M33.6,18.5c0-1.9,1-3.5,2.9-3.5c2.2,0,2.9,1.4,2.9,3.2v7.6h2.5v-8c0-2.8-1.5-5-4.5-5c-1.5,0-3,0.6-3.9,2.2v-1.8h-2.4v12.6h2.5
			C33.6,25.8,33.6,18.5,33.6,18.5z M47.3,19.5c0-2.6,1.5-4.4,3.7-4.4c2.3,0,3.6,1.8,3.6,4.4c0,2.6-1.4,4.6-3.6,4.6
			C48.6,24,47.3,22.1,47.3,19.5z M54.7,24.1c0,0.8,0.1,1.4,0.1,1.7h2.4C57.1,25,57,24.2,57,23.5V6.8h-2.4V15c-0.4-1-1.6-2.1-3.8-2.1
			c-3.6,0-6,3-6,6.6c0,3.7,2.3,6.7,6,6.7c2,0,3.3-1,3.9-2.3V24.1L54.7,24.1z M60,22.5c0.2,1.4,1.7,3.7,5.1,3.7
			c3.1,0,4.6-2.1,4.6-3.9c0-1.9-1.2-3.2-3.5-3.7l-2-0.4c-1-0.2-1.4-0.8-1.4-1.5c0-0.9,0.8-1.7,2.1-1.7c2,0,2.5,1.4,2.6,2.1l2.2-0.8
			c-0.3-1.1-1.3-3.4-4.8-3.4c-2.5,0-4.5,1.8-4.5,4c0,1.8,1.2,3.1,3.1,3.5l2.1,0.5c1,0.2,1.6,0.8,1.6,1.6c0,0.9-0.8,1.7-2.1,1.7
			c-1.8,0-2.8-1-2.9-2.4L60,22.5L60,22.5z M75.5,30.8v-6.7c0.7,1.1,2.1,2,4.1,2c3.7,0,5.9-2.9,5.9-6.6c0-3.7-2-6.6-5.8-6.6
			c-2,0-3.5,1-4.2,2.3v-2h-2.4v17.6C73.1,30.8,75.5,30.8,75.5,30.8z M82.9,19.5c0,2.6-1.4,4.4-3.7,4.4c-2.3,0-3.7-1.9-3.7-4.4
			c0-2.5,1.5-4.4,3.7-4.4C81.5,15.1,82.9,17,82.9,19.5z M87.8,22.4c0,2,1.6,3.8,4.2,3.8c2.3,0,3.5-1.2,4.1-2.1
			c0,0.9,0.1,1.4,0.1,1.7h2.4c-0.1-0.3-0.2-1-0.2-2.1v-6.3c0-2.5-1.5-4.6-5.2-4.6c-2.7,0-4.9,1.7-5.2,4.1l2.4,0.6
			c0.2-1.5,1.1-2.5,2.9-2.5c1.9,0,2.7,1,2.7,2.3c0,0.4-0.2,0.8-1,0.9l-3.4,0.5C89.4,18.9,87.8,20.2,87.8,22.4L87.8,22.4z M92.3,24.1
			c-1.3,0-2-0.9-2-1.8c0-1.1,0.8-1.7,1.8-1.9l3.8-0.6v0.6C96,23.2,94.4,24.1,92.3,24.1z M109.3,13.1c-0.3,0-0.5-0.1-0.8-0.1
			c-1.3,0-2.9,0.5-3.8,2.4v-2.2h-2.4v12.6h2.5v-6.3c0-2.8,1.4-3.9,3.5-3.9c0.3,0,0.7,0,1.1,0.1L109.3,13.1L109.3,13.1z M123.2,13.2
			h-3.4l-5.4,5.6V6.8h-2.4v19h2.4V22l1.8-1.8l4.1,5.5h3.1l-5.5-7.3L123.2,13.2L123.2,13.2z M128.1,6.8h-2.5v19h2.5
			C128.1,25.8,128.1,6.8,128.1,6.8z M133.9,18.2c0.1-1.6,1.4-3.2,3.4-3.2c2.2,0,3.4,1.4,3.5,3.2H133.9z M141.1,21.5
			c-0.5,1.4-1.5,2.5-3.5,2.5c-2.1,0-3.7-1.6-3.8-3.8h9.5c0-0.3,0.1-0.5,0.1-0.8c0-3.9-2.2-6.6-6-6.6c-3.2,0-6,2.7-6,6.7
			c0,4.3,3,6.7,6.3,6.7c2.9,0,4.9-1.8,5.7-4L141.1,21.5L141.1,21.5z M173.3,25.8V7.2h-3.4l-6.6,15.1l-6.5-15.1h-3.5v18.6h2.5V11.4
			l6.3,14.4h2.4l6.3-14.4v14.4H173.3L173.3,25.8z M176.8,22.4c0,2,1.6,3.8,4.2,3.8c2.3,0,3.5-1.2,4.1-2.1c0,0.9,0.1,1.4,0.1,1.7h2.4
			c-0.1-0.3-0.2-1-0.2-2.1v-6.3c0-2.5-1.5-4.6-5.2-4.6c-2.7,0-4.9,1.7-5.2,4.1l2.4,0.6c0.2-1.5,1.1-2.5,2.9-2.5c1.9,0,2.7,1,2.7,2.3
			c0,0.4-0.2,0.8-1,0.9l-3.4,0.5C178.5,18.9,176.8,20.2,176.8,22.4L176.8,22.4z M181.4,24.1c-1.3,0-2-0.9-2-1.8
			c0-1.1,0.8-1.7,1.8-1.9l3.8-0.6v0.6C185,23.2,183.4,24.1,181.4,24.1z M190.4,26.5c0.3,2.7,2.7,4.7,5.8,4.7c4.7,0,6.3-3.1,6.3-6.3
			V13.2h-2.4V15c-0.6-1.3-1.9-2-3.9-2c-3.5,0-5.8,2.7-5.8,6.1c0,3.6,2.4,6.1,5.8,6.1c1.9,0,3.2-0.9,3.8-2.1v1.8
			c0,2.7-1.2,4.1-3.8,4.1c-1.9,0-3.2-1.3-3.4-3.1L190.4,26.5L190.4,26.5z M196.6,23.1c-2.2,0-3.6-1.6-3.6-4c0-2.4,1.5-4,3.6-4
			c2.1,0,3.6,1.6,3.6,4C200.2,21.5,198.7,23.1,196.6,23.1z"
        />
      </g>
      <path d="M93.3,0c1.5,0,2.8,1.2,2.8,2.8s-1.2,2.8-2.8,2.8c0,0,0,0,0,0c-1.5,0-2.8-1.2-2.8-2.8S91.8,0,93.3,0" />
    </g>
  </SvgAnimationWrapper>
)

fwa.propTypes = {
  className: PropTypes.string,
  entered: PropTypes.bool,
  width: PropTypes.number,
  delay: PropTypes.number,
}

export default fwa
