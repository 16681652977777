import React from 'react'
import PropTypes from 'prop-types'
import SvgAnimationWrapper from '../../../components/SvgAnimationWrapper'

const jcarandafm = ({ className, entered, width, delay }) => (
  <SvgAnimationWrapper
    entered={entered}
    className={className}
    viewBox="0 0 109.2 17.4"
    width={width}
    strokeOffset={80}
    delay={delay}
  >
    <g>
      <path
        d="M2.2,3.5L2.2,3.5l0-2.1l2.7,0l0,2.1L2.2,3.5z M1.8,16.9c-0.2,0-0.4,0-0.7,0l0-2.2c1,0,1.1-0.4,1.1-1.6L2.2,4.3
		l2.7,0l0.1,8.8C5,15.8,4.4,16.9,1.8,16.9"
      />
      <path
        d="M11.4,11.1L11.4,11.1c1.2,0,2.1-1,2.1-2.3c0-1.3-1-2.3-2.3-2.3C10,6.5,9,7.6,9,8.8C9,10.1,10,11.1,11.4,11.1
		 M13.6,12.4c-0.8,0.8-1.6,1.1-2.7,1.1c-1.1,0-2.1-0.3-2.9-1.1c-1.1-0.9-1.6-2.2-1.6-3.6c0-1.3,0.5-2.5,1.5-3.4
		c0.9-0.8,1.9-1.3,3.1-1.3c1.1,0,2,0.4,2.6,1.2l0-1l2.7,0l0.1,9.2l-2.6,0L13.6,12.4z"
      />
      <path
        d="M27,10.1c-0.7,2.2-2.4,3.6-4.7,3.6c-1.4,0-2.5-0.5-3.5-1.5c-0.9-0.9-1.4-2.1-1.4-3.4c0-1.3,0.5-2.5,1.4-3.4
		c0.9-1,2.1-1.5,3.4-1.5c2.3,0,4,1.2,4.8,3.6l-2.9,0c-0.4-0.6-1-1-1.9-1c-1.2,0-2.1,0.9-2.1,2.2c0,1.4,0.9,2.3,2.2,2.3
		c0.7,0,1.3-0.3,1.8-0.9L27,10.1z"
      />
      <path
        d="M32.9,10.9L32.9,10.9c1.2,0,2.1-1,2.1-2.3c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1.1-2.3,2.3
		C30.6,10,31.6,10.9,32.9,10.9 M35.1,12.3c-0.8,0.8-1.6,1.1-2.7,1.1c-1.1,0-2.1-0.3-2.9-1.1c-1.1-0.9-1.6-2.2-1.6-3.6
		c0-1.3,0.5-2.5,1.5-3.4C30.1,4.4,31.2,4,32.3,4c1.1,0,2,0.4,2.6,1.2l0-1l2.7,0l0.1,9.2l-2.6,0L35.1,12.3z"
      />
      <path d="M42,4l0,1.3c0.4-0.9,1.2-1.4,2.3-1.4l0,2.5h-0.1c-1.7,0-2.1,0.6-2.1,2.3l0,4.6l-2.7,0l-0.1-9.2L42,4z" />
      <path
        d="M50.1,10.8L50.1,10.8c1.2,0,2.1-1,2.1-2.3c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1.1-2.3,2.3
		C47.7,9.8,48.7,10.8,50.1,10.8 M52.2,12.2c-0.8,0.8-1.6,1.1-2.7,1.1c-1.1,0-2.1-0.3-2.9-1.1C45.5,11.3,45,10,45,8.6
		c0-1.3,0.5-2.5,1.5-3.4c0.9-0.8,1.9-1.3,3.1-1.3c1.1,0,2,0.4,2.6,1.1l0-1l2.7,0l0.1,9.2l-2.6,0L52.2,12.2z"
      />
      <path
        d="M56.7,13.2l-0.1-9.2l2.6,0l0,1.2c0.6-0.9,1.4-1.4,2.6-1.4C63.7,3.7,65,4.9,65,7l0,6.1l-2.7,0l0-5
		c0-1.3-0.3-1.9-1.3-1.9c-1.1,0-1.6,0.7-1.6,1.9l0,5L56.7,13.2z"
      />
      <path
        d="M71.3,10.7L71.3,10.7c1.2,0,2.1-1,2.1-2.3c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1.1-2.3,2.3
		C68.9,9.7,69.9,10.7,71.3,10.7 M73.5,12c-0.8,0.8-1.6,1.1-2.7,1.1c-1.1,0-2.1-0.3-2.9-1.1c-1.1-0.9-1.6-2.2-1.6-3.6
		c0-1.3,0.5-2.5,1.5-3.4c0.9-0.8,1.9-1.3,3.1-1.3c1.1,0,2,0.4,2.6,1.1l0-4l2.7,0L76.1,13l-2.6,0L73.5,12z"
      />
      <path
        d="M82.5,10.6L82.5,10.6c1.2,0,2.1-1,2.1-2.3c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1.1-2.3,2.3
		C80.1,9.6,81.1,10.6,82.5,10.6 M84.6,11.9C83.8,12.7,83,13,81.9,13c-1.1,0-2.1-0.3-2.9-1.1c-1.1-0.9-1.6-2.2-1.6-3.6
		c0-1.3,0.5-2.5,1.5-3.4c0.9-0.8,1.9-1.3,3.1-1.3c1.1,0,2,0.4,2.6,1.1l0-1l2.7,0l0.1,9.2l-2.6,0L84.6,11.9z"
      />
      <path
        d="M90.9,12.9l-1.2,0l-0.1-8.2l-1.2,0l0-1l1.2,0l0-0.3c0-1,0.2-1.7,0.7-2.2c0.5-0.5,1.2-0.7,2.3-0.7l0.1,0l0,1.1
		c-0.7,0-1.2,0.2-1.5,0.4c-0.3,0.3-0.4,0.7-0.4,1.4l0,0.3l1.9,0l0,1l-1.9,0L90.9,12.9z"
      />
      <path
        d="M101.1,5.1c0.4-0.5,0.8-0.9,1.4-1.2c0.6-0.3,1.2-0.4,1.9-0.4c1.2,0,2.2,0.4,2.7,1.1c0.6,0.8,0.9,2,0.9,3.6
		l0,4.7l-1.1,0l0-4.9c0-1.2-0.2-2.1-0.6-2.6c-0.4-0.5-1.1-0.8-2-0.8c-0.5,0-1,0.1-1.4,0.3c-0.4,0.2-0.7,0.5-0.9,0.9
		c-0.1,0.3-0.2,0.5-0.3,0.9c-0.1,0.3-0.1,0.9-0.1,1.7l0,4.5l-1.1,0l0-5.2c0-1.1-0.2-1.8-0.7-2.4c-0.4-0.5-1.1-0.8-2-0.7
		c-0.5,0-1,0.1-1.4,0.3c-0.4,0.2-0.7,0.5-1,0.9c-0.1,0.3-0.2,0.5-0.3,0.9C95.1,7,95,7.6,95,8.4l0,4.5l-1.2,0l-0.1-9.2l1.2,0l0,1
		c0.3-0.4,0.8-0.7,1.2-0.9c0.5-0.2,1-0.3,1.6-0.3c0.7,0,1.3,0.1,1.9,0.4C100.4,4.2,100.8,4.6,101.1,5.1"
      />
    </g>
  </SvgAnimationWrapper>
)

jcarandafm.propTypes = {
  className: PropTypes.string,
  entered: PropTypes.bool,
  width: PropTypes.number,
  delay: PropTypes.number,
}

export default jcarandafm
