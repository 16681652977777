import React from 'react'
import PropTypes from 'prop-types'
import SvgAnimationWrapper from '../../../components/SvgAnimationWrapper'

const vitality = ({ className, entered, width, delay }) => (
  <SvgAnimationWrapper
    entered={entered}
    className={className}
    viewBox="0 0 52.3 29.1"
    width={width}
    strokeOffset={165}
    delay={delay}
  >
    <path
      d="M16.5,13.8l2.9-0.2c0.3,0,0.7-0.1,1-0.1c-0.1,0.2-1.1,1-1.2,1.2c-0.4,0.4-0.7,0.7-1.1,1.2
	c-0.7,0.9-1.2,2.1-1.3,3.5c0,0.3,0,0.8,0.2,1.1l0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.3c1.2,0.9,2.4,0.5,3.5-0.3l1-0.8c0.7-0.7,0.3,0.2,1,1
	c0.4,0.4,0.8,0.5,1.4,0.7c0.4,0.1,1.2-0.2,1.6-0.4c0.3-0.1,0.4-0.2,0.7-0.3c0.7-0.4,0.5-0.5,1,0c0.1,0.1,0.3,0.3,0.4,0.4
	c0.3,0.2,0.6,0.4,1.1,0.3c0.4-0.1,0.7-0.2,1.1-0.4l0.5-0.3c0,0,0,0,0,0l0.4-0.3c1-0.9,1.6-1.9,2.4-3c0.3-0.3,0.5-0.8,0.6,0.1
	c0,0.1,0.1,0.2,0.1,0.3c0.1,0.4,0.1,0.7,0.2,1.1c0,0.2,0,0.2,0.1,0.3c0,0.1,0,0.2,0.1,0.3c0.5,1.8,1.2,2.1,2.8,1.7
	c0.1,0,0.6-0.2,0.6-0.2c0.2-0.1,0.3-0.3,0.1-0.5c-0.2-0.3-0.4-0.1-0.6-0.2c-0.6-0.3-0.6-1.2-0.6-1.8c0-0.5,0-1,0-1.6
	c0-1,0.1-2.7-0.8-3.2c-0.6-0.3-1.3,0.1-1.9,0.3c-0.1,0.1-0.4,0.2-0.5,0.3c-0.3,0.4-0.5,0.9-0.8,1.2l-1.2,1.6c-0.1,0.1,0,0-0.1,0.1
	l-1,1.2c-0.2,0.2-0.6,0.8-0.9,0.3c-0.2-0.4-0.2-1-0.2-1.5c0-1.2,0.3-2.3,0.6-3.4c0.2-0.7,0.3-1.3,0.5-1.9l0.6-1.8
	c0.3-1,0.8-1.9,1.1-2.9l0.5-1.1c0.1-0.3,0.4-0.9,0.4-1.2c-0.1-0.8-1.3-1.4-2-1.3c-0.8,1.1-1.5,2.9-2,4.2c-0.3,0.7-0.5,1.6-0.8,2.4
	L27.9,12c-0.2,0.7-0.3,1.4-0.4,2c-0.3,1.8-0.4,2.6-0.5,4.5c0,0.4,0,0.8,0,1.2c-0.3,0.3-1.1,0.5-1.6,0.3c-0.8-0.2-0.2-1.6,0-2.1
	c0.5-1.4,0.6-1.4,0-2.2c-0.3-0.4-0.7-0.7-1.1-0.9c-0.5-0.2-0.7,0.3-1.1,0.7l-2.8,3.2c-0.2,0.2-0.3,0.3-0.5,0.5
	c-0.2,0.3-0.5,0.1-0.6-0.1c-0.3-0.9,0.4-2.4,0.9-3c0.8-0.9,1.6-1.6,2.8-2.2c0.7-0.3,0.7-0.2,1.2-0.7c0.5-0.5,0.8-0.8,0-1.1
	c-1.3-0.6-3.3-0.6-4.8-0.7c-0.7,0-1.4-0.1-2.1-0.1c-0.1-0.6,1.4-4.6,1.8-5.6c0-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.1-0.4,0.2-0.6
	c0.3-0.7,0.2-1.2-0.5-1.6c-0.2-0.1-0.9-0.5-1.2-0.4c-0.6,1-1.1,2-1.5,3c-0.2,0.5-0.5,1.1-0.7,1.5l-0.6,1.4c0,0,0,0,0,0.1l-0.5,1.3
	c-0.4,1.1-0.3,1.1-0.8,1.4c-0.2,0.1-0.2,0.1-0.3,0.2c-1,1.1,0.5,1.1,0.3,2.4c-0.1,0.3-0.1,0.7-0.2,1c0,0.2,0,0.3-0.1,0.5
	c0,0.2-0.1,0.2-0.1,0.5c0,0.4-0.1,0.7-0.1,1.1c0,0.5-0.1,4.2,0,4.4c0.2,0.2,0.8-0.2,0.9-0.2c0.4-0.3,0.8-0.7,1-1.2
	c0.2-0.4,0.2-0.5,0.3-0.9c0.1-0.3,0.1-0.6,0.2-0.9c0.1-0.4,0.1-0.6,0.2-1l0.4-1.9C16.1,15.3,16.4,13.9,16.5,13.8L16.5,13.8z
	 M42,12.7c0-0.2,1.4-4.1,1.5-4.4c0.2-0.5,0.7-1.9,0.5-2.4c-0.2-0.7-1.5-1.1-1.8-1.1l-0.8,1.7c-0.3,0.7-1.4,3.6-1.6,4.4
	c-0.1,0.3-0.1,0.4-0.2,0.6c-0.1,0.3-0.3,1.2-0.5,1.3c-0.6,0.4-1.5,0.1-1.5,1c0.2,0.2,0.5,0.4,0.7,0.5c0.7,0.4,0.6,0.7,0.4,1.6
	c-0.1,0.3-0.1,0.7-0.1,1c0,0.4-0.1,0.7-0.1,1.1c0,0.4,0,0.8,0,1.2c0,0.6,0,1.8,0.1,2.4c0.5,0,0.9-0.6,1.1-0.8
	c0.1-0.1,0.2-0.2,0.3-0.3c0.3-0.4,0.3-0.4,0.3-0.9c0-0.3,0.1-0.7,0.1-1c0.3-2.3,1-3.3,1.1-3.7c0.4,0,0.9,0,1.3,0
	c0.4,0,0.9-0.1,1.2-0.1c0,0.2-0.2,0.6-0.3,0.8c-0.1,0.2-0.2,0.5-0.3,0.8c-0.3,0.7-0.7,1.8-0.8,2.5c-0.1,0.6,0,1.4,0.2,1.9
	c0.4,0.9,1.2,1.1,2.2,1c0.5,0,1.3-0.2,1.7-0.4c0.3-0.1,0.5-0.2,0.7-0.4c0.5-0.3,0.8-0.6,1.2-1c0.3-0.3,0.4-0.5,0.6-0.5
	c0,0.3-0.3,1.2-0.4,1.6c-0.3,1-1.1,2.6-1.7,3.4c-0.5,0.8-1.2,1.6-1.8,2.2c-0.1,0.1-0.2,0.2-0.3,0.2c-0.3,0.3-1.1,0.8-1.5,1
	c-0.2,0.1-0.5,0.2-0.7,0.3c-0.4,0.2-0.6,0.2-0.7,0.2c0,0.2,0,0.2,0.2,0.2c0.7,0.2,2-0.1,2.6-0.4c0.3-0.1,0.4-0.2,0.6-0.3l0.6-0.3
	c0.3-0.2,0.7-0.5,1-0.7l0.9-0.9c0.5-0.5,1.1-1.3,1.5-2c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.1,0.1-0.1,0.2-0.3c0-0.1,0.1-0.2,0.1-0.3
	c0.3-0.5,0.9-1.9,1.1-2.4c0.2-0.7,0.4-1.3,0.6-2.1c0-0.2,0.1-0.4,0.2-0.7c0.1-0.8,0.4-2.4,0.4-3.1c0-0.7,0.2-0.9-0.7-1.5
	c-1.1-0.7-1-0.4-1.4,0.4l-1.3,2.2c0,0.1-0.1,0.1-0.1,0.2l-1.1,1.5c-0.2,0.3-0.9,1.1-1.2,1.3c-0.3,0.3-1.4,1-1.7,0.2
	c-0.2-0.6,0.2-2.2,0.4-3c0.1-0.4,0.3-0.8,0.3-1.3c0.1-0.4,0.2-0.7,0.2-1.1c0-0.5,0-0.8-0.5-0.8c-0.1,0.1-0.2,0.2-0.2,0.2
	c-0.2,0-1-0.3-1.3-0.4C42.8,12.8,42.8,12.8,42,12.7L42,12.7z M3.3,16.8c-0.2-0.4-0.4-2.5-0.4-3.2c-0.3-2.3-0.2-5-0.2-7.4
	c0-0.7-0.2-1.5-0.9-1.8C1.4,4.4,1,4.3,0.6,4.4C0.4,4.5,0.5,4.7,0.4,4.9C0,6.6,0.2,12,0.3,13.8c0.1,0.9,0.2,1.9,0.2,2.8
	c0,0.4,0.1,0.9,0.2,1.3c0,0.2,0.1,0.4,0.1,0.7L1,19.8c0.1,0.2,0.1,0.4,0.1,0.6c0.1,0.3,0.2,0.9,0.5,1.1c0.6,0.3,2.7,0.2,3.2-0.1
	c0.5-0.2,0.4-0.3,0.5-0.9c0.2-0.9,0.5-2.3,0.7-3c0.2-0.8,0.4-1.6,0.7-2.3l0.7-2.2c0.2-0.8,0.5-1.4,0.8-2.2c0.4-1,0.7-1.6,1.1-2.6
	c0.5-1.1,1.3-2.4,1.9-3.3c0.2-0.3,0.4-0.6,0.6-0.9l0.7-0.8c0.9-1,1.3-1.4,2.3-2.1c0.2-0.1,0.3-0.2,0.4-0.3c0.2-0.1,0.4-0.2,0.5-0.3
	c-0.1-0.2-0.6-0.2-0.8-0.2c-0.5,0-1,0.2-1.4,0.3c-0.4,0.2-0.8,0.4-1.2,0.6c-0.6,0.4-0.9,0.7-1.5,1.2c-0.8,0.7-1,1.1-1.7,1.9
	c-1,1.3-2.2,3.4-3,5C6.2,9.4,6.2,9.7,6.1,9.8c0,0.1,0,0-0.1,0.1c0,0.1,0,0.1-0.1,0.2C5.5,11,5.1,12,4.7,12.9c0,0.1-0.1,0.2-0.1,0.3
	l-1.1,3.3C3.4,16.7,3.4,16.8,3.3,16.8L3.3,16.8z M10.2,12.4c-0.8,0.9-1,1.4-1.5,2.6c-0.1,0.2-0.1,0.3-0.2,0.6
	c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.1,0.5-0.1,0.7c-0.1,0.5-0.4,2.4-0.4,2.9c-0.1,1.2,0.7,1.9,1.6,1.7c0.4,0,0.8-0.4,1-0.6
	c0.2-0.2,0.5-0.9,0.3-1.2c-0.1-0.2-0.2-0.2-0.1-0.7l0.5-2.1c0.2-0.8,0.5-1.8,0.6-2.7C11.8,13.4,11,12.5,10.2,12.4L10.2,12.4z
	 M10.2,8.7c0,0.9,0.3,1.8,1,2.2c0.9,0.5,2.2,0.2,2-0.7c0-0.1-0.2-0.4-0.2-0.6c-0.2-0.4-0.7-1.1-1-1.4c-0.3-0.3-0.8-0.7-1.3-0.3
	C10.4,8.1,10.2,8.3,10.2,8.7L10.2,8.7z M34.4,9.2c-0.4,0.1-0.7,0.4-0.7,0.9c0,1.1,1.1,3,2.2,2.3c0.3-0.2,0.6-0.4,0.8-0.8
	c0.1-0.3,0-0.4-0.1-0.6c-0.3-0.5-0.5-1-1-1.4C35.3,9.4,34.9,9.1,34.4,9.2L34.4,9.2z"
    />
  </SvgAnimationWrapper>
)

vitality.propTypes = {
  className: PropTypes.string,
  entered: PropTypes.bool,
  width: PropTypes.number,
  delay: PropTypes.number,
}

export default vitality
