import React from 'react'
import PropTypes from 'prop-types'
import SvgAnimationWrapper from '../../../components/SvgAnimationWrapper'

const cddda = ({ className, entered, width, delay }) => (
  <SvgAnimationWrapper
    entered={entered}
    className={className}
    viewBox="0 0 164 25"
    width={width}
    strokeOffset={75}
    delay={delay}
  >
    <g>
      <polygon points="16.2,16 16.2,16 8.1,8 16.2,0 8.1,0 0,8 4.1,12.1 8.1,16 12.2,20 			" />
      <polygon points="22,12.1 17.9,8 13.9,4.1 9.9,8 17.9,16 17.9,16 17.9,16 9.8,24.1 17.9,24.1 22,20.1 26,16 			" />
    </g>
    <g transform="translate(30.637363, 5.406593)">
      <path
        d="M5.5,2.6C4.3,2.6,3.3,3,2.6,3.8S1.5,5.7,1.5,7.1s0.4,2.5,1,3.3c0.7,0.8,1.6,1.2,2.9,1.2
				c0.8,0,1.6-0.2,2.6-0.5v1.2c-0.8,0.3-1.7,0.5-2.8,0.5c-1.6,0-2.9-0.5-3.8-1.4S0.1,8.9,0.1,7.1c0-1.2,0.2-2.2,0.6-3s1.1-1.5,1.8-2
				s1.7-0.7,2.9-0.7s2.2,0.2,3.1,0.6L7.9,3.2C7.2,2.9,6.4,2.6,5.5,2.6z"
      />
      <path
        d="M16.8,9.7c0,1-0.4,1.7-1.1,2.3c-0.7,0.5-1.6,0.8-2.9,0.8c-1.4,0-2.3-0.2-3.1-0.5V11c0.5,0.2,1,0.4,1.4,0.5
				c0.5,0.1,1.1,0.2,1.6,0.2c0.9,0,1.5-0.2,1.9-0.5c0.5-0.4,0.6-0.8,0.6-1.4c0-0.4-0.1-0.7-0.3-0.9c-0.2-0.3-0.5-0.5-0.8-0.7
				c-0.4-0.2-0.9-0.5-1.6-0.7c-1-0.4-1.8-0.8-2.2-1.4C10,5.6,9.8,5,9.8,4.1s0.4-1.5,1-2.1c0.6-0.5,1.5-0.7,2.5-0.7
				c1.1,0,2.1,0.2,3.1,0.6L16,3.1c-0.9-0.4-1.8-0.5-2.7-0.5c-0.7,0-1.2,0.2-1.6,0.5c-0.4,0.3-0.5,0.7-0.5,1.3c0,0.4,0.1,0.7,0.2,0.9
				c0.2,0.3,0.4,0.5,0.7,0.6c0.4,0.2,0.8,0.5,1.5,0.7c1.2,0.5,2,0.9,2.4,1.4C16.6,8.4,16.8,9,16.8,9.7z"
      />
      <path
        d="M25.3,9.7c0,1-0.4,1.7-1.1,2.3c-0.7,0.5-1.6,0.8-2.9,0.8c-1.4,0-2.3-0.2-3.1-0.5V11c0.5,0.2,1,0.4,1.4,0.5
				s1.1,0.2,1.6,0.2c0.9,0,1.5-0.2,1.9-0.5c0.5-0.4,0.6-0.8,0.6-1.4c0-0.4-0.1-0.7-0.3-0.9c-0.2-0.3-0.5-0.5-0.8-0.7
				c-0.4-0.2-0.9-0.5-1.6-0.7c-1-0.4-1.8-0.8-2.2-1.4c-0.5-0.5-0.6-1.2-0.6-2s0.4-1.5,1-2.1s1.5-0.7,2.5-0.7C23,1.4,24,1.5,25,2
				l-0.4,1.1c-0.9-0.4-1.8-0.5-2.7-0.5c-0.7,0-1.2,0.2-1.6,0.5c-0.4,0.3-0.5,0.7-0.5,1.3c0,0.4,0.1,0.7,0.2,0.9
				c0.2,0.3,0.4,0.5,0.7,0.6c0.4,0.2,0.8,0.5,1.5,0.7c1.2,0.5,2,0.9,2.4,1.4C25.1,8.4,25.3,9,25.3,9.7z"
      />
      <path
        d="M36.6,6.9c0,1.8-0.5,3.2-1.5,4.2c-1,1-2.4,1.5-4.3,1.5h-3.6V1.6h3.8c1.8,0,3.2,0.5,4.1,1.4
				C36,3.9,36.6,5.2,36.6,6.9z M33.5,7c0-1-0.2-1.8-0.6-2.3c-0.4-0.5-1-0.7-1.8-0.7h-0.9v6.2h0.6c0.9,0,1.5-0.3,2-0.8
				C33.3,8.9,33.5,8.1,33.5,7z"
      />
      <path
        d="M42.5,12.9c-1.4,0-2.5-0.4-3.3-1.2C38.4,11,38,9.9,38,8.5s0.4-2.5,1.1-3.3S40.9,4,42.3,4c1.3,0,2.3,0.4,3,1
				s1.1,1.6,1.1,2.9v1.4H41c0,0.5,0.2,0.8,0.5,1.2c0.4,0.3,0.8,0.5,1.4,0.5c0.5,0,1-0.1,1.4-0.2c0.5-0.1,0.9-0.3,1.4-0.5v2.2
				c-0.5,0.3-0.9,0.4-1.4,0.5C43.8,12.8,43.3,12.9,42.5,12.9z M42.4,6c-0.4,0-0.6,0.1-0.9,0.4c-0.3,0.2-0.4,0.5-0.5,1h2.5
				c0-0.5-0.1-0.7-0.4-1C43.1,6.1,42.8,6,42.4,6z"
      />
      <path
        d="M54.6,10.1c0,0.9-0.3,1.6-0.9,2.1c-0.6,0.5-1.5,0.7-2.7,0.7c-0.6,0-1.2,0-1.6-0.1s-1-0.2-1.4-0.4v-2.3
				c0.5,0.2,1,0.4,1.5,0.5c0.5,0.1,1,0.2,1.4,0.2c0.6,0,1-0.2,1-0.5c0-0.2-0.1-0.3-0.3-0.4c-0.2-0.1-0.7-0.4-1.5-0.7
				c-0.8-0.4-1.4-0.7-1.6-1.1C48.2,7.7,48,7.3,48,6.7c0-0.8,0.3-1.4,0.9-1.9c0.6-0.5,1.4-0.6,2.6-0.6c0.5,0,1.1,0.1,1.6,0.2
				c0.5,0.1,1,0.3,1.5,0.5l-0.8,1.9c-0.4-0.2-0.8-0.4-1.3-0.5c-0.5-0.1-0.8-0.2-1.1-0.2c-0.5,0-0.7,0.1-0.7,0.4
				c0,0.2,0.1,0.3,0.3,0.4c0.2,0.1,0.6,0.3,1.4,0.6c0.6,0.3,1.1,0.5,1.4,0.7c0.3,0.3,0.5,0.5,0.6,0.8C54.5,9.2,54.6,9.6,54.6,10.1z"
      />
      <path
        d="M56.2,2c0-0.5,0.1-0.8,0.4-1c0.3-0.2,0.6-0.4,1.2-0.4c0.5,0,1,0.1,1.2,0.4c0.3,0.2,0.4,0.5,0.4,1
				c0,0.9-0.5,1.3-1.6,1.3C56.7,3.3,56.2,2.9,56.2,2z M59.2,12.7h-3V4.1h3V12.7z"
      />
      <path
        d="M69.4,4.1v1.4L68.2,6c0.2,0.3,0.3,0.6,0.3,1c0,0.9-0.4,1.6-1,2.2s-1.6,0.7-2.9,0.7c-0.4,0-0.5,0-0.7-0.1
				c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.2,0.1,0.3,0.4,0.4c0.3,0.1,0.5,0.1,0.9,0.1h1.4c1.9,0,2.9,0.8,2.9,2.4c0,1.1-0.5,1.9-1.4,2.4
				c-0.9,0.5-2.2,0.9-3.8,0.9c-1.2,0-2.2-0.2-2.8-0.6c-0.6-0.4-1-1-1-1.7c0-1,0.6-1.7,1.9-2c-0.3-0.1-0.5-0.3-0.7-0.5
				c-0.2-0.3-0.3-0.5-0.3-0.7s0.1-0.5,0.2-0.7c0.2-0.2,0.5-0.5,0.9-0.7c-0.5-0.2-0.8-0.5-1.1-0.9C61.1,8,61,7.6,61,6.9
				c0-0.9,0.4-1.6,1-2.2C62.6,4.2,63.5,4,64.7,4c0.2,0,0.5,0,0.8,0.1c0.4,0,0.6,0.1,0.8,0.1H69.4L69.4,4.1z M62.9,13.8
				c0,0.3,0.1,0.5,0.4,0.6c0.3,0.2,0.6,0.2,1.1,0.2c0.7,0,1.3-0.1,1.7-0.3c0.5-0.2,0.6-0.5,0.6-0.7c0-0.3-0.1-0.4-0.4-0.5
				c-0.3-0.1-0.6-0.2-1.2-0.2H64c-0.4,0-0.5,0.1-0.8,0.2C63,13.3,62.9,13.5,62.9,13.8z M63.8,6.9c0,0.9,0.3,1.4,0.9,1.4
				c0.3,0,0.5-0.1,0.6-0.4c0.2-0.2,0.3-0.5,0.3-1c0-0.9-0.3-1.4-0.9-1.4C64.2,5.6,63.8,6,63.8,6.9z"
      />
      <path
        d="M76.1,12.7V8c0-0.5-0.1-1-0.3-1.3c-0.2-0.3-0.5-0.5-0.8-0.5c-0.5,0-0.9,0.2-1.1,0.6C73.7,7.3,73.5,8,73.5,9
				v3.8h-3V4.2h2.3l0.4,1.1h0.2C73.6,5,74,4.6,74.4,4.4c0.5-0.2,1-0.3,1.5-0.3c1,0,1.7,0.3,2.3,0.8S79,6.3,79,7.3v5.6h-3V12.7z"
      />
      <path
        d="M88.5,12.7l-1.4-3.5h-4.4l-1.4,3.5h-1.3l4.4-11.1h1.1l4.3,11.1H88.5z M86.7,8l-1.3-3.4
				c-0.2-0.5-0.4-1-0.5-1.6c-0.1,0.5-0.3,1-0.5,1.6L83.2,8H86.7L86.7,8z"
      />
      <path
        d="M97.9,12.7l-1.5-4.9c-0.1-0.3-0.3-1-0.5-2h-0.1c-0.2,0.9-0.4,1.5-0.5,2.1l-1.5,4.9h-1.4L90,4.5h1.4
				c0.5,2.1,0.9,3.7,1.3,4.8c0.3,1.1,0.5,1.8,0.5,2.2h0.1c0.1-0.3,0.2-0.6,0.3-1.1c0.1-0.5,0.3-0.8,0.4-1.1l1.5-4.8h1.4l1.4,4.8
				c0.3,0.9,0.5,1.6,0.5,2.2h0.1c0-0.2,0.1-0.5,0.2-0.8s0.6-2.4,1.5-6.1h1.3l-2.3,8.3L97.9,12.7L97.9,12.7z"
      />
      <path
        d="M108.3,12.7l-0.3-1.2H108c-0.5,0.5-0.8,0.9-1.3,1.1c-0.5,0.2-0.9,0.3-1.5,0.3c-0.8,0-1.4-0.2-1.9-0.6
				c-0.5-0.5-0.7-1-0.7-1.8c0-1.7,1.4-2.5,4.1-2.6l1.4-0.1V7.2c0-0.6-0.2-1.2-0.5-1.4c-0.3-0.3-0.7-0.5-1.4-0.5
				c-0.7,0-1.4,0.2-2.3,0.6l-0.4-1c0.5-0.2,0.8-0.4,1.4-0.5c0.5-0.1,1-0.2,1.4-0.2c1,0,1.7,0.2,2.2,0.6c0.5,0.5,0.7,1.2,0.7,2.1v5.7
				L108.3,12.7L108.3,12.7z M105.4,11.8c0.8,0,1.4-0.2,1.8-0.6c0.5-0.5,0.6-1,0.6-1.8V8.7l-1.3,0.1c-1,0-1.7,0.2-2.2,0.5
				s-0.6,0.7-0.6,1.3c0,0.5,0.2,0.8,0.5,1C104.6,11.7,105,11.8,105.4,11.8z"
      />
      <path
        d="M115.6,4.2c0.4,0,0.7,0,1,0.1l-0.2,1.2c-0.4-0.1-0.6-0.1-0.9-0.1c-0.6,0-1.3,0.3-1.7,0.8
				c-0.5,0.5-0.7,1.3-0.7,2.1v4.4h-1.3V4.4h1l0.2,1.5h0.1c0.3-0.5,0.7-1,1.1-1.3C114.6,4.4,115.1,4.2,115.6,4.2z"
      />
      <path
        d="M123.8,11.6L123.8,11.6c-0.6,0.8-1.5,1.3-2.7,1.3c-1.1,0-1.9-0.4-2.5-1.1c-0.6-0.7-0.9-1.8-0.9-3.2
				s0.3-2.4,0.9-3.2s1.4-1.2,2.5-1.2c1.1,0,2,0.5,2.6,1.3h0.1L123.7,5V4.4V0.9h1.3v11.8h-1L123.8,11.6z M121.3,11.8
				c0.9,0,1.4-0.3,1.9-0.7c0.4-0.5,0.5-1.3,0.5-2.3V8.6c0-1.2-0.2-2-0.6-2.5c-0.4-0.5-1-0.7-1.9-0.7c-0.7,0-1.3,0.3-1.7,0.9
				c-0.4,0.5-0.6,1.4-0.6,2.4c0,1.1,0.2,1.9,0.5,2.4C119.9,11.5,120.5,11.8,121.3,11.8z"
      />
      <path
        d="M133,10.5c0,0.8-0.3,1.4-0.9,1.8c-0.5,0.5-1.4,0.6-2.4,0.6c-1.1,0-2-0.2-2.6-0.5v-1.2
				c0.4,0.2,0.8,0.4,1.3,0.5s0.9,0.2,1.4,0.2c0.6,0,1.2-0.1,1.5-0.3s0.5-0.5,0.5-1c0-0.4-0.2-0.6-0.5-0.8c-0.3-0.3-0.8-0.5-1.6-0.8
				s-1.4-0.5-1.6-0.7c-0.4-0.2-0.5-0.5-0.7-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.3-1.2,0.8-1.6c0.5-0.5,1.3-0.6,2.3-0.6
				c0.9,0,1.8,0.2,2.6,0.5l-0.5,1c-0.8-0.4-1.6-0.5-2.3-0.5c-0.6,0-1.1,0.1-1.4,0.3c-0.3,0.2-0.5,0.5-0.5,0.8c0,0.2,0.1,0.5,0.2,0.5
				c0.1,0.2,0.3,0.3,0.5,0.5s0.7,0.4,1.4,0.6c1,0.4,1.6,0.7,2,1.1C132.8,9.5,133,9.9,133,10.5z"
      />
    </g>
  </SvgAnimationWrapper>
)

cddda.propTypes = {
  className: PropTypes.string,
  entered: PropTypes.bool,
  width: PropTypes.number,
  delay: PropTypes.number,
}

export default cddda
