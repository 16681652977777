import PropTypes from 'prop-types'
import React from 'react'

import SvgAnimationWrapper from '../../../components/SvgAnimationWrapper'

const ranktank = ({ className, entered, width, delay }) => (
  <SvgAnimationWrapper
    entered={entered}
    className={className}
    width={width}
    viewBox="0 0 400 211.9"
    strokeOffset={550}
    delay={delay}
  >
    <g>
      <path
        d="M201.6,96.4c0,1.1,0.2,2.2,0.3,3.4c7.9,0,15.4,0,23.6,0c0-19,0-37.7,0-57.4c15,18.9,29.3,36.9,43.6,54.8
		c1,1.2,2.5,2.8,3.8,2.8c6.2,0.3,12.5,0.1,18.9,0.1c0-33.8,0-67,0-100.1c-7.7,0-15.3,0-23,0c-0.2,1.8-0.7,3.6-0.7,5.3
		c-0.1,15.6,0,31.3,0,46.9c0,1.6,0,3.2,0,6.3C252.1,38.3,237,19.1,221.9,0c-6.7,0-13.3,0-20,0c-0.1,1.2-0.3,2.3-0.3,3.5
		C201.6,34.5,201.6,65.4,201.6,96.4z"
      />
      <path
        d="M268.1,112c0,18.9,0,37.6,0,57.6c-14.1-17.7-27.2-34.2-40.4-50.7c-2-2.5-4.2-6.4-6.8-6.8
		c-6.3-1-12.8-0.3-19.4-0.3c0,26.9,0,53,0,79.1c0,7,0.2,14,0.3,20.9c7.7,0,15.3,0,23,0c0.2-3.6,0.5-7.2,0.5-10.9
		c0.1-15.3,0-30.5,1-46.4c15.2,19.1,30.3,38.2,45.5,57.3c6.7,0,13.3,0,20,0c0-33.2,0-66.4,0-99.8C283.9,112,276.3,112,268.1,112z"
      />
      <path
        d="M23.9,68c3.1,0,5.6,0.3,8-0.1c4.2-0.5,6.5,1,8.4,5c4,8.4,8.6,16.5,13.1,24.6c0.6,1.1,2.1,2.4,3.2,2.4
		c8.2,0.2,16.5,0.1,25.7,0.1c-7.2-12.3-13.9-23.9-20.6-35.4c1.2-0.9,2.1-1.7,3.1-2.3c12-6.5,18.1-23.8,13.6-38.3
		C73.9,9.8,62.2,1.4,46.4,1.4C30.9,1.3,15.5,1.1,0,1c0,33,0,66,0,98.9c7.8,0,15.5,0,23.9,0C23.9,89.1,23.9,78.8,23.9,68z M24,21.7
		c7.5,0,14.9-0.7,22.1,0.2c6.5,0.9,10.3,7.2,9.8,13.6c-0.5,6.3-5.6,11.5-12.5,12c-6.3,0.4-12.6,0.1-19.4,0.1
		C24,38.5,24,30.2,24,21.7z"
      />
      <path
        d="M395.9,204.8c-7.6-11.5-15.1-23-22.7-34.5c-3.2-4.8-6.4-9.6-9.5-14.2c11.6-14.7,22.9-29.1,34.9-44.4
		c-9,0-16.7-0.2-24.4,0.1c-1.9,0.1-4.4,1.3-5.6,2.8c-6.2,7.7-12.2,15.7-18.2,23.5c-5.4,7-10.9,14-16.3,21c-0.3-0.1-0.7-0.2-1-0.4
		c0-15.5,0-31,0-46.8c-8.1,0-15.6,0-23.5,0c-0.1,1.3-0.3,2.5-0.3,3.6c0,30.9,0,61.9,0,92.8c0,1.1,0.3,2.3,0.5,3.4c7.7,0,15.3,0,23,0
		c0.1-3.3,0.6-6.7,0.2-9.9c-0.9-6.8,1.6-12.1,6.2-17c3.1-3.2,5.6-6.9,9-11.1c8,13,15.8,25.5,23.6,38.1c8.5,0,17,0,25.5,0
		c1.3,0,3.1,0.5,2.5-2C398.5,208.2,397.1,206.6,395.9,204.8z"
      />
      <path
        d="M309.4,96.4c0,1.1,0.2,2.2,0.3,3.5c7.9,0,15.4,0,24.2,0c-5.2-16.3,4.9-26.2,14.4-36.9
		c6.9,11,13.5,21.7,20.4,32.3c1.4,2.1,3.9,4.6,6.1,4.7c8.3,0.5,16.6,0.1,25,0c0-0.3,0-0.7,0-1c-9.8-14.6-19.6-29.1-29.3-43.7
		c-2.2-3.3-4.4-6.6-6.8-10.3c10.7-13.5,21.4-27.1,32.1-40.7c0.9-1.2,1.4-2.8,2.1-4.3c-9,0-18,0-27,0c-10.1,13.3-20.2,26.6-30.3,39.8
		c-2.1,2.7-4.2,5.5-7.7,8c0-15.9,0-31.9,0-47.8c-7.7,0-15.3,0-23,0c-0.2,1.3-0.5,2.6-0.5,3.9C309.4,34.7,309.4,65.5,309.4,96.4z"
      />
      <path
        d="M146.2,111.9c-4.5-0.3-9,0.3-13.5-0.2c-5-0.6-6.9,1.8-8.5,5.9c-10.9,27-22.1,54-33,81
		c-1.7,4.3-2.8,8.9-4.2,13.4c8.3,0,16.7,0,25,0c2.4-6.7,4.6-13.4,7.1-20c6-15.7,12.2-31.3,18.9-48.3c6.2,15.7,11.9,30.2,17.9,45.3
		c-9.6,0-18.5,0-27.9,0c0,7.9,0,15.5,0,23c20.7,0,41.3,0,62,0c-13.2-32.5-26.5-65.1-39.8-97.5C149.6,113.1,147.6,112,146.2,111.9z"
      />
      <path
        d="M109,100c1.1,0,2.7-1.2,3.1-2.2c3.2-7.6,6.1-15.4,9.2-23.1c5.4-13.7,10.8-27.3,16.7-42.3
		c6.2,15.8,11.9,30.3,18,45.6c-9.9,0-19,0-28,0c0,7.7,0,14.7,0,21.8c20.7,0,40.9,0,61.6,0c-0.7-2-1.2-3.8-1.9-5.4
		c-10.4-25.5-20.9-50.9-31.2-76.4c-2.4-5.9-4.3-12-6.5-18.1c-7.7,0-15.3,0-23,0c-13.6,33.2-27.2,66.3-41,100.1
		C94.4,100.1,101.7,100.2,109,100z"
      />
      <path
        d="M58.3,137.9c0-1.8,0-3.5,0-5.8c12.2,0,23.6,0,35.2,0c0-7,0-13.4,0-19.9c-0.8-0.2-1.5-0.4-2.1-0.4
		c-30.5,0-61,0.1-91.4,0.1c0,6.7,0,13.3,0,20c11.3,0,22.5,0,34.5,0c0,2.6,0,4.3,0,6.1c0,22.8,0,45.6,0,68.4c0,1.8,0.3,3.6,0.5,5.4
		c7.7,0,15.3,0,23,0c0.1-2,0.3-4,0.3-6C58.3,183.2,58.3,160.6,58.3,137.9z"
      />
    </g>
  </SvgAnimationWrapper>
)

ranktank.propTypes = {
  className: PropTypes.string,
  entered: PropTypes.bool,
  width: PropTypes.number,
  delay: PropTypes.number,
}

export default ranktank
