import React from 'react'
import PropTypes from 'prop-types'
import SvgAnimationWrapper from '../../../components/SvgAnimationWrapper'

const lifetech = ({ className, entered, width, delay }) => (
  <SvgAnimationWrapper
    entered={entered}
    className={className}
    viewBox="0 0 437.3 71.1"
    width={width}
    strokeOffset={300}
    delay={delay}
  >
    <g>
      <g>
        <path d="M2.9,9.3h9.7v44.2h27.4v8.2H2.9V9.3z" />
        <path d="M59.8,9.3h9.7v52.5h-9.7V9.3z" />
        <path d="M105.4,17.5v16h25.3v8.2h-25.3v20.1h-9.7V9.3H134v8.2H105.4z" />
        <path d="M194.8,53.6v8.2h-39.4V9.3h38.3v8.2h-28.6v13.6h25.3v8h-25.3v14.5H194.8z" />
      </g>
      <path d="M219.8,0.5h3.5v70.2h-3.5V0.5z" />
      <g>
        <path
          d="M374.9,29.8l-1,1c-3.2-2.9-7.3-4.7-12.2-4.7c-10,0-17.4,7.3-17.4,17.8c0,10.6,7.3,17.9,17.4,17.9
          c5.3,0,9.9-2,13-5.6l1,1c-3.4,3.8-8.3,6.1-14,6.1c-10.8,0-18.8-8.1-18.8-19.4c0-11.2,8-19.3,18.8-19.3
          C366.8,24.6,371.5,26.6,374.9,29.8z"
        />
        <path
          d="M434.4,39.9v23.5h-1.5V39.9c0-8.7-5.1-13.8-13.7-13.8c-10.3,0.2-16.4,7-16.4,18.3v19h-1.5V7.7h1.5v28
          c2.5-7,8.2-11,16.4-11.2C428.8,24.6,434.4,30.3,434.4,39.9z"
        />
        <path
          d="M269.8,60.6c-2.2,1.7-4.9,2.7-7.6,2.8c-5.4,0.1-8.2-3.6-8.2-10.6V26.9h-5.7v-1.5h5.7v-9.2h1.5v9.2H269v1.5
          h-13.6v25.9c0,6,2.3,9.2,6.7,9.1c2.4-0.1,4.8-0.9,6.7-2.5L269.8,60.6z"
        />
        <path
          d="M319.1,56.2l1,1c-3.5,3.8-8.8,6.1-14.8,6.1c-10.7,0-18.7-8.1-18.7-19.4c0-11.2,7.9-19.3,18.7-19.3
          c10.8,0,18,8.1,18,19.3h-35.2c0,10.6,7.2,17.9,17.2,17.9C311,61.9,315.9,59.8,319.1,56.2z M288.3,42.4h33.6
          c-0.7-9.7-6.9-16.3-16.5-16.3C295.9,26.1,288.9,32.8,288.3,42.4z"
        />
      </g>
    </g>
  </SvgAnimationWrapper>
)

lifetech.propTypes = {
  className: PropTypes.string,
  entered: PropTypes.bool,
  width: PropTypes.number,
  delay: PropTypes.number,
}

export default lifetech
