import React from 'react'
import PropTypes from 'prop-types'
import SvgAnimationWrapper from '../../../components/SvgAnimationWrapper'

const redbull = ({ className, entered, width, delay }) => (
  <SvgAnimationWrapper
    entered={entered}
    className={className}
    viewBox="0 0 426.4 95.6"
    width={width}
    strokeOffset={460}
    delay={delay}
    strokeWidth={2.5}
  >
    <g>
      <path
        d="M413.1,4.9c0.7,0,3.9,0.1,3.9,3.4c0,1.7-1.3,2.6-2.7,2.9V11c0.6,0.1,1.1,0.7,1.4,1.4l2.1,4.9h-1.8l-2.1-4.6
		c-0.3-0.6-0.5-1-1.5-1h-1.4v5.6h-1.6V4.9H413.1L413.1,4.9z M411.2,10.3L411.2,10.3h1.5c1.8,0,2.6-1,2.6-2c0-0.7-0.2-2-2.3-2h-1.9
		V10.3L411.2,10.3z M413.4,0.6L413.4,0.6c5.8,0,10.5,4.7,10.5,10.5s-4.7,10.5-10.5,10.5c-5.8,0-10.5-4.7-10.5-10.5
		S407.6,0.6,413.4,0.6L413.4,0.6z M413.4,20.2L413.4,20.2c5.1,0,9.1-4,9.1-9.1s-4-9.1-9.1-9.1c-5.1,0-9.1,4-9.1,9.1
		S408.3,20.2,413.4,20.2L413.4,20.2z"
      />
      <path
        d="M82.5,58.6h21.3c0,0-0.9-10.1-10.5-10.1S82.5,58.6,82.5,58.6L82.5,58.6L82.5,58.6z M93.6,36.3
		c28.7,0,27.6,28,27.6,28v4.4h-39c0,6.4,5.3,11.9,12.6,11.9c7.3,0,11-7,11-7l13.8,6.8c-6.4,9.6-15,13.5-24.6,13.5
		C65,94,64.4,69.1,64.4,66C64.4,62.9,64.9,36.3,93.6,36.3L93.6,36.3L93.6,36.3z M157.6,78.3L157.6,78.3c-0.9,0-12,0.2-12-13.5
		c0-13.6,12.2-14.1,12.2-14.1s13.6,0.4,13.6,13.8C171.4,77.8,158.5,78.3,157.6,78.3L157.6,78.3L157.6,78.3z M170.7,1.6v41.1
		c0,0-8.3-6.4-17.8-6.4c-9.5,0-25.5,6.7-25.5,27.7c0,21,13.6,29,25.3,29c11.7,0,18.2-7.7,18.2-7.7v6.8h16.9V1.6H170.7L170.7,1.6z
		 M284.6,39.2h17.2v30.4c0,0,0.6,10.2,10.2,10.2c9.6,0,9.5-10.2,9.5-10.2V39.3h17.3v31.3c0,0,2.2,24.5-26.4,24.5
		c-28.6,0-27.9-21.5-27.9-25.3C284.6,65.8,284.6,39.2,284.6,39.2L284.6,39.2L284.6,39.2z M348.9,1.6h17v90.6h-17V1.6L348.9,1.6z
		 M377.7,1.6h16.7v90.6h-16.7V1.6L377.7,1.6z M250.2,77.1L250.2,77.1h-16.1V20.4h8.6c0,0,11.7-0.9,11.7,9.3c0,10.2-9.3,9.3-9.3,9.3
		h-5.3v15.3c0,0,7.7,0,10.4,0c2.7,0,11.5,1.3,11.5,11.4C261.6,75.7,250.2,77.1,250.2,77.1L250.2,77.1L250.2,77.1z M264.6,44.9
		c0,0,7.9-6.1,7.9-17.7c0-11.6-8.4-22-26.3-22c-17.9,0-30,0-30,0l0,87h36.9c0,0,26.5-0.3,26.5-24.2
		C279.6,54.1,274.2,48.8,264.6,44.9L264.6,44.9L264.6,44.9z M2.5,3.4v88.9h17.9V20.3h8.4c0,0,13.2-0.6,13.2,11.4
		c0,12-11.7,11.7-11.7,11.7H26v20.5L44,92.2h21L40,55.9c0,0,20.6-1.8,20.6-24.2C60.6,9.4,45,3.4,24.3,3.4C3.5,3.4,2.5,3.4,2.5,3.4
		L2.5,3.4L2.5,3.4z"
      />
    </g>
  </SvgAnimationWrapper>
)

redbull.propTypes = {
  className: PropTypes.string,
  entered: PropTypes.bool,
  width: PropTypes.number,
  delay: PropTypes.number,
}

export default redbull
