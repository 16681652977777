import React from 'react'
import PropTypes from 'prop-types'
import SvgAnimationWrapper from '../../../components/SvgAnimationWrapper'

const yoco = ({ className, entered, width, delay }) => (
  <SvgAnimationWrapper
    entered={entered}
    className={className}
    viewBox="0 0 120 48"
    width={width}
    strokeOffset={570}
    delay={delay}
    strokeWidth={2}
  >
    <path
      d="M85,0H24C10.7,0,0,10.7,0,24v0c0,13.3,10.7,24,24,24h61c13.3,0,24-10.7,24-24v0C109,10.7,98.3,0,85,0z
		 M28.1,32.9h-6.1v-5l-7.3-12.6h6.7l3.8,6.6l4-6.6h6.6l-7.7,12.6V32.9z M53.8,24.2c0,2.1-0.4,3.8-1.3,5.1c-1.7,2.7-4.7,4-8.8,4
		c-2,0-3.8-0.3-5.3-1c-3-1.3-4.7-4-4.7-8.2v0c0-2,0.4-3.6,1.3-5c1.7-2.8,4.8-4.3,8.8-4.3c1.9,0,3.7,0.4,5.2,1.1
		C52,17.4,53.8,20.2,53.8,24.2L53.8,24.2z M71.5,22.3c0,0-3-2-5.7-2c-2.5,0-4.5,1.2-4.5,3.9v0c0,2.5,2,3.7,4.5,3.7
		c2.3,0,4.7-1.4,5.5-1.8l0.1-0.1c0.1,0,0.1-0.1,0.1-0.1v5.7c-1.7,1.2-3.9,1.7-6.6,1.7c-2,0-3.8-0.3-5.3-1c-3-1.3-4.7-4-4.7-8.2v0
		c0-2,0.4-3.6,1.3-5c1.7-2.8,4.8-4.3,8.8-4.3c1.9,0,3.7,0.4,5.2,1.1c0.5,0.2,0.9,0.5,1.3,0.8V22.3z M92.7,24.2
		c0,2.1-0.4,3.8-1.3,5.1c-1.7,2.7-4.7,4-8.8,4c-2,0-3.8-0.3-5.3-1c-3-1.3-4.7-4-4.7-8.2v0c0-2,0.4-3.6,1.3-5
		c1.7-2.8,4.8-4.3,8.8-4.3c1.9,0,3.7,0.4,5.2,1.1C90.9,17.4,92.7,20.2,92.7,24.2L92.7,24.2z"
    />
    <path d="M43.8,20.3c-2.5,0-4,1.2-4,3.9v0c0,2.5,1.5,3.7,4,3.7c2.5,0,4-1.1,4-3.7C47.8,21.5,46.3,20.3,43.8,20.3z" />
    <path d="M82.7,20.3c-2.5,0-4,1.2-4,3.9v0c0,2.5,1.5,3.7,4,3.7c2.5,0,4-1.1,4-3.7C86.7,21.5,85.2,20.3,82.7,20.3z" />
  </SvgAnimationWrapper>
)

yoco.propTypes = {
  className: PropTypes.string,
  entered: PropTypes.bool,
  width: PropTypes.number,
  delay: PropTypes.number,
}

export default yoco
