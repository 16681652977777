import React from 'react'
import PropTypes from 'prop-types'
import SvgAnimationWrapper from '../../../components/SvgAnimationWrapper'

const bcx = ({ className, entered, width, delay }) => (
  <SvgAnimationWrapper
    entered={entered}
    className={className}
    viewBox="0 0 379.3 155.2"
    width={width}
    strokeOffset={570}
    delay={delay}
    strokeWidth={4}
  >
    <path
      d="M104.9,77c8.3-8,13.4-19.3,13.3-31.7c0-24.4-19.8-43.6-44.2-43.5H4.4v151.5h70.1c24.3-0.1,44-20.3,43.9-44.7
	C118.5,96.2,113.3,85,104.9,77z M74.1,26c10.7,0,19.4,8.6,19.5,19.3c0,10.2-7.9,18.6-17.9,19.4c-0.5,0-0.9,0.1-1.4,0.1H27.5V26.1
	L74.1,26z M74.5,128.2l-47,0.1V89.5l46.9-0.1c0.5,0,0.9,0,1.4,0.1c10.1,0.7,18,9,18.1,19.3C93.9,119.4,85.2,128.1,74.5,128.2z"
    />
    <path
      d="M200.2,27.5h29.6V1.8h-29.6c-41.8,0-75.7,33.9-75.7,75.7s33.9,75.7,75.7,75.7h29.6v-25.6h-29.6l0,0
	c-27.7,0-50.1-22.4-50.1-50.1C150.1,49.9,172.5,27.5,200.2,27.5z"
    />
    <g>
      <polygon points="322.2,80.7 374.9,1.8 340.5,1.8 304.9,54.9 	" />
    </g>
    <polygon points="323.4,76.4 306.1,102.1 340.3,153.3 374.7,153.3 " />
    <polygon points="324.2,77.6 273.5,1.8 239.1,1.8 289.7,77.6 239.1,153.3 273.5,153.3 306.9,103.3 " />
  </SvgAnimationWrapper>
)

bcx.propTypes = {
  className: PropTypes.string,
  entered: PropTypes.bool,
  width: PropTypes.number,
  delay: PropTypes.number,
}

export default bcx
