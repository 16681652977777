import React from 'react'
import PropTypes from 'prop-types'
import SvgAnimationWrapper from '../../../components/SvgAnimationWrapper'

const caarts = ({ className, entered, width, delay }) => (
  <SvgAnimationWrapper
    entered={entered}
    className={className}
    viewBox="0 0 83.9 30.7"
    width={width}
    strokeOffset={110}
    delay={delay}
    strokeWidth={0.1}
  >
    <g transform="matrix(3.8438 0 0 4.0333 -170.01 -177.16)">
      <path
        d="M47.6,48.3l0.7,0l0,0.4c0,1.9-0.5,2.9-1.9,2.8c-1.4-0.1-2.1-1.2-2.1-3.8c0-1.2,0.1-2.2,0.5-2.8
		c0.4-0.7,1-0.9,1.8-0.9c0.6,0,1,0.2,1.3,0.6c0.3,0.4,0.5,0.9,0.3,1.5c-0.1,0.4-0.2,0.5-0.4,0.7c-0.1,0.1-0.4,0.1-0.5,0.1
		c-0.2,0-0.7-0.2-0.7-0.8c0-0.2,0-0.4,0.1-0.5c0.1-0.2,0.3-0.2,0.5-0.2c0,0,0.3,0,0.4,0.2c0,0,0.1,0,0.1,0.2c0,0,0,0.1,0.1,0.1
		c0,0,0,0,0-0.1c0-0.1,0-0.2,0-0.2c-0.1-0.6-0.6-0.8-1-0.8c-0.4,0-0.8,0.3-1,0.6c-0.1,0.4-0.2,1.2-0.2,2.2c0,1.1,0,2,0.1,2.4
		c0.1,0.4,0.3,0.9,0.9,0.9c0.6,0,1-0.4,1-2.1L47.6,48.3z"
      />
      <path
        d="M49.4,44.7c-0.4,0.1-0.6,0.6-0.6,0.6c0,0,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2c0.1-0.2,0.2-0.4,0.5-0.4
		c0.2,0,0.3,0,0.4,0.1c0.1,0,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.2,0.1,0.4c0,0.3,0,0.4-0.2,0.6c-0.2,0.2-0.3,0.3-0.6,0.3
		c-0.3,0-0.4-0.1-0.6-0.3c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.6,0.2-1,0.6-1.3c0.4-0.4,0.9-0.4,1.6-0.4c0.7,0,1.2,0.2,1.6,0.7
		c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.2,0.1,0.4,0.1,0.9l0,3.7c0,0.4-0.1,1,0.3,1c0.1,0,0.4,0,0.4-0.6l0-1.2l0.5,0l0,0.7
		c0,1.1-0.1,1.7-1.2,1.8c-0.6,0-1-0.2-1.2-0.9c-0.4,0.7-0.9,0.9-1.4,0.9c-0.5,0-1-0.2-1.3-0.6c-0.3-0.3-0.3-0.8-0.3-1.4
		c0-0.9,0.5-1.4,1.2-1.8l0.8-0.5c0.6-0.3,1-0.9,1-1.5c0-0.3-0.1-0.6-0.3-0.8C50.6,44.8,50.2,44.5,49.4,44.7 M51.1,47.3
		c-0.5,0.5-1.1,1-1.3,1.2c-0.2,0.2-0.4,0.7-0.5,0.9c-0.2,1.1,0.3,1.3,0.6,1.3c0.9,0,1.1-1.2,1.1-2.2L51.1,47.3z"
      />
      <path
        d="M53.6,48.2c0.1,0,0.1,0,0.2,0V48c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3-0.2-0.3-0.8c0-0.5,0.1-0.8,0.3-0.8
		c0,0,0.1,0,0.1,0v-0.2c-0.1,0-0.1,0-0.2,0c-0.3,0-0.4,0.4-0.4,1C53.2,47.9,53.3,48.2,53.6,48.2 M54.2,48.2c0.2,0,0.3-0.4,0.3-0.8
		c0-0.5,0-0.8-0.3-0.8c-0.2,0-0.3,0.3-0.3,0.8C53.9,48,53.9,48.2,54.2,48.2 M54.2,48c-0.1,0-0.1-0.1-0.1-0.6c0-0.4,0-0.6,0.1-0.6
		c0.1,0,0.1,0.2,0.1,0.6C54.3,47.8,54.3,48,54.2,48 M55.6,48.2h0.2V47c0-0.3-0.1-0.4-0.2-0.4c-0.1,0-0.2,0.1-0.2,0.2
		c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2l0-0.1h-0.2l0,0.3v1.2h0.2v-1.2c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.1,0.1,0.1,0.3v1.1
		h0.2v-1.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.1,0.1,0.1,0.3V48.2z M56.8,48.2H57V47c0-0.3-0.1-0.4-0.2-0.4c-0.1,0-0.2,0.1-0.2,0.2
		c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2l0-0.1h-0.2l0,0.3v1.2h0.2v-1.2c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.1,0.1,0.1,0.3v1.1
		h0.2v-1.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.1,0.1,0.1,0.3V48.2z M57.8,46.7h-0.2v1.2c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.1-0.1-0.1-0.3
		v-1h-0.2v1.1c0,0.3,0.1,0.4,0.2,0.4c0.1,0,0.2-0.1,0.3-0.2l0,0.2h0.2l0-0.3L57.8,46.7z M58,48.2h0.2V47c0-0.1,0.1-0.2,0.2-0.2
		c0.1,0,0.1,0.1,0.1,0.3v1.1h0.2V47c0-0.3-0.1-0.4-0.2-0.4c-0.1,0-0.2,0.1-0.3,0.2l0-0.2H58l0,0.3L58,48.2z M58.8,46.8L58.8,46.8
		l0.1,1.3h0.2v-1.5h-0.3L58.8,46.8z M58.9,46.1c0,0.1,0.1,0.1,0.1,0.1c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1
		C58.9,46,58.9,46.1,58.9,46.1 M59.6,48.2c0.1,0,0.1,0,0.2,0V48c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.2-0.6c0-0.4,0.1-0.6,0.2-0.6
		c0,0,0.1,0,0.1,0v-0.2c-0.1,0-0.1,0-0.1,0c-0.2,0-0.4,0.3-0.4,0.8C59.3,47.9,59.4,48.2,59.6,48.2 M60.3,46.6
		c-0.3,0-0.4,0.2-0.4,0.8c0,0.5,0.1,0.7,0.3,0.7c0.1,0,0.2-0.1,0.2-0.2l0,0.1h0.2l0-0.3v-1.2C60.5,46.6,60.4,46.6,60.3,46.6
		 M60.2,48c-0.1,0-0.2-0.1-0.2-0.5c0-0.5,0-0.7,0.2-0.7c0,0,0.1,0,0.1,0v1.1C60.3,47.9,60.3,48,60.2,48 M60.7,46.8h0.1v1.1
		c0,0.2,0.1,0.3,0.2,0.3c0.1,0,0.1,0,0.2,0V48c0,0-0.1,0-0.1,0C61,48,61,47.9,61,47.8v-1h0.2v-0.2H61v-0.4h-0.2v0.4h-0.1L60.7,46.8z
		 M61.2,46.8L61.2,46.8l0.1,1.3h0.2v-1.5h-0.3L61.2,46.8z M61.3,46.1c0,0.1,0.1,0.1,0.1,0.1s0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1
		S61.3,46.1,61.3,46.1 M62,48.2c0.2,0,0.3-0.4,0.3-0.8c0-0.5,0-0.8-0.3-0.8c-0.2,0-0.3,0.3-0.3,0.8C61.6,48,61.7,48.2,62,48.2
		 M62,48c-0.1,0-0.1-0.1-0.1-0.6c0-0.4,0-0.6,0.1-0.6c0.1,0,0.1,0.2,0.1,0.6C62.1,47.8,62.1,48,62,48 M62.5,48.2h0.2V47
		c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.1,0.1,0.1,0.3v1.1h0.2V47c0-0.3-0.1-0.4-0.2-0.4c-0.1,0-0.2,0.1-0.3,0.2l0-0.2h-0.2l0,0.3
		L62.5,48.2z M63.6,48.2h0.2l0.1-0.6h0.3l0.1,0.6h0.2l-0.3-1.9h-0.2L63.6,48.2z M63.9,47l0.1-0.5h0L64,47l0,0.4h-0.2L63.9,47z
		 M64.5,48.2h0.2v-1.1c0-0.1,0.1-0.2,0.2-0.2c0,0,0,0,0,0v-0.2c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.2,0.2l0-0.2h-0.2l0,0.3L64.5,48.2z
		 M64.9,46.8h0.1v1.1c0,0.2,0.1,0.3,0.2,0.3c0.1,0,0.1,0,0.2,0V48c0,0-0.1,0-0.1,0c-0.1,0-0.1-0.1-0.1-0.2v-1h0.2v-0.2h-0.2v-0.4
		h-0.2v0.4h-0.1V46.8z M65.7,48.2c0.2,0,0.3-0.1,0.3-0.4c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.1-0.2-0.3c0-0.2,0.1-0.3,0.2-0.3
		c0,0,0.1,0,0.2,0v-0.2c0,0-0.1,0-0.2,0c-0.2,0-0.3,0.2-0.3,0.4c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.1,0.2,0.3
		c0,0.2-0.1,0.3-0.2,0.3c0,0-0.1,0-0.2,0v0.2C65.6,48.2,65.7,48.2,65.7,48.2"
      />
    </g>
  </SvgAnimationWrapper>
)

caarts.propTypes = {
  className: PropTypes.string,
  entered: PropTypes.bool,
  width: PropTypes.number,
  delay: PropTypes.number,
}

export default caarts
