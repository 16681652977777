import React from 'react'
import PropTypes from 'prop-types'
import SvgAnimationWrapper from '../../../components/SvgAnimationWrapper'

const uxpin = ({ className, entered, width, delay }) => (
  <SvgAnimationWrapper
    entered={entered}
    className={className}
    viewBox="0 0 348 145"
    width={width}
    strokeOffset={650}
    delay={delay}
    strokeWidth={4}
  >
    <g>
      <path
        d="M0,1c5.1,0,10.3,0,16,0c0,1.9,0,3.7,0,5.4c0,32.6-0.1,65.3,0.1,97.9c0,4.9,0.7,10,1.8,14.8
		c1.6,7,7.7,11.7,14.8,11.8c3.8,0.1,7.8,0.2,11.3-0.9c10.5-3.3,11.6-12.7,12.9-21.7c0.5-3.8,0.1-7.6,0.1-11.5c0-32.3,0-64.6,0-96.9
		c5,0,10,0,15,0c-0.3,37.9-0.1,75.8-1.1,113.6c-0.5,18.1-10.1,27.4-28.2,30.6c-0.6,0.1-1.2,0.5-1.7,0.8c-4.3,0-8.7,0-13,0
		c-1.3-0.4-2.5-0.8-3.8-1.1c-12.4-2.8-20.1-10.3-22.9-22.8c-0.4-1.7-0.9-3.4-1.3-5.2C0,77.7,0,39.3,0,1z"
      />
      <path
        d="M158,0c-4.8,11.7-9.4,23.3-14.3,35c-3.6,8.8-7.3,17.5-11.2,26.1c-1.4,3-1.2,5.5,0,8.5
		c9.3,22.6,18.5,45.2,27.7,67.8c0.5,1.3,1,2.7,1.5,4.1c-0.6,0.2-0.9,0.4-1.2,0.4c-5.3,0-11.8,1.8-15.6-0.6c-3.7-2.4-4.7-9-6.6-13.9
		c-5.6-14.5-11.1-29-17.1-44.8c-3,7.8-5.6,14.6-8.2,21.3c-4.3,11.2-8.7,22.3-12.7,33.6c-1.2,3.5-3,4.6-6.5,4.3
		c-3.4-0.3-6.9-0.1-11-0.1c5.2-12.2,10.1-23.8,15-35.4c5-11.8,10-23.5,14.9-35.4c0.6-1.5,1-3.7,0.5-5.1c-8.9-22-18-44-27-65.9
		c6,0,12,0,18,0c1,3.1,1.9,6.3,3.1,9.4c5,13.5,10.1,26.9,15.5,41.3C129.6,33.2,136.3,16.6,143,0C148,0,153,0,158,0z"
      />
      <path
        d="M212,0c0.3,0.2,0.6,0.4,0.9,0.5c21.7,4.4,29.8,12.4,31.5,34.5c0.7,8.9-0.7,18.4-3.3,27
		c-4.3,14.3-17.8,22.2-34.4,22.1c-4.8,0-9.6,0-14.7,0c0,19.4,0,38.3,0,57.6c-5.4,0-10.5,0-15.9,0c-0.1-1-0.4-2.1-0.4-3.2
		c0-45.2,0-90.3,0-135.5c0-1,0.2-2,0.3-3C188,0,200,0,212,0z M191.6,14.1c0,18.9,0,37.5,0,56.7c6.6-0.3,12.8-0.5,19.1-0.9
		c8.7-0.6,14.3-4.9,16.6-13.3c2.8-10.4,2.7-20.7-1.3-30.8c-2.4-6.1-6.8-10.7-13.3-11.5C205.8,13.5,198.8,14.1,191.6,14.1z"
      />
      <path
        d="M348,142c-4.5,0-8.9,0-14.2,0c0-2.5,0-4.4,0-6.3c-0.3-23.8-0.5-47.6-0.9-71.5c0-2.8-0.8-5.7-1.8-8.2
		c-4.1-9.7-15.3-11-21.6-2.4c-5.7,7.7-6.1,16.8-6.3,25.8c-0.3,18.8-0.1,37.7-0.1,56.5c0,1.8,0,3.6,0,5.7c-4.8,0-9,0-13.7,0
		c0-34.6,0-69.3,0-104.3c4.3,0,8.6,0,13.3,0c0,4.4,0,8.6,0,12.8c0.3,0.1,0.6,0.2,0.9,0.2c0.6-1.1,1.1-2.1,1.7-3.2
		c5.2-9.9,14.6-14.3,25.5-12.1c9.6,2,15.4,9.4,16.4,20.6c0.1,1.5,0.6,2.9,0.8,4.3C348,87.3,348,114.7,348,142z"
      />
      <path d="M273,0c0,5.8,0,11.5,0,17.6c-4.8,0-9.7,0-15,0c0-5.9,0-11.7,0-17.6C263,0,268,0,273,0z" />
      <path
        d="M258.4,37.3c4.7,0,9.2,0,13.8,0c0,34.8,0,69.4,0,104.2c-4.7,0-9.1,0-13.8,0C258.4,106.7,258.4,72.2,258.4,37.3
		z"
      />
    </g>
  </SvgAnimationWrapper>
)

uxpin.propTypes = {
  className: PropTypes.string,
  entered: PropTypes.bool,
  width: PropTypes.number,
  delay: PropTypes.number,
}

export default uxpin
