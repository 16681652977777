import React from 'react'
import PropTypes from 'prop-types'
import SvgAnimationWrapper from '../../../components/SvgAnimationWrapper'
import { colors } from '../../../theme'

const invision = ({ className, entered, width, delay }) => (
  <SvgAnimationWrapper
    entered={entered}
    className={className}
    viewBox="0 0 599.9 205.1"
    width={width}
    strokeOffset={790}
    delay={delay}
    strokeWidth={4}
  >
    <g>
      <path
        d="M181.8,0H18.1C8.1,0,0,8.4,0,18.6v167.9c0,10.2,8.2,18.6,18.1,18.6h163.7c10,0,18.1-8.4,18.1-18.6
		V18.6C199.9,8.4,191.7,0,181.8,0z"
      />
      <path
        d="M281.5,160.3h19.3V73.4h-19.3V160.3z M352.1,108.9c-9.9-3.2-19-6-19-12.2v-0.3
		c0-5.1,4.3-8.7,11.9-8.7c3.6,0,7.7,0.9,12,2.4c0,0,0,0,0.2,0c0.3,0.2,0.7,0.3,1.2,0.5c3.1,1,11.2,2.4,18.3-8.8
		c-8.9-6.1-20.4-9.9-31-9.9c-16.8,0-30,10-30,26.5v0.3c0,16.7,13.8,21.9,26.4,25.5c9.9,3.1,18.8,5.4,18.8,12.2v0.3
		c0,5.8-4.8,9.4-13,9.4c-5.4,0-11.5-1.5-17.6-4.4c-1.8-0.7-10.7-3.9-18.5,7.3c10.5,8.5,23.6,12.9,35.6,12.9c17.5,0,31.3-9,31.3-27.4
		v-0.2C378.3,118.6,364.4,112.9,352.1,108.9z M229,137.2l-22.6-64h-20.8l34.6,87.6h17.3l34.4-87.4h-20.3L229,137.2z M467.9,71.5
		c-25.9,0-45,20.6-45,45.6c0,24.8,19,45.1,44.7,45.1c26,0,45.2-20.6,45.2-45.4C512.7,91.8,493.6,71.5,467.9,71.5z M467.9,145
		c-15,0-25.7-12.8-25.7-28.2c0-15.3,9.9-27.9,25.4-27.9c15.2,0,25.9,12.8,25.9,28.2C493.3,132.4,483.4,145,467.9,145z M569.9,71.5
		c-13,0-20.9,7.1-26.4,15.3V73.4h-19.3v86.9h19.3v-49.1c0-13.4,7.7-21.6,19-21.6c11.5,0,18,7.7,18,21.3v49.3h19.4v-55.1
		C599.9,84.8,588.9,71.5,569.9,71.5z M391,160.3h19.3V73.4H391V160.3z"
      />
      <path
        style={{ fill: colors.primary }}
        d="M69,62c6.5,0,11.9-5.2,11.9-11.9c0-6.8-5.4-11.9-11.9-11.9s-11.9,5.2-11.9,11.9S62.5,62,69,62z"
      />
      <path
        style={{ fill: colors.primary }}
        d="M43.2,130.8c-0.7,3.1-1.2,6.3-1.2,8.9c0,10.6,5.8,17.6,18.3,17.6c10.3,0,18.6-6,24.6-15.6
		l-3.7,14.5h20.5l11.7-45.9c3-11.5,8.7-17.7,17.1-17.7c6.8,0,11,4.1,11,10.9c0,2-0.2,4.1-0.8,6.5l-6,21.1c-0.8,3.1-1.3,6-1.3,8.9
		c0,10.1,6,17.4,18.6,17.4c10.8,0,19.5-6.8,24.1-23.1l-8-3.1c-4,10.9-7.5,12.8-10.3,12.8c-2.7,0-4.2-1.8-4.2-5.4
		c0-1.6,0.3-3.4,0.8-5.5l6.2-20.5c1.5-4.9,2-9.1,2-13c0-15.4-9.5-23.4-21-23.4c-10.8,0-21.8,9.4-27.3,19.5l4-18H87l-4.5,15.8h14.6
		l-9,35.3c-7.2,15.4-20.1,15.6-21.6,15.3c-2.7-0.7-4.3-1.6-4.3-5c0-2,0.3-4.7,1.3-8.1l13.6-53.1H42.5L38,93.7h14.5L43.2,130.8z"
      />
    </g>
  </SvgAnimationWrapper>
)

invision.propTypes = {
  className: PropTypes.string,
  entered: PropTypes.bool,
  width: PropTypes.number,
  delay: PropTypes.number,
}

export default invision
