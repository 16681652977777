import { keyframes } from 'styled-components'

export const svgAnimationInKeyframes = (strokeOffset, strokeWidth, color) =>
  keyframes`
    0% {
        stroke-dashoffset: ${strokeOffset};
        stroke-width: ${strokeWidth};
      }
      30% {
        fill: transparent;
      }
      50% {
        stroke-dashoffset: 0;
        stroke-width: ${strokeWidth};
      }
      100% {
        stroke-dashoffset: 0;
        stroke-width: 0;
        fill: ${color};
      }
  `

export const svgAnimationOutKeyframes = (strokeOffset, strokeWidth, color) =>
  keyframes`
    0% {
      stroke-dashoffset: 0;
      fill: ${color};
      stroke-width: 0;
    }
    50% {
      stroke-dashoffset: 0;
      stroke-width: ${strokeWidth};
    }
    70% {
      fill: transparent;
    }
    100% {
      stroke-dashoffset: ${strokeOffset};
    }
  `
