import PropTypes from 'prop-types'
import React from 'react'

import SvgAnimationWrapper from '../../../components/SvgAnimationWrapper'

const motorhappy = ({ className, entered, width, delay }) => (
  <SvgAnimationWrapper
    entered={entered}
    className={className}
    width={width}
    viewBox="0 0 171 39"
    strokeOffset={180}
    delay={delay}
  >
    <g>
      <polygon
        points="18.1,0.2 10.9,17.5 3.8,0.2 0.2,0.2 0.2,21.8 3.8,21.8 3.8,9.3 9.2,21.8 12.7,21.8 18.1,9.3
						18.1,21.8 21.7,21.8 21.7,0.2"
      />
      <path
        d="M35.4,11.4c-0.7-0.8-1.6-1.3-2.8-1.3c-1.2,0-2.1,0.4-2.8,1.3c-0.7,0.8-1.1,1.9-1.1,3.1
						c0,0.8,0.2,1.5,0.5,2.1s0.8,1.2,1.4,1.6c0.6,0.4,1.3,0.6,2,0.6c1.2,0,2.1-0.4,2.8-1.2c0.7-0.8,1-1.8,1-3.1S36.1,12.3,35.4,11.4
						 M39,18.4c-0.7,1.2-1.5,2.1-2.7,2.8c-1.1,0.7-2.4,1-3.7,1c-1.3,0-2.6-0.4-3.7-1c-1.1-0.7-2-1.6-2.7-2.8c-0.7-1.2-1-2.4-1-3.8
						c0-1.4,0.3-2.7,1-3.9c0.7-1.2,1.6-2.1,2.7-2.8s2.4-1,3.7-1c1.4,0,2.6,0.3,3.7,1c1.1,0.7,2,1.6,2.7,2.8c0.7,1.2,1,2.5,1,3.9
						C40,15.9,39.7,17.2,39,18.4"
      />
      <path
        d="M47.6,10.2v7.1c0,0.5,0.1,0.9,0.3,1c0.2,0.1,0.6,0.2,1.1,0.2l1.5,0v3.2l-3.3,0c-1.1,0-1.9-0.3-2.4-1
						C44.3,20,44,19.1,44,17.8v-7.6h-2.5v-3H44V2.6h3.6v4.6h2.9v3H47.6z"
      />
      <path
        d="M63,11.4c-0.7-0.8-1.6-1.3-2.8-1.3c-1.2,0-2.1,0.4-2.8,1.3c-0.7,0.8-1.1,1.9-1.1,3.1
						c0,0.8,0.2,1.5,0.5,2.1s0.8,1.2,1.4,1.6c0.6,0.4,1.3,0.6,2,0.6c1.2,0,2.1-0.4,2.8-1.2c0.7-0.8,1-1.8,1-3.1S63.7,12.3,63,11.4
						 M66.6,18.4c-0.7,1.2-1.5,2.1-2.7,2.8c-1.1,0.7-2.4,1-3.7,1c-1.3,0-2.6-0.4-3.7-1c-1.1-0.7-2-1.6-2.7-2.8c-0.7-1.2-1-2.4-1-3.8
						c0-1.4,0.3-2.7,1-3.9s1.6-2.1,2.7-2.8s2.4-1,3.7-1c1.4,0,2.6,0.3,3.7,1c1.1,0.7,2,1.6,2.7,2.8c0.7,1.2,1,2.5,1,3.9
						C67.6,15.9,67.3,17.2,66.6,18.4"
      />
      <path
        d="M78.6,7.2l0.7,0v3.2c-0.3-0.1-0.7-0.1-1.1-0.1c-1.1,0-2,0.4-2.7,1.1c-0.7,0.8-1.1,1.7-1.2,2.9v7.4h-3.5
						l0-14.6h3.5V9C75.2,7.8,76.6,7.2,78.6,7.2"
      />
      <path
        d="M46.4,38.5c-7.6,0-14.7-3-20-8.5l2.6-2.5c4.6,4.8,10.8,7.4,17.5,7.4c6.7,0,12.9-2.6,17.5-7.4l2.6,2.5
						C61.2,35.4,54,38.5,46.4,38.5z"
      />
      <polygon
        points="99.1,0.2 99.1,21.8 95.5,21.8 95.5,12.6 86,12.6 86,21.8 82.4,21.8 82.4,0.2 86,0.2 86,9.1
						95.5,9.2 95.5,0.2"
      />
      <path
        d="M111.2,18.4c0.7-0.5,1-1.3,1.1-2.3v-0.8c-0.6,0-1.7,0-3.2,0c-1,0-1.7,0.2-2.2,0.6
						c-0.5,0.4-0.8,0.9-0.8,1.5c0,0.6,0.2,1.1,0.7,1.4c0.5,0.3,1.1,0.4,1.8,0.4C109.7,19.2,110.6,18.9,111.2,18.4 M112.8,7.4
						c0.9,0.4,1.7,1.1,2.2,1.9c0.5,0.8,0.8,1.9,0.8,3v9.6h-3.5v-1.3c-0.5,0.5-1.1,0.8-1.7,1.1c-0.7,0.3-1.3,0.4-2.1,0.4
						c-1.7,0-3-0.4-4-1.3c-1-0.9-1.6-2-1.6-3.4c0-0.8,0.2-1.6,0.5-2.4c0.4-0.7,0.9-1.3,1.6-1.8c0.4-0.3,1-0.5,1.6-0.6
						s1.4-0.2,2.4-0.2h2.3c0.6,0,0.9-0.3,0.9-0.9c-0.2-1.2-1.1-1.8-2.9-1.8c-0.8,0-1.5,0.2-2,0.5c-0.6,0.3-1.1,0.8-1.5,1.6l-2.7-1.5
						c0.8-1.4,1.7-2.3,2.9-2.9c1.1-0.5,2.4-0.8,3.8-0.8C110.9,6.8,111.9,7,112.8,7.4"
      />
      <path
        d="M130.5,18c0.8-0.8,1.3-1.9,1.3-3.4c0-1-0.2-1.9-0.6-2.6c-0.4-0.7-0.9-1.3-1.5-1.7c-0.6-0.4-1.3-0.6-2-0.6
						c-1.1,0-2.1,0.4-2.9,1.2c-0.8,0.8-1.2,1.9-1.3,3.3V15c0,0.9,0.3,1.7,0.6,2.3c0.4,0.6,0.9,1.1,1.5,1.5c0.6,0.3,1.2,0.5,1.9,0.5
						C128.7,19.2,129.6,18.8,130.5,18 M131.8,7.7c1.1,0.6,2,1.6,2.6,2.8c0.6,1.2,0.9,2.7,0.9,4.3c-0.1,1.6-0.5,2.9-1.1,4.1
						c-0.7,1.1-1.5,2-2.6,2.6c-1,0.6-2.2,0.9-3.4,0.9c-0.9,0-1.7-0.2-2.5-0.5c-0.8-0.3-1.5-0.8-2.1-1.5v8.4h-3.8V7.2h3.8v1.5
						c0.6-0.7,1.3-1.2,2.2-1.5c0.8-0.3,1.7-0.5,2.5-0.5C129.5,6.7,130.7,7,131.8,7.7"
      />
      <path
        d="M149.2,18c0.8-0.8,1.3-1.9,1.3-3.4c0-1-0.2-1.9-0.6-2.6c-0.4-0.7-0.9-1.3-1.5-1.7c-0.6-0.4-1.3-0.6-2-0.6
						c-1.1,0-2.1,0.4-2.9,1.2c-0.8,0.8-1.2,1.9-1.3,3.3V15c0,0.9,0.3,1.7,0.6,2.3c0.4,0.6,0.9,1.1,1.5,1.5c0.6,0.3,1.2,0.5,1.9,0.5
						C147.4,19.2,148.4,18.8,149.2,18 M150.5,7.7c1.1,0.6,2,1.6,2.6,2.8c0.6,1.2,0.9,2.7,0.9,4.3c-0.1,1.6-0.5,2.9-1.1,4.1
						c-0.7,1.1-1.5,2-2.6,2.6c-1,0.6-2.2,0.9-3.4,0.9c-0.9,0-1.7-0.2-2.5-0.5c-0.8-0.3-1.5-0.8-2.1-1.5v8.4h-3.8V7.2h3.8v1.5
						c0.6-0.7,1.3-1.2,2.2-1.5c0.8-0.3,1.7-0.5,2.5-0.5C148.2,6.7,149.4,7,150.5,7.7"
      />
      <polygon points="170.8,7.2 162.2,28.6 158.4,28.6 161.2,21.7 154.6,7.2 158.4,7.2 163,17.4 167,7.2" />
    </g>
  </SvgAnimationWrapper>
)

motorhappy.propTypes = {
  className: PropTypes.string,
  entered: PropTypes.bool,
  width: PropTypes.number,
  delay: PropTypes.number,
}

export default motorhappy
