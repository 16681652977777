import React from 'react'
import PropTypes from 'prop-types'
import SvgAnimationWrapper from '../../../components/SvgAnimationWrapper'

const mtn = ({ className, entered, width, delay }) => (
  <SvgAnimationWrapper
    entered={entered}
    className={className}
    viewBox="0 0 480.9 528.6"
    width={width}
    strokeOffset={2100}
    delay={delay}
    strokeWidth={3}
  >
    <g>
      <path
        d="M94.9,334.9c2.3,0.5,4.7,0.2,7,0.3c6.4,0.3,10.4-3.5,13.3-8.5c2.3-3.9,3.3-8.2,2.6-12.5
		c0.3-4.4,0-8.3-1.9-12.1c-3.9-7.5-11.5-11.9-20.2-9.8c-7.2,1.8-11.1,6.5-12.7,13.2c-0.8,3.6-0.6,7.4-0.8,11.1c-0.2,3.1,0.5,6,2,8.8
		C86.5,330,89.6,333.8,94.9,334.9z"
      />
      <path
        d="M135.8,205.1c-3.6-10-7.2-20-10.7-30c-0.3-0.7-0.7-1.4-1.3-2.5c-4.8,13.1-9.4,25.7-14.2,38.5
		c2.6,0,4.9,0,7.3,0c6.3,0,12.7,0,19,0c1.6,0,2.1-0.5,1.4-2C136.8,207.9,136.3,206.5,135.8,205.1z"
      />
      <path
        d="M233,196c6.9-0.4,13.9,0.8,20.8-0.8c3.8-0.9,7.5-1.9,10.6-4.6c4.2-3.6,6-8.1,5.7-13.6
		c-0.2-3.3-1.2-6.1-3.5-8.5c-4.4-4.6-10.4-5.4-16.1-5.9c-5.8-0.5-11.6-0.1-17.5-0.1c-1.3,0-1.7,0.4-1.7,1.8c0.1,5,0,10,0,15
		c0,5,0,10,0,15C231.2,195.6,231.7,196.1,233,196z"
      />
      <path
        d="M280.9,400c-0.2-0.6-0.6-1.1-1-1.6c-0.2,0-0.4,0-0.5,0c-0.5,1.4-1,2.8-1.5,4.2c-2.5,6.8-5,13.6-7.5,20.4
		c-0.6,1.5-0.2,2,1.5,2c5.4-0.1,10.9,0,16.3,0c0.5,0,1-0.1,1.6-0.2c-0.1-0.6-0.2-1-0.3-1.4C286.6,415.6,283.7,407.8,280.9,400z"
      />
      <path
        d="M207.3,84.3c0.7-0.2,1.3-0.4,1.8-0.8c0.5-0.4,0.9-0.8,1.2-1.4c0.3-0.6,0.4-1.2,0.4-2c0-1.5-0.6-2.7-1.7-3.3
		c-1.1-0.7-2.7-1-4.6-1h-4.6v8.8h5.3C205.8,84.6,206.6,84.5,207.3,84.3z"
      />
      <path
        d="M209.8,96.8c0.7-0.4,1.2-0.9,1.7-1.5c0.4-0.6,0.6-1.4,0.6-2.4c0-1.7-0.6-2.9-1.8-3.7c-1.2-0.8-2.9-1.1-5.1-1.1
		h-5.4v9.8h5.1c0.8,0,1.7-0.1,2.5-0.2C208.3,97.4,209.1,97.2,209.8,96.8z"
      />
      <path
        d="M384.5,397.6c-1.7-3.9-5.4-6.7-10.1-6.9c-5.5-0.2-11,0-16.5-0.1c-1.5,0-1.8,0.5-1.8,1.9c0.1,3.3,0,6.6,0,9.9
		c0,3.2,0.1,6.3,0,9.5c-0.1,2,0.6,2.5,2.5,2.4c3-0.1,6,0,8.9,0c4.1-0.1,8-0.9,11.7-2.7C383.9,409.1,386.7,402.6,384.5,397.6z"
      />
      <path
        d="M0,0v528.6h480.9V0H0z M388.5,91.4c-0.4-0.6-0.9-1.1-1.5-1.5c-0.6-0.4-1.4-0.8-2.2-1c-0.8-0.3-1.7-0.6-2.6-0.9
		c-0.9-0.3-1.8-0.6-2.7-1c-0.9-0.4-1.7-0.9-2.4-1.5c-0.7-0.6-1.3-1.4-1.7-2.3c-0.4-0.9-0.7-2-0.7-3.3c0-1.4,0.3-2.7,0.9-3.7
		c0.6-1,1.3-1.9,2.3-2.6c0.9-0.7,2-1.2,3.2-1.5c1.2-0.3,2.4-0.5,3.6-0.5c1.7,0,3.3,0.3,4.7,0.9c1.5,0.6,2.6,1.4,3.5,2.4l-2.9,2.8
		c-0.6-0.8-1.3-1.4-2.3-1.9c-1-0.5-2.1-0.8-3.3-0.8c-0.7,0-1.3,0.1-2,0.3c-0.7,0.2-1.3,0.5-1.8,0.9c-0.5,0.4-1,0.9-1.3,1.5
		c-0.3,0.6-0.5,1.3-0.5,2.1c0,0.8,0.2,1.4,0.5,2c0.3,0.5,0.7,1,1.3,1.3c0.5,0.4,1.2,0.7,1.9,0.9c0.7,0.3,1.5,0.5,2.3,0.8
		c1,0.3,2,0.6,3,1c1,0.4,1.9,0.9,2.7,1.5c0.8,0.6,1.5,1.4,2,2.3c0.5,0.9,0.8,2.1,0.8,3.6c0,1.5-0.3,2.8-0.8,3.9
		c-0.6,1.1-1.3,2-2.2,2.7c-0.9,0.7-2,1.3-3.2,1.6c-1.2,0.4-2.5,0.5-3.7,0.5c-1.8,0-3.6-0.4-5.3-1.1c-1.7-0.7-3.1-1.8-4.2-3.1
		l3.1-2.6c0.7,1,1.6,1.8,2.7,2.4c1.1,0.6,2.4,0.9,3.7,0.9c0.7,0,1.4-0.1,2-0.3c0.7-0.2,1.3-0.5,1.9-0.9c0.6-0.4,1-0.9,1.4-1.5
		c0.4-0.6,0.5-1.4,0.5-2.2C389.1,92.7,388.9,92,388.5,91.4z M363.7,91.4c-0.4-0.6-0.9-1.1-1.5-1.5c-0.6-0.4-1.4-0.8-2.2-1
		c-0.8-0.3-1.7-0.6-2.6-0.9c-0.9-0.3-1.8-0.6-2.7-1c-0.9-0.4-1.7-0.9-2.4-1.5c-0.7-0.6-1.3-1.4-1.7-2.3c-0.4-0.9-0.7-2-0.7-3.3
		c0-1.4,0.3-2.7,0.9-3.7c0.6-1,1.3-1.9,2.3-2.6c0.9-0.7,2-1.2,3.2-1.5c1.2-0.3,2.4-0.5,3.6-0.5c1.7,0,3.3,0.3,4.7,0.9
		c1.5,0.6,2.6,1.4,3.5,2.4l-2.9,2.8c-0.6-0.8-1.3-1.4-2.3-1.9c-1-0.5-2.1-0.8-3.3-0.8c-0.7,0-1.3,0.1-2,0.3
		c-0.7,0.2-1.3,0.5-1.8,0.9c-0.5,0.4-1,0.9-1.3,1.5c-0.3,0.6-0.5,1.3-0.5,2.1c0,0.8,0.2,1.4,0.5,2c0.3,0.5,0.7,1,1.3,1.3
		c0.5,0.4,1.2,0.7,1.9,0.9c0.7,0.3,1.5,0.5,2.3,0.8c1,0.3,2,0.6,3,1c1,0.4,1.9,0.9,2.7,1.5c0.8,0.6,1.5,1.4,2,2.3
		c0.5,0.9,0.8,2.1,0.8,3.6c0,1.5-0.3,2.8-0.8,3.9c-0.6,1.1-1.3,2-2.2,2.7c-0.9,0.7-2,1.3-3.2,1.6c-1.2,0.4-2.5,0.5-3.7,0.5
		c-1.8,0-3.6-0.4-5.3-1.1c-1.7-0.7-3.1-1.8-4.2-3.1l3.1-2.6c0.7,1,1.6,1.8,2.7,2.4c1.1,0.6,2.4,0.9,3.7,0.9c0.7,0,1.4-0.1,2-0.3
		c0.7-0.2,1.3-0.5,1.9-0.9c0.6-0.4,1-0.9,1.4-1.5c0.4-0.6,0.5-1.4,0.5-2.2C364.3,92.7,364.1,92,363.7,91.4z M324.8,72.4h18.5v3.5
		h-14.5v8.6h13.6V88h-13.6v9.6h15.1v3.6h-19.1V72.4z M291.7,72.4h5.3L312,95.4h0.1V72.4h4v28.8h-5.1l-15.2-23.6h-0.1v23.6h-4V72.4z
		 M279,72.4h4v28.8h-4V72.4z M267,91.4c-0.4-0.6-0.9-1.1-1.5-1.5c-0.6-0.4-1.4-0.8-2.2-1c-0.8-0.3-1.7-0.6-2.6-0.9
		c-0.9-0.3-1.8-0.6-2.7-1c-0.9-0.4-1.7-0.9-2.4-1.5c-0.7-0.6-1.3-1.4-1.7-2.3c-0.4-0.9-0.7-2-0.7-3.3c0-1.4,0.3-2.7,0.9-3.7
		c0.6-1,1.3-1.9,2.3-2.6c0.9-0.7,2-1.2,3.2-1.5c1.2-0.3,2.4-0.5,3.6-0.5c1.7,0,3.3,0.3,4.7,0.9c1.5,0.6,2.6,1.4,3.5,2.4l-2.9,2.8
		c-0.6-0.8-1.3-1.4-2.3-1.9c-1-0.5-2.1-0.8-3.3-0.8c-0.7,0-1.3,0.1-2,0.3c-0.7,0.2-1.3,0.5-1.8,0.9c-0.5,0.4-1,0.9-1.3,1.5
		c-0.3,0.6-0.5,1.3-0.5,2.1c0,0.8,0.2,1.4,0.5,2c0.3,0.5,0.7,1,1.3,1.3c0.5,0.4,1.2,0.7,1.9,0.9c0.7,0.3,1.5,0.5,2.3,0.8
		c1,0.3,2,0.6,3,1c1,0.4,1.9,0.9,2.7,1.5c0.8,0.6,1.5,1.4,2,2.3c0.5,0.9,0.8,2.1,0.8,3.6c0,1.5-0.3,2.8-0.8,3.9
		c-0.6,1.1-1.3,2-2.2,2.7c-0.9,0.7-2,1.3-3.2,1.6c-1.2,0.4-2.5,0.5-3.7,0.5c-1.8,0-3.6-0.4-5.3-1.1c-1.7-0.7-3.1-1.8-4.2-3.1
		l3.1-2.6c0.7,1,1.6,1.8,2.7,2.4c1.1,0.6,2.4,0.9,3.7,0.9c0.7,0,1.4-0.1,2-0.3c0.7-0.2,1.3-0.5,1.9-0.9c0.6-0.4,1-0.9,1.4-1.5
		c0.4-0.6,0.5-1.4,0.5-2.2C267.6,92.7,267.4,92,267,91.4z M223.4,72.4h4v17.9c0,1,0.1,1.9,0.4,2.9c0.2,1,0.6,1.8,1.2,2.6
		c0.6,0.8,1.3,1.4,2.2,1.8c0.9,0.5,2,0.7,3.4,0.7c1.3,0,2.5-0.2,3.4-0.7c0.9-0.5,1.7-1.1,2.2-1.8c0.6-0.8,1-1.6,1.2-2.6
		c0.2-1,0.4-1.9,0.4-2.9V72.4h4v18.2c0,1.5-0.2,2.9-0.7,4.3c-0.4,1.4-1.1,2.6-2.1,3.6c-0.9,1.1-2.1,1.9-3.5,2.5c-1.4,0.6-3.1,1-5,1
		c-1.9,0-3.6-0.3-5-1c-1.4-0.6-2.6-1.5-3.5-2.5c-0.9-1.1-1.6-2.3-2.1-3.6c-0.4-1.4-0.7-2.8-0.7-4.3V72.4z M252.7,145.9
		c10.4,0.4,20.6,2.7,28.9,9.7c5.1,4.3,8.4,9.8,9,16.6c0.3,3.4,0.8,6.8,0.7,10.2c-0.1,2.6-0.9,5.2-1.9,7.6
		c-2.4,6.3-6.2,11.4-12.1,14.9c-4.2,2.5-8.7,4.6-13.5,5.7c-1.9,0.4-3.7,1.2-5.6,1.5c-2.2,0.4-4.4,0.6-6.6,0.7
		c-6.4,0.3-12.7,0.5-19.1,0.8c-0.4,0-1.2,0.8-1.2,1.2c0.1,3.9,0.4,7.8,0.6,11.7c0.1,1.6,0.2,3.2,0.1,4.7c-0.2,3.9-0.6,7.8-0.7,11.7
		c-0.1,3.9-0.1,7.7,0,11.6c0,1.2-0.4,1.7-1.6,1.6c-5.6,0-11.3-0.1-16.9,0c-1.6,0-2-0.5-2-2.1c0-17.7,0-35.5,0-53.2
		c0-17.6,0-35.1,0-52.7c0-2,0.5-2.4,2.4-2.4C226.3,145.8,239.5,145.3,252.7,145.9z M195.8,72.4h9.9c1.1,0,2.2,0.1,3.3,0.4
		c1.1,0.2,2.1,0.7,2.9,1.2c0.9,0.6,1.5,1.3,2.1,2.2c0.5,0.9,0.8,2.1,0.8,3.5c0,1.6-0.5,2.9-1.4,4c-0.9,1.1-2.1,1.8-3.7,2.2V86
		c0.9,0.1,1.8,0.3,2.6,0.7c0.8,0.4,1.5,0.8,2,1.4c0.6,0.6,1,1.3,1.3,2.1c0.3,0.8,0.5,1.7,0.5,2.7c0,1.4-0.3,2.6-0.9,3.6
		c-0.6,1-1.3,1.9-2.3,2.5c-1,0.7-2.1,1.2-3.4,1.5c-1.3,0.3-2.7,0.5-4.2,0.5h-9.5V72.4z M150.9,72.4h5.3l15.1,23.1h0.1V72.4h4v28.8
		h-5.1L155,77.7h-0.1v23.6h-4V72.4z M122.9,72.4h22.5v3.5h-9.2v25.3h-4V75.9h-9.2V72.4z M87.7,72.4h6.3l8.6,22.2h0.1l8.5-22.2h6.3
		v28.8h-4V77.1h-0.1l-9.6,24.1h-2.6l-9.5-24.1h-0.1v24.1h-3.9V72.4z M73.2,298.6c0.6-1.5,1.5-3,2.5-4.2c4-5,8.6-9.3,15.2-10.7
		c5.2-1.1,10.4-1.3,15.8-0.5c7.2,1,12.6,4.8,16.9,10.2c1.9,2.4,2.9,5.5,4.2,8.3c0.6,1.4,1.4,2.9,1.5,4.5c0.5,7.1,0.7,14.3-1.9,21.1
		c-3.3,8.8-9.7,14.6-18.7,16.7c-6.6,1.6-13.4,2-20.1-0.7c-9-3.8-14.9-10.2-17.1-19.7c-0.7-3.2-0.8-6.5-1.1-9.7c0.2,0,0.3,0,0.5,0
		C70.1,308.5,71.2,303.5,73.2,298.6z M143.5,380.3c-3.1,4.9-6.2,9.7-9.3,14.5c-4.1,6.4-8.2,12.9-12.4,19.3c-2,3.1-4,6.1-5.9,9.3
		c-0.6,1-1,2.3-1,3.5c-0.1,9.3-0.1,18.6,0,28c0,1.5-0.4,2.1-2,2.1c-3.5-0.1-7-0.1-10.5,0c-1.6,0-2.1-0.4-2.1-2
		c0.1-7.9-0.2-15.7,0.1-23.6c0.2-4.4-1.4-7.9-3.6-11.4c-5.7-8.7-11.4-17.5-17-26.2c-2.9-4.4-5.7-8.8-8.6-13.2
		c-0.5-0.7-0.8-1.5-1.5-2.7c5.3,0,10.1-0.1,14.9,0.1c0.8,0,1.9,0.9,2.4,1.7c2.9,4.4,5.7,8.8,8.5,13.2c3.2,5.1,6.3,10.2,9.4,15.3
		c0.2,0.3,0.4,0.5,0.6,0.8c1.6,2.2,2.5,2.2,4-0.2c5.1-8.1,10.2-16.3,15.2-24.4c1-1.6,1.9-3.3,3-4.8c0.6-0.7,1.5-1.6,2.4-1.6
		c4.6-0.1,9.1-0.1,13.7,0c0.2,0,0.3,0.1,0.7,0.3C144.2,378.9,144,379.7,143.5,380.3z M145.2,228.7c-0.2-0.4-1.2-0.7-1.8-0.7
		c-9.7,0-19.4,0-29.1,0c-3.1,0-6.2,0-9.3,0c-1.4,0-2.2,0.5-2.7,1.8c-3.1,8-6.2,16-9.2,24c-0.6,1.7-1.6,2.4-3.4,2.3
		c-5.6-0.1-11.3,0-16.9,0c-2.1,0-2.3-0.2-1.5-2.2c1.7-4.7,3.6-9.4,5.4-14.1c1.7-4.3,3.5-8.5,5.2-12.8c4.5-11.3,9.1-22.6,13.6-33.8
		c2.8-7.1,5.6-14.2,8.4-21.3c3.3-8.1,6.6-16.2,9.8-24.3c0.5-1.3,1.4-1.8,2.8-1.8c4.8,0,9.6,0.1,14.5,0c1.7,0,2.8,0.8,3.4,2.2
		c3.3,8.3,6.6,16.7,9.9,25c4,9.9,8.1,19.7,12.1,29.6c2.2,5.4,4.3,10.8,6.4,16.2c3.9,9.8,7.9,19.6,11.8,29.5c1,2.5,1.9,5,3,8
		c-5.4,0-10.3,0-15.1,0c-6.4,0-6.5,0-8.9-5.8c-2.2-5.6-4.3-11.2-6.4-16.8C146.4,231.9,145.9,230.2,145.2,228.7z M162,319.9
		c0.1,7.4,0,14.8,0.1,22.2c0,1.8-0.6,2.3-2.3,2.2c-2.3-0.1-4.7-0.2-7,0c-1.8,0.1-2.2-0.6-2.2-2.3c0.1-9.4,0-18.9,0-28.3
		c0-9.6,0-19.1,0-28.7c0-1.3,0.4-1.6,1.7-1.6c11.8,0,23.7,0,35.5,0c1.2,0,1.7,0.4,1.6,1.7c-0.1,2.2-0.1,4.3,0,6.5
		c0.1,1.4-0.5,1.8-1.9,1.8c-7.8-0.1-15.6,0-23.4-0.1c-1.8,0-2.3,0.5-2.2,2.3c0.1,3.7,0.1,7.5,0,11.2c0,1.4,0.5,1.8,1.9,1.8
		c6.6-0.1,13.2,0,19.8-0.1c1.6,0,2.1,0.5,2,2.1c-0.1,1.8-0.1,3.6,0,5.3c0.1,1.5-0.5,1.8-1.9,1.8c-6.5-0.1-13.1,0-19.6-0.1
		C162.4,317.6,162,318.2,162,319.9z M221.4,446.9c-0.1,2.8-0.1,5.6,0,8.4c0,1.2-0.4,1.7-1.6,1.6c-16.7,0-33.3,0-50,0
		c-1.7,0-1.6-0.9-1.6-2c0-12.6,0-25.1,0-37.7c0-12.4,0-24.9,0-37.3c0-1.8,0.5-2.2,2.3-2.2c15.5,0.1,31.1,0.1,46.6,0
		c1.8,0,2.3,0.6,2.2,2.3c-0.1,2.5-0.2,5.1,0,7.6c0.2,2.1-0.7,2.4-2.5,2.4c-10.6-0.1-21.2,0-31.8-0.1c-1.8,0-2.4,0.4-2.4,2.3
		c0.1,5.4,0.1,10.9,0,16.4c0,1.5,0.4,2,1.9,2c9.1-0.1,18.1,0,27.2-0.1c1.7,0,2.2,0.5,2.1,2.1c-0.1,2.7-0.1,5.5,0,8.2
		c0.1,1.6-0.5,1.9-2,1.9c-8.9-0.1-17.9,0-26.8,0c-2.4,0-2.4,0-2.4,2.5c0,5.7,0.1,11.4,0,17.1c0,1.9,0.4,2.5,2.4,2.5
		c11.4-0.1,22.8,0,34.2-0.1C220.9,444.7,221.5,445.1,221.4,446.9z M226.3,293.4c-1.5,0-2-0.4-2-1.9c0.1-2.1,0.1-4.3,0-6.5
		c0-1.2,0.4-1.5,1.5-1.5c15.7,0,31.4,0,47.2,0c1.2,0,1.6,0.4,1.5,1.5c-0.1,2-0.1,3.9,0,5.9c0.1,1.4-0.4,1.7-1.8,1.7
		c-5.1-0.1-10.1,0-15.2-0.1c-1.7,0-2.1,0.5-2.1,2.2c0.1,7.9,0,15.8,0,23.8c0,7.9,0,15.8,0,23.8c0,1.7-0.5,2.2-2.2,2.1
		c-2.4-0.1-4.8-0.1-7.2,0c-1.7,0.1-2.1-0.6-2.1-2.2c0.1-7.2,0.1-14.4,0-21.7c0-1.2-0.4-2.5-0.5-3.7c-0.1-0.9-0.3-1.9-0.2-2.8
		c0.2-3.3,0.6-6.5,0.7-9.8c0.1-3,0-6,0.1-8.9c0-1.5-0.4-2-1.9-2C237,293.4,231.7,293.3,226.3,293.4z M315.5,456.9
		c-3.9,0-7.9,0-11.8,0c-1.1,0-1.5-0.4-1.9-1.5c-1.9-5.2-4.3-10.2-5.9-15.5c-0.9-2.9-2.4-2.8-4.6-2.8c-7.9,0.1-15.8,0.1-23.8,0
		c-2,0-3.1,0.7-3.8,2.6c-1.9,5.2-4,10.3-6.2,15.4c-0.3,0.8-1.4,1.6-2.2,1.7c-4.2,0.2-8.4,0.1-12.5,0.1c-0.2,0-0.5-0.1-1-0.3
		c0.4-1.3,0.7-2.7,1.2-3.9c4.8-11.9,9.6-23.8,14.4-35.8c3.1-7.6,6.1-15.2,9.2-22.8c1.9-4.9,3.8-9.7,5.9-14.5
		c0.3-0.8,1.4-1.7,2.2-1.7c3.9-0.2,7.8-0.1,11.7-0.1c2.8,7,5.5,13.9,8.3,20.8c4.3,10.7,8.6,21.4,12.9,32.1
		c2.2,5.4,4.3,10.8,6.4,16.2c1,2.6,2.1,5.1,3.1,7.7C317.8,456.5,317.5,456.9,315.5,456.9z M334.6,344.4c-1.7,0.1-2.2-0.4-2.2-2.1
		c0.1-7.5,0-15,0.1-22.4c0-1.7-0.4-2.2-2.1-2.2c-8,0.1-16.1,0.1-24.1,0c-1.5,0-2,0.4-2,1.9c0.1,7.6,0,15.2,0.1,22.8
		c0,1.5-0.4,2-1.9,1.9c-2.5-0.1-5.1-0.1-7.6,0c-1.5,0.1-1.9-0.5-1.9-1.9c0.1-8.9,0-17.9,0-26.8c0-10,0-20,0-30
		c0-1.8,0.6-2.3,2.3-2.2c2.3,0.1,4.7,0.2,7,0c1.8-0.1,2.2,0.6,2.2,2.3c-0.1,6.9,0,13.8-0.1,20.7c0,1.6,0.3,2.2,2.1,2.2
		c8-0.1,16-0.1,24,0c1.9,0,2.2-0.6,2.2-2.3c-0.1-7,0-13.9-0.1-20.9c0-1.4,0.3-2.1,1.9-2c2.5,0.1,4.9,0.1,7.4,0
		c1.7-0.1,2.2,0.4,2.2,2.1c-0.1,9.4,0,18.9,0,28.3s0,18.9,0,28.3c0,1.6-0.4,2.3-2.1,2.2C339.4,344.2,337,344.2,334.6,344.4z
		 M402,456.9c-4.1,0.1-8.2,0-12.4,0c-1.6,0-2.7-0.6-3.7-1.9c-5.8-8.2-11.7-16.2-17.3-24.5c-2-3-4.2-4.5-8.1-4.2
		c-4.6,0.4-4.6,0.1-4.6,4.6c0,8,0,16,0,24c0,1.5-0.4,2-1.9,2c-3.5-0.1-7.1-0.1-10.6,0c-1.5,0-1.9-0.4-1.9-1.9
		c0.1-12.5,0-25.1,0-37.6c0-12.5,0-25,0-37.5c0-1.7,0.4-2.2,2.1-2.1c7.4,0.1,14.7-0.1,22.1,0.1c4.2,0.1,8.4,1,12.5,1.3
		c4.1,0.3,7.9,1.4,11.2,3.6c6.1,4,10.6,9.1,10.7,17c0,4.5-0.2,9-2.7,12.9c-2.6,4.2-6.1,7.4-10.5,9.6c-1.1,0.5-2.2,0.9-3.3,1.4
		c-2.1,0.9-2.1,0.9-0.7,2.8c6.8,9.3,13.6,18.6,20.3,27.9c0.4,0.5,0.5,1.2,0.7,1.8C403.3,456.5,402.7,456.9,402,456.9z M408.6,336.6
		c-0.2,2-0.1,3.9,0,5.9c0.1,1.4-0.4,1.9-1.8,1.9c-6.3-0.1-12.5,0-18.8,0c-6.1,0-12.3,0-18.4,0c-1.6,0-2.1-0.4-2.1-2
		c0.1-8,0-16.1,0-24.1c0-10.9,0-21.8,0-32.6c0-1.7,0.5-2.1,2.1-2.1c11.8,0.1,23.6,0.1,35.4,0c1.7,0,2.2,0.5,2.1,2.1
		c-0.1,2-0.1,3.9,0,5.9c0.1,1.5-0.5,1.9-2,1.9c-8-0.1-16,0-24-0.1c-1.9,0-2.4,0.5-2.3,2.4c0.1,3.7,0.1,7.4,0,11c0,1.6,0.5,1.9,2,1.9
		c6.7-0.1,13.4,0,20.2-0.1c1.9,0,2.4,0.6,2.3,2.4c-0.1,1.5-0.2,3.1,0,4.6c0.2,1.9-0.6,2.3-2.4,2.3c-6.7-0.1-13.3,0-20-0.1
		c-1.5,0-2.1,0.4-2.1,2c0.1,4.2,0.1,8.4,0,12.5c0,1.7,0.4,2.3,2.2,2.3c8.4-0.1,16.9,0,25.3-0.1C408,334.4,408.7,334.8,408.6,336.6z
		 M410.6,184.1c-2.2,9.6-6.9,17.7-16.3,22c-3.8,1.7-7.8,2.9-11.6,4.5c-4.4,1.8-9,1.7-13.5,2.3c-5.6,0.8-11.1,0.4-16.7,0.6
		c-1.6,0-1.9,0.6-1.9,2c0,12.7,0,25.4,0,38c0,2.6,0,2.6-2.5,2.6c-5.2,0-10.4-0.1-15.6,0c-1.9,0-2.5-0.4-2.5-2.4
		c0.1-17.6,0-35.2,0-52.9c0-17.6,0-35.1,0-52.7c0-1.9,0.4-2.5,2.4-2.5c14.3,0.1,28.5,0,42.8,0.1c0.5,0,1.1-0.1,1.5,0.1
		c5.9,2,12.2,2.5,17.8,5.5c7.7,4.1,13.1,10.1,15.1,18.7C410.9,174.6,411.7,179.5,410.6,184.1z"
      />
      <path
        d="M386.6,169.1c-4.4-5.1-10.6-5.9-16.7-6.4c-5.8-0.5-11.6-0.1-17.5-0.1c-1.3,0-1.8,0.4-1.7,1.7c0.1,5,0,10,0,15
		c0,5,0,10,0,15c0,1.3,0.4,1.8,1.7,1.7c7-0.3,14.1,0.8,21-0.8c3.7-0.9,7.4-1.9,10.5-4.6c4.5-3.8,6.2-8.6,5.6-14.4
		C389.3,173.6,388.4,171.2,386.6,169.1z"
      />
    </g>
  </SvgAnimationWrapper>
)

mtn.propTypes = {
  className: PropTypes.string,
  entered: PropTypes.bool,
  width: PropTypes.number,
  delay: PropTypes.number,
}

export default mtn
