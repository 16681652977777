import React from 'react'
import PropTypes from 'prop-types'
import SvgAnimationWrapper from '../../../components/SvgAnimationWrapper'

const tnw = ({ className, entered, width, delay }) => (
  <SvgAnimationWrapper
    entered={entered}
    className={className}
    viewBox="0 0 456 110"
    width={width}
    strokeOffset={900}
    delay={delay}
    strokeWidth={3}
  >
    <g>
      <path
        d="M249,0c13.7,0,27.3,0,41,0c0,22.9,0,45.8,0,68.5c10.2,2,18.1-0.4,24.4-8.2c3.4-4.3,7.6-7.9,11.6-11.7
		c4.1-4,6-8.8,6-14.5c-0.1-11.3,0-22.6,0-34c13.7,0,27.3,0,41,0c0,15.6,0,31.3,0,46.9c0,1.8-0.1,3.7-0.7,5.3
		c-2.2,5.6-4.6,11.2-7,16.8c5.7,0,11.9,0.5,17.9-0.2c3.3-0.4,7-2,9.5-4.2c5.9-5.1,11.2-10.9,16.7-16.5c3.7-3.8,5.6-8.3,5.6-13.7
		c0-11.5,0-23,0-34.4c13.7,0,27.3,0,41,0c0,17,0,34,0,51c-1.9,9.7-7.6,16.9-14.8,23.4c-6.3,5.7-12.2,11.8-18,17.9
		c-7.4,7.9-15.1,15.3-26.2,17.6c-14.7,0-29.3,0-44,0c0-9.6,0-19.3,0-28.9c-0.7-0.3-1.3-0.7-2-1c-2,2.4-3.7,5.1-6,7.3
		c-6,5.9-11.8,12.1-18.5,17.2c-3.7,2.8-9,3.7-13.6,5.4c-21.3,0-42.7,0-64,0C249,73.3,249,36.7,249,0z"
      />
      <path
        d="M235,110c-13.7,0-27.3,0-41,0c0-10.8,0-21.6,0-32.4c0-6.7-1.9-12.5-7-17.1c-4.4-4-8.7-8.3-12.7-12.7
		c-6.1-6.7-13.8-7.3-22.3-6.4c0,23.1,0,45.9,0,68.7c-13.7,0-27.3,0-41,0c0-36.7,0-73.3,0-110c21.3,0,42.7,0,64,0
		c0.3,0.3,0.7,0.9,1,0.9c7.5,0.2,13.3,3.9,18.3,9c10.5,10.4,20.9,20.9,31.4,31.4c6.2,6.2,9.2,13.8,9.2,22.4
		C235.1,79.1,235,94.6,235,110z"
      />
      <path
        d="M0,41C0,27.3,0,13.7,0,0c32.3,0,64.7,0,97,0c0,13.6,0,27.1,0,41.2c-9.7,0-18.7,0-28,0c0,23.3,0,46,0,68.8
		c-13.7,0-27.3,0-41,0c0-22.9,0-45.8,0-69C18.2,41,9.1,41,0,41z"
      />
    </g>
  </SvgAnimationWrapper>
)

tnw.propTypes = {
  className: PropTypes.string,
  entered: PropTypes.bool,
  width: PropTypes.number,
  delay: PropTypes.number,
}

export default tnw
