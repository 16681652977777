import React from 'react'
import PropTypes from 'prop-types'
import SvgAnimationWrapper from '../../../components/SvgAnimationWrapper'

const brainfarm = ({ className, entered, width, delay }) => (
  <SvgAnimationWrapper
    entered={entered}
    className={className}
    width={width}
    viewBox="0 0 142.4 32.5"
    strokeOffset={170}
    delay={delay}
  >
    <g>
      <path
        d="M110.1,14.2h1.4c0.4,0,0.5,0,0.7-0.4c0.2-0.4,0.4-0.5,0.4-0.7V6.4c0-0.4,0-0.5-0.4-0.7
		c-0.2-0.2-0.5-0.4-0.7-0.4h-1.4V14.2 M118.4,30.9c0,0.4,0,0.5-0.5,0.5H114c-0.2,0-0.4,0-0.5-0.2s-0.4-0.4-0.4-0.5l-3.2-10.6v10.6
		v0.2c0,0.4-0.2,0.5-0.5,0.5h-3.9c-0.4,0-0.5-0.2-0.5-0.5V1.5c0-0.4,0.2-0.5,0.5-0.5h6.6c0.7,0,1.4,0.2,2.1,0.5
		c0.7,0.4,1.2,0.7,1.8,1.2c0.5,0.5,0.9,1.1,1.2,1.8c0.4,0.7,0.5,1.4,0.5,2.1v7.1c0,1.1-0.4,2-0.9,2.8c-0.5,0.9-1.2,1.4-2.1,2
		L118.4,30.9z M96.2,21.3L95.3,9.5H95l-0.9,11.9H96.2z M101.9,30.9c0,0.4-0.2,0.5-0.5,0.5h-3.9c-0.4,0-0.5-0.2-0.7-0.5l-0.4-4.8
		h-2.7l-0.4,4.8c0,0.4-0.4,0.5-0.7,0.5h-3.9c-0.4,0-0.5-0.2-0.5-0.5l3.4-29.4c0-0.4,0.4-0.5,0.7-0.5h5.9c0.4,0,0.7,0.2,0.7,0.5
		L101.9,30.9z M87.5,1.5v3.9c0,0.4-0.2,0.5-0.5,0.5h-5v6.2h4.4c0.4,0,0.5,0.2,0.5,0.5v3.9c0,0.4-0.2,0.5-0.5,0.5H82v13.7
		c0,0.4-0.2,0.5-0.5,0.5h-4.1c-0.4,0-0.5-0.2-0.5-0.5V1.5c0-0.4,0.2-0.5,0.5-0.5H87C87.4,0.8,87.5,0.9,87.5,1.5 M73.9,1.5v29.4
		c0,0.4-0.2,0.5-0.5,0.5h-2.8c-0.2,0-0.4,0-0.5-0.2s-0.4-0.2-0.4-0.4L65,16.5v14.5c0,0.4-0.2,0.5-0.5,0.5h-3.9
		c-0.4,0-0.5-0.2-0.5-0.5V1.5c0-0.4,0.2-0.5,0.5-0.5h2.8c0.2,0,0.4,0,0.5,0.2c0.2,0.2,0.4,0.2,0.4,0.4l4.6,14v-14
		c0-0.4,0.2-0.5,0.5-0.5h3.9C73.5,0.8,73.9,0.9,73.9,1.5 M56.8,30.9c0,0.4-0.2,0.5-0.5,0.5h-3.9c-0.4,0-0.5-0.2-0.5-0.5V1.5
		c0-0.4,0.2-0.5,0.5-0.5h3.9c0.4,0,0.5,0.2,0.5,0.5V30.9z M42.8,21.3L41.9,9.5h-0.4l-0.9,11.9H42.8z M48.7,30.9
		c0,0.4-0.2,0.5-0.5,0.5h-3.9c-0.4,0-0.5-0.2-0.7-0.5l-0.4-4.8h-2.7l-0.4,4.8c0,0.4-0.2,0.5-0.7,0.5h-3.9c-0.4,0-0.5-0.2-0.5-0.5
		l3.4-29.4c0-0.4,0.4-0.5,0.7-0.5H45c0.4,0,0.7,0.2,0.7,0.5L48.7,30.9z M24.7,14.4h1.4c0.4,0,0.5,0,0.7-0.4c0.2-0.4,0.4-0.5,0.4-0.7
		V6.6c0-0.4,0-0.5-0.4-0.7c-0.2-0.2-0.5-0.4-0.7-0.4h-1.4V14.4z M33.1,30.9c0,0.4,0,0.5-0.5,0.5h-3.9c-0.2,0-0.4,0-0.5-0.2
		c-0.2-0.2-0.4-0.4-0.4-0.5l-3.2-10.6v10.6v0.4c0,0.4-0.2,0.5-0.5,0.5h-3.9c-0.4,0-0.5-0.2-0.5-0.5V1.5c0-0.4,0.2-0.5,0.5-0.5h6.6
		c0.7,0,1.4,0.2,2.1,0.5c0.7,0.4,1.2,0.7,1.8,1.2c0.5,0.5,0.9,1.1,1.2,1.8s0.5,1.4,0.5,2.1v7.1c0,1.1-0.4,2-0.9,2.8
		c-0.5,0.9-1.2,1.4-2.1,2L33.1,30.9z M11.4,13.2V6.4c0-0.4,0-0.5-0.4-0.7c-0.4-0.2-0.5-0.4-0.7-0.4H9v8.7h1.4c0.4,0,0.5,0,0.7-0.4
		C11.4,13.9,11.4,13.5,11.4,13.2 M11.4,25.8v-4.1c0-0.4,0-0.5-0.4-0.7c-0.2-0.2-0.5-0.4-0.7-0.4H9v6.2h1.4c0.4,0,0.5,0,0.7-0.4
		C11.4,26.3,11.4,26.1,11.4,25.8 M15.3,17.4c-0.4,0.4-0.9,0.7-1.6,0.9c0.5,0.4,1.1,0.7,1.6,1.1c0.9,0.9,1.2,2,1.2,3.4v3.2
		c0,0.7-0.2,1.4-0.5,2.1c-0.4,0.7-0.7,1.2-1.2,1.8c-0.5,0.5-1.1,0.9-1.8,1.2c-0.7,0.4-1.4,0.5-2.1,0.5H4.3c-0.4,0-0.5-0.2-0.5-0.5
		V1.5c0-0.4,0.2-0.5,0.5-0.5h6.6c0.7,0,1.4,0.2,2.1,0.5c0.7,0.4,1.2,0.7,1.8,1.2c0.5,0.5,0.9,1.1,1.2,1.8c0.4,0.7,0.5,1.4,0.5,2.1
		V13c0,0.7,0,2.5-0.4,3C16.1,16.5,15.9,16.9,15.3,17.4 M138.6,1.5v29.4c0,0.4-0.2,0.5-0.5,0.5h-4.3c-0.4,0-0.5-0.2-0.5-0.5V17.1
		l-2.8,11.4c0,0.2-0.2,0.2-0.2,0.2s-0.2,0-0.2-0.2l-2.8-11.4v13.8c0,0.4-0.2,0.5-0.5,0.5h-4.3c-0.4,0-0.5-0.2-0.5-0.5V1.5
		c0-0.4,0.2-0.5,0.5-0.5h3.7c0.2,0,0.4,0,0.5,0.2s0.2,0.4,0.4,0.5l3.4,15.6l3.4-15.6c0-0.2,0.2-0.4,0.4-0.5c0.2-0.2,0.4-0.2,0.5-0.2
		h3.7C138.4,0.8,138.6,0.9,138.6,1.5"
      />
    </g>
  </SvgAnimationWrapper>
)

brainfarm.propTypes = {
  className: PropTypes.string,
  entered: PropTypes.bool,
  width: PropTypes.number,
  delay: PropTypes.number,
}

export default brainfarm
