import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import {
  svgAnimationInKeyframes,
  svgAnimationOutKeyframes,
} from './StyledMixins'

const Svg = styled.svg`
  stroke-dasharray: ${props => props.strokeOffset};
  stroke-dashoffset: ${props => props.strokeOffset};
  stroke: ${props => props.colorHex};
  stroke-width: ${props => props.strokeWidth};
  fill: transparent;
  ${props =>
    props.entered
      ? css`
          animation: 3s
            ${svgAnimationInKeyframes(
              props.strokeOffset,
              props.strokeWidth,
              props.colorHex
            )}
            forwards;
          animation-delay: ${props.delay}ms;
        `
      : css`
          animation: 1s
            ${svgAnimationOutKeyframes(
              props.strokeOffset,
              props.strokeWidth,
              props.colorHex
            )}
            forwards;
        `};
`

const SvgAnimationWrapper = ({
  className,
  entered,
  width,
  children,
  viewBox,
  strokeOffset,
  delay,
  strokeWidth,
  style,
  color,
}) => (
  <Svg
    entered={entered}
    className={className}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox={viewBox}
    style={{ enableBackground: `new ${viewBox}`, ...style }}
    xmlSpace="preserve"
    width={width}
    strokeOffset={strokeOffset}
    delay={delay}
    strokeWidth={strokeWidth}
    colorHex={color}
  >
    {children}
  </Svg>
)

SvgAnimationWrapper.propTypes = {
  className: PropTypes.string,
  entered: PropTypes.bool,
  width: PropTypes.number,
  children: PropTypes.any,
  viewBox: PropTypes.string,
  strokeOffset: PropTypes.number,
  delay: PropTypes.number,
  strokeWidth: PropTypes.number,
  style: PropTypes.object,
  color: PropTypes.string,
}

SvgAnimationWrapper.defaultProps = {
  delay: 0,
  strokeWidth: 1,
  color: '#1d1d1d',
}

export default SvgAnimationWrapper
