import React from 'react'
import styled, { keyframes, css } from 'styled-components'
import PropTypes from 'prop-types'
import SectionIndicatorBase from './SectionIndicator'
import { screenSizes, gutter } from '../theme'
import ArrowBase from './Arrow'
import WithScrollAnimation from './WithScrollAnimation'

const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding: 60px ${gutter.mobile}px;
  margin-bottom: 100px;

  @media (min-width: ${screenSizes.tablet}px) {
    padding: 60px ${gutter.tablet}px;
  }

  @media (min-width: ${screenSizes.desktop}px) {
    flex-direction: row;
    padding: 60px ${gutter.desktop}px;
  }

  @media (min-width: ${screenSizes.desktopLarge}px) {
    padding: 60px ${gutter.desktopLarge}px;
  }
`

const ImageList = styled.div`
  flex: 1;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 30px;
  grid-column-gap: 30px;

  img {
    max-width: 100%;
  }

  svg {
    max-width: 100%;
  }

  @media (min-width: ${screenSizes.desktop}px) {
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 60px;
  }
`

const ImageBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &:hover {
    img {
      opacity: 1;
    }
  }
`

const ImageBlockHover = styled.img`
  pointer-events: none;
  z-index: 10;
  opacity: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  max-width: 100vw !important;
  transition: all ease 500ms;

  @media (min-width: ${screenSizes.tablet}px) {
    top: 0;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
  }
`

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: 0;
  transform: translate(100px, 0%);
  transition: all ease 1000ms;
  transition-delay: 750ms;
  width: 300px;

  ${props =>
    props.entered &&
    `
    transition: all ease 500ms;
    transition-delay: 0ms;
    opacity: 1;
    transform: translate(0%, 0%);
  `}

  @media (min-width: ${screenSizes.desktop}px) {
    align-items: flex-end;
    margin-left: 100px;
  }
`

const Description = styled.div`
  max-width: 100%;
  margin-bottom: 30px;

  @media (min-width: ${screenSizes.desktop}px) {
    text-align: right;
    margin-bottom: 0;
  }
`

const DescriptionText = styled.p`
  font-size: 11pt;

  @media (min-width: ${screenSizes.desktop}px) {
    font-size: 9pt;
    line-height: 16pt;
  }
`

const Arrow = styled(ArrowBase)`
  display: none;

  @media (min-width: ${screenSizes.desktop}px) {
    display: block;
  }
`

const ImageInfoAnimateIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const ImageInfoAnimateOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity:0;
  }
`

const ImageInfo = styled.div`
  text-align: center;
  margin-top: 15px;
  opacity: 0;

  p {
    margin-bottom: 0;
    line-height: 16pt;
    text-transform: uppercase;
  }

  ${props =>
    props.entered
      ? css`
          animation: 3s ${ImageInfoAnimateIn} forwards;
          animation-delay: ${props.delay}ms;
        `
      : css`
          animation: 1s ${ImageInfoAnimateOut} forwards;
        `};
`

const ImageHeading = styled.h3`
  margin-bottom: 0;
  font-size: 20pt;
  opacity: 0;

  ${props =>
    props.entered
      ? css`
          animation: 3s ${ImageInfoAnimateIn} forwards;
          animation-delay: ${props.delay}ms;
        `
      : css`
          animation: 1s ${ImageInfoAnimateOut} forwards;
        `};

  @media (min-width: ${screenSizes.laptop}px) {
    font-size: 30pt;
  }
`

const SubHeading = styled.span`
  text-transform: uppercase;
  opacity: 0;
  transform: translate(100px, 0px);
  transition: all ease 500ms;
  transition-delay: 250ms;
  width: 170px;
  display: none;
  @media (min-width: ${screenSizes.desktop}px) {
    display: inline-block;
    text-align: right;

    ${props =>
      props.entered &&
      `
      transition-delay: 0;
      opacity: 1;
      transform: translate(0px, 0px);
    `}
  }
`

const SectionIndicator = styled(SectionIndicatorBase)`
  @media (min-width: ${screenSizes.desktop}px) {
    margin-left: 40px;
  }
  line-height: 20px;
`

class ImageListSection extends React.PureComponent {
  static propTypes = {
    entered: PropTypes.bool,
    imageList: PropTypes.array,
    title: PropTypes.string,
    description: PropTypes.string,
    className: PropTypes.string,
    subHeading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }

  constructor(props) {
    super(props)

    this.container = null
    this.onArrowClick = this.onArrowClick.bind(this)
    this.renderImageBlock = this.renderImageBlock.bind(this)
  }

  onArrowClick() {
    window.scrollTo({
      top:
        window.pageYOffset +
        this.container.getBoundingClientRect().y +
        this.container.clientHeight,
      behavior: 'smooth',
    })
  }

  renderImageBlock() {
    const { imageList, entered } = this.props

    return (
      imageList &&
      imageList.map((image, index) => {
        if (typeof image.src === 'string')
          return (
            <ImageBlock key={index}>
              {image.hoverImage && <ImageBlockHover src={image.hoverImage} />}
              <img {...image.extraProps} src={image.src} alt="Teamgeek" />
              <ImageInfo entered={entered} delay={300 * index + 2000}>
                {this.renderImageInfo(image.extraInfo)}
              </ImageInfo>
            </ImageBlock>
          )
        else
          return (
            <ImageBlock key={index}>
              {image.hoverImage && <ImageBlockHover src={image.hoverImage} />}
              {image.heading && (
                <ImageHeading delay={150 * index} entered={entered}>
                  {image.heading}
                </ImageHeading>
              )}
              {image.src && (
                <image.src
                  {...image.extraProps}
                  delay={150 * index}
                  entered={entered}
                />
              )}
              <ImageInfo entered={entered} delay={300 * index + 2000}>
                {this.renderImageInfo(image.extraInfo)}
              </ImageInfo>
            </ImageBlock>
          )
      })
    )
  }

  renderImageInfo(imageInfo) {
    return (
      imageInfo &&
      imageInfo.map((info, index) => {
        return <p key={index}>{info}</p>
      })
    )
  }

  render() {
    const { title, description, className, entered, subHeading } = this.props
    return (
      <Container
        className={className}
        ref={container => (this.container = container)}
      >
        <ImageList>{this.renderImageBlock()}</ImageList>

        <DescriptionContainer entered={entered}>
          <Description>
            <p style={{ lineHeight: '7pt' }}>
              {subHeading && (
                <SubHeading entered={entered}>{subHeading}</SubHeading>
              )}
              <SectionIndicator>{title}</SectionIndicator>
            </p>

            <DescriptionText>{description}</DescriptionText>
          </Description>

          <Arrow onClick={this.onArrowClick} />
        </DescriptionContainer>
      </Container>
    )
  }
}

export default WithScrollAnimation(ImageListSection, { enteredOffset: 0.1 })
