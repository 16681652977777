import React from 'react'
import PropTypes from 'prop-types'
import SvgAnimationWrapper from '../../../components/SvgAnimationWrapper'

const behance = ({ className, entered, width, delay }) => (
  <SvgAnimationWrapper
    entered={entered}
    className={className}
    viewBox="0 0 2000 371"
    width={width}
    strokeOffset={1650}
    delay={delay}
    strokeWidth={4}
  >
    <g>
      <path
        d="M188,0c11.4,2,22.9,3.8,34.3,6c13.3,2.6,25.3,8,36.4,15.6c10.5,7.2,17.7,17.1,22.1,28.9
		c7.1,19,8.5,38.7,4.7,58.5c-4.8,24.8-21.2,40.8-42.1,52.9c-1.4,0.8-2.9,1.6-4.8,2.7c1.1,0.6,1.9,1.2,2.7,1.4
		c44.7,14,61.9,51.4,62.9,87.8c0.8,31.3-8.7,58.5-33.8,78.6c-15,12.1-32.7,19-51.4,23.3c-16.5,3.8-33.1,5.5-50,5.5
		c-54.1,0-108.3,0.2-162.4,0.4c-2.2,0-4.3,0.2-6.5,0.2C0,241.3,0,120.7,0,0C62.7,0,125.3,0,188,0z M80.3,300.9
		c1.2,0.1,1.9,0.3,2.5,0.3c28.3,0.1,56.6,0.3,84.9,0c12.5-0.1,25-1.7,35.9-8.7c18.5-12,22.4-30,19.9-50.3
		c-2.2-17.7-10.7-31.1-28.2-37.1c-6.1-2.1-12.6-3.9-18.9-4.1c-31.3-0.6-62.6-0.6-93.9-0.7c-0.7,0-1.5,0.4-2.2,0.6
		C80.3,234.3,80.3,267.4,80.3,300.9z M80.3,147c2.4,0,4.2,0,6,0c24,0,48,0,72-0.1c4.6,0,9.3-0.3,13.9-0.9
		c19.9-2.5,33.2-12.8,36.1-29.7c1.4-8.3,1.1-17.3,0-25.7c-1.5-11-8.6-18.4-18.6-23.1c-8.8-4.2-18-5.6-27.6-5.6
		c-25.7,0.1-51.3,0-77,0c-1.6,0-3.2,0.2-4.9,0.3C80.3,90.5,80.3,118.4,80.3,147z"
      />
      <path
        d="M693,0c-0.1,16.3-0.2,32.7-0.2,49c0,27.2,0,54.3,0,81.5c0,1.7,0.2,3.5,0.3,6.1c1.2-1.3,1.8-1.8,2.2-2.4
		c11.4-19.3,28.9-30.4,49.8-36.8c23.5-7.2,46.9-5.8,69.7,2.2c27.7,9.7,42.2,31,47,59c2.4,14,3.7,28.5,3.8,42.7
		c0.4,52.5,0.2,105,0.2,157.5c0,1.1-0.1,2.3-0.2,3.8c-23.9,0-47.6,0-72,0c0-1.9,0-3.9,0-5.8c0-46.3,0-92.7,0-139
		c0-13.9-0.8-27.6-5.6-40.9c-6.1-16.8-17.5-25.5-35.2-25.9c-7.1-0.2-14.4,0.1-21.3,1.5c-20.6,4.1-31.8,18-35.3,38
		c-2.1,12.2-3.1,24.8-3.2,37.2c-0.4,42.8-0.1,85.7-0.1,128.5c0,2,0,4,0,6.4c-24,0-47.7,0-71.9,0c-0.1-1.6-0.2-3.4-0.2-5.1
		c0-117.5,0-235,0-352.5c0-1.7,0.2-3.3,0.4-5C645,0,669,0,693,0z"
      />
      <path
        d="M1863,371c-8.3-1.3-16.8-2.3-25-4.1c-40.8-8.9-71.3-31.8-87.1-70.6c-18.5-45.4-18.8-91.5,4.3-135.8
		c20.9-39.9,55.2-61.2,99.5-66.5c21.9-2.6,43.7-1.6,64.4,6.8c33.7,13.6,55.4,38.9,68.8,71.9c5.9,14.6,10,29.6,11.2,45.4
		c0.1,1.3,0.6,2.6,0.8,3.9c0,9,0,18,0,27c-2.1,0.3-4.2,0.9-6.2,0.9c-59.5,0.1-119,0-178.4,0c-1.9,0-3.9,0-6.5,0
		c0.8,6.2,1.1,11.7,2.3,17c4.6,19.9,13.4,36.7,34.1,44c14.1,5,28.6,6.3,43.3,3.6c19.1-3.5,34-13,43.2-30.6c0.6-1.2,3.1-2.1,4.7-2.1
		c18.3-0.1,36.7-0.1,55-0.1c1.1,0,2.2,0.2,3.7,0.4c-1.2,3.3-2.2,6.3-3.4,9.2c-15.1,38.9-41.3,65.5-82.9,74.9c-8.9,2-18,3.1-27,4.6
		C1875.7,371,1869.3,371,1863,371z M1929.6,203.9c-8.4-42-27.4-58.5-66-55.3c-33,2.8-51.7,26.8-51.2,55.3
		C1851.4,203.9,1890.3,203.9,1929.6,203.9z"
      />
      <path
        d="M455,371c-8.3-1.3-16.8-2.3-25-4.1c-41-9-71.5-32.1-87.2-71.1c-18-44.9-18.5-90.6,4.2-134.4
		c23.1-44.5,61.7-65.6,110.9-68.3c32.5-1.8,62.2,6.1,87.4,27.5c20.3,17.2,33.3,39.6,40.3,64.8c3.9,14.1,3.9,29.2,5.2,43.9
		c0.6,6.2,0.1,12.6,0.1,19.4c-64,0-127.1,0-191.3,0c1,7.2,1.5,13.8,3,20.2c7.2,30.7,25.2,45.4,57.3,46.9
		c19.6,0.9,37.9-3.1,52.3-17.7c3.9-4,6.7-9,10.1-13.5c0.9-1.1,2.3-2.6,3.5-2.6c20.3-0.2,40.6-0.1,61.4-0.1c-0.5,1.7-0.6,3-1.1,4.2
		c-6.2,12.3-11.3,25.3-18.8,36.7c-18.5,28.2-46.6,41.1-79,46.3c-4.7,0.8-9.5,1.3-14.3,2C467.7,371,461.3,371,455,371z M401.9,203.8
		c39.2,0,78.2,0,117.2,0c-4.6-39.2-27.3-56.6-61.1-55.6C428.9,149,401.3,169.1,401.9,203.8z"
      />
      <path
        d="M978.8,179.8c-23.8,0-47.5,0-71.5,0c-0.5-9.4,1.1-18.4,4.4-26.9c9.5-24.2,27.8-39.4,51.3-48.8
		c25.1-10.1,51.5-11.9,78.1-10.5c15.7,0.8,31.6,2.5,47,5.6c16.5,3.4,31,11.5,42.7,24.4c8.7,9.6,12.8,20.9,14.2,33.3
		c0.6,5.3,0.7,10.6,0.7,16c0.1,46,0,92,0.3,138c0.1,9.5,0.9,19,2.5,28.3c1.4,8,4.6,15.8,7.1,23.8c-0.2,0-1.1,0.2-2,0.2
		c-22,0-44-0.1-66,0.1c-3.2,0-5.1-1.1-5.9-4c-1.8-6.5-3.4-13-5.2-19.8c-9.3,8.8-19.9,16.3-32.2,20.9c-31.8,11.8-64.3,13.8-96.3,2
		c-35.5-13.1-50.1-39.7-48.5-78.6c0.8-19.1,7.9-35.2,21.3-48.6c10.2-10.2,22.8-16.4,36.7-19c23.5-4.4,47.3-7.6,71-11.2
		c11.2-1.7,22.4-2.9,32.7-8.2c9-4.6,12.8-11.5,12.5-21.3c-0.6-20-10.6-31.4-32.5-34c-10.4-1.2-21.4-0.8-31.8,1.1
		c-17.2,3.2-26,15.5-29.4,32.1c-0.3,1.3-0.5,2.6-0.7,3.9C979.3,178.9,979.1,179.2,978.8,179.8z M1073.7,234.3
		c-12.7,8.9-25.8,10.6-39,12.7c-12.3,2-24.6,4.2-36.7,7c-18.4,4.4-28.4,18.7-27,37.5c0.9,12.3,9.2,22.9,21,26.3
		c16.3,4.8,32.7,4.5,48.7-0.7c17.8-5.7,28.2-19.1,30.7-37.1C1073.5,265.5,1073,250.6,1073.7,234.3z"
      />
      <path
        d="M1183.4,100c23.5,0,46.8,0,70.7,0c0,12,0,23.9,0,36.8c1.5-1.2,2.2-1.6,2.6-2.1c13.5-22.9,34.9-34.3,59.9-39.2
		c23-4.6,45.7-2.6,67.6,6.4c12.9,5.4,22.7,14.1,30.2,25.9c12,19,16.3,40,16.5,61.9c0.3,56.8,0.2,113.7,0.2,170.5
		c0,0.8-0.1,1.6-0.2,2.6c-23.9,0-47.5,0-71.9,0c0-1.8,0-3.8,0-5.7c0-46.8,0-93.7,0-140.5c0-12.7-0.8-25.3-4.9-37.5
		c-6.5-19.7-18.6-29-39.3-29.1c-8.2,0-16.8,0.9-24.5,3.5c-19,6.5-29.1,21.1-32.2,40.4c-1.8,11.6-2.9,23.5-2.9,35.3
		c-0.3,43-0.1,86-0.1,129c0,1.9,0,3.9,0,6.1c-24.2,0-47.8,0-71.7,0C1183.4,276.3,1183.4,188.5,1183.4,100z"
      />
      <path
        d="M1714.4,193.5c-23.2,0-46.4,0-70.1,0c-0.5-3.1-0.9-6.2-1.6-9.2c-4.8-23-20.1-35.6-43.6-37.4
		c-34.8-2.7-52.9,20-61.1,42.1c-7.6,20.5-7.8,41.7-5.5,63.1c1.7,15.4,6.4,29.4,15.8,41.9c15.3,20.4,35.9,25.3,59.6,19.9
		c22.5-5.2,35.2-20.3,40.1-42.4c0.6-2.9,1-5.9,1.5-9.1c22.9,0,45.8,0,69.3,0c-5.7,38.6-21.3,70.7-56.5,90.5
		c-25.5,14.4-53.3,18.4-82.1,16.1c-34.7-2.8-64.9-15.7-88.5-41.9c-19.8-21.9-30-48-32.2-77.4c-2.7-35.9,3.9-69.7,23.4-100.4
		c18.8-29.6,46.1-47,80.4-53.4c37-6.9,72.3-2,105.1,17c30.3,17.6,43.6,45.5,46.5,79.3C1714.8,192.6,1714.6,192.9,1714.4,193.5z"
      />
      <path
        d="M386.2,25.3c49,0,97.5,0,146.3,0c0,11.7,0,23.1,0,34.7c-48.9,0-97.4,0-146.3,0C386.2,48.4,386.2,37,386.2,25.3
		z"
      />
    </g>
  </SvgAnimationWrapper>
)

behance.propTypes = {
  className: PropTypes.string,
  entered: PropTypes.bool,
  width: PropTypes.number,
  delay: PropTypes.number,
}

export default behance
