import React from 'react'
import PropTypes from 'prop-types'
import SvgAnimationWrapper from '../../../components/SvgAnimationWrapper'

const accenture = ({ className, entered, width, delay, style }) => (
  <SvgAnimationWrapper
    style={style}
    entered={entered}
    className={className}
    viewBox="0 0 1013.3 273"
    width={width}
    strokeOffset={610}
    delay={delay}
    strokeWidth={5}
  >
    <g>
      <path
        d="M106.5,266.8c-10.6,0-20.8,0-31.4,0c0-4.2,0-8.3,0-12.3c-0.3-0.2-0.6-0.4-1-0.6c-0.3,0.5-0.4,1.2-0.8,1.6
        c-9.2,10.6-21,14.5-34.8,13.2c-6.8-0.7-13.4-2.2-19.3-5.8c-9.2-5.7-13.7-14.2-14.2-24.7c-0.2-5.1,0.1-10.3,1.3-15.2
        c3.1-12.6,12.3-19.5,24-23.4c9.7-3.2,19.7-3.9,29.8-4c4.4,0,8.8,0,13.4,0c0.2-5,0.5-9.7-1.3-14.3c-1.7-4.6-4.9-7.7-9.8-8.9
        c-4.2-1-8.4-1.4-12.6,0.1c-6.4,2.3-10,6.9-10.8,13.8c-10.2,0-20.3,0-30.6,0c1.1-12.9,6.3-23.1,17.3-30c7-4.4,14.7-6.6,22.8-7.4
        c11.2-1.1,22.4-0.8,33.1,3.2c15,5.7,22.6,16.9,24.2,32.5c0.3,3.4,0.4,6.8,0.4,10.1c0,22.9,0,45.9,0,68.8
        C106.5,264.4,106.5,265.4,106.5,266.8z M72.5,217c-7.3,0-14.4-0.2-21.5,0.1c-2.9,0.1-5.8,1.2-8.4,2.4c-5.5,2.6-7.7,7.7-6.9,14.9
        c0.6,5.6,4.2,9.6,10,10.9c5.3,1.2,10.5,0.9,15.6-1.1c6.4-2.5,10.6-7,11.1-14C72.8,225.8,72.5,221.4,72.5,217z M230.1,191.6c-10.3,0-20.4,0-30.7,0c-0.4-1.3-0.7-2.7-1.1-4c-2.3-7.6-6.8-13-15.1-14
        c-8.4-1.1-15.4,1.5-20.4,8.7c-3.3,4.8-4.8,10.2-5.4,15.9c-1,10-1.2,20,1.4,29.8c3.6,13.4,15.2,20,27.8,15.8
        c7.9-2.6,12.1-8.8,14.2-16.4c0.8-2.9,1.9-3.6,4.7-3.5c7.6,0.2,15.1,0.1,22.7,0.1c0.9,0,1.9,0,3.1,0c-0.8,8.3-3,15.8-7.2,22.6
        c-8.3,13.4-20.8,19.9-36.1,21.8c-8.7,1.1-17.4,0.6-25.8-1.7c-16.2-4.5-27.7-14.4-33.3-30.2c-6.9-19.6-6.9-39.3,1.8-58.4
        c8.4-18.4,23.6-27.7,43.4-29.5c11.7-1.1,23.1,0.5,33.5,6.3C222,162.7,228.5,175.5,230.1,191.6z M349.8,191.7c-10.2,0-20.3,0-30.4,0c-0.4-1.4-0.7-2.7-1.1-4.1c-2.3-7.6-6.8-13-15-14
        c-8.4-1.1-15.5,1.5-20.4,8.7c-3.4,4.9-5,10.5-5.5,16.4c-0.9,10.1-1.2,20.3,1.8,30.2c2.4,8.2,7.3,14,16.2,15.4
        c13.1,2.1,23.1-4.8,25.9-18.1c0.4-1.8,1-2.4,2.8-2.4c8.1,0.1,16.2,0,24.2,0c0.7,0,1.3,0.1,2.5,0.1c-0.5,3.2-0.8,6.2-1.6,9.1
        c-5,19.7-18,30.9-37.6,34.6c-9.9,1.9-19.8,1.5-29.4-1.2c-16.8-4.6-28.5-15.1-33.9-31.7c-6.2-19.1-6.2-38.4,2.3-56.9
        c8.4-18.4,23.6-27.7,43.4-29.5c9.4-0.9,18.6,0.1,27.4,3.5c16.3,6.3,25.3,18.4,28.1,35.5c0.2,1.2,0.3,2.4,0.5,3.6
        C350,191.1,349.9,191.3,349.8,191.7z M473,216.5c-25.6,0-50.4,0-75.6,0c0.8,8.7,1.8,16.9,8.1,23.2c1.4,1.4,3.1,2.6,4.9,3.4
        c6.1,2.7,12.5,3.3,18.8,1.5c6.1-1.7,10.3-5.9,12.1-12.2c0.2-0.7,1.1-1.7,1.7-1.7c9.5-0.1,18.9-0.1,28.8-0.1c-0.4,2-0.6,3.8-1.1,5.5
        c-4.2,14.8-13.9,24.6-28.3,29.6c-15.3,5.3-30.7,5.2-45.9-0.4c-15.1-5.5-24.7-16.5-29.1-31.8c-4.7-15.9-4.7-32.1,0.2-48
        c7.4-24.2,28.2-38.4,53.9-37.4c8.7,0.3,17.1,1.8,24.9,5.8c14.9,7.5,22.7,20.1,25,36.1C472.6,198.6,472.5,207.4,473,216.5z
        M441.3,194.8c-0.4-2.9-0.6-5.5-1.2-8.1c-2.1-9.7-8-15.2-17.5-16.3c-9-1-17.1,3.4-20.9,11.8c-1.8,3.9-2.7,8.1-4.1,12.5
        C412.5,194.8,426.7,194.8,441.3,194.8z M493,150.7c10.7,0,21.1,0,31.7,0c0,5.4,0,10.7,0,16.7c0.8-1,1.2-1.5,1.6-2c7.9-11.9,19.2-17.4,33.3-17.2
    c3.4,0,6.8,0.3,10.1,1.1c13.5,3,22.1,12.9,24.3,27.9c0.6,3.8,1,7.6,1,11.4c0.1,25.2,0,50.4,0,75.6c0,0.6,0,1.2,0,2
        c-10.9,0-21.6,0-32.7,0c0-0.9,0-1.9,0-3c0-22.8,0.1-45.7-0.1-68.5c0-3.8-0.6-7.6-1.6-11.3c-1.3-4.6-4.7-7.3-9.3-8.6
        c-14.1-3.9-26.6,5.5-26.6,20.1c0,22.7,0,45.3,0,68c0,1,0,2.1,0,3.3c-10.7,0-21.1,0-31.8,0C493,227.7,493,189.3,493,150.7z M697.2,150.7c11,0,21.6,0,32.5,0c0,1.1,0,2.1,0,3.1c0,23.2-0.1,46.4,0.1,69.6c0,3.8,0.5,7.6,1.5,11.3
        c1.2,4.9,4.7,7.7,9.6,8.9c13.9,3.4,25.8-5.9,25.9-20.2c0.1-23.5,0.4-47.1,0.6-70.6c0-0.6,0.1-1.2,0.1-2c10.8,0,21.5,0,32.4,0
        c0,38.4,0,76.8,0,115.4c-10.8,0-21.5,0-32.4,0c0-5.4,0-10.6,0-16.6c-0.7,0.8-0.9,1.1-1.1,1.4c-8.6,13.7-21.3,19.2-37.1,17.5
        c-19.4-2.1-31.1-15-31.8-35.2c-0.4-13.5-0.2-26.9-0.2-40.4c0-13,0-26.1,0-39.1C697.2,152.9,697.2,151.9,697.2,150.7z M622.7,174.5c-4.7,0-9,0-13.4,0c0-8,0-15.8,0-23.9c4.6,0,9.1,0,14,0c0-5.4,0-10.5,0-15.6c0-6,0-6,5.7-8.4
        c7.8-3.3,15.7-6.6,23.5-9.9c0.8-0.3,1.6-0.6,2.7-1c0,11.7,0,23.1,0,34.8c7.5,0,14.8,0,22.1,0c0,8.1,0,15.9,0,24c-7.2,0-14.5,0-22,0
        c0,1.3,0,2.2,0,3.1c0,16.8,0,33.5,0,50.3c0,11.6,5.1,15.9,16.6,14c2-0.3,3.9-0.8,6.2-1.2c0,4.2,0,8.2,0,12.3
        c0,3.6,0.1,7.3-0.1,10.9c0,0.8-0.8,2-1.4,2.2c-12,2.8-23.9,3.4-35.6-1c-10.9-4-16.1-12.7-17.6-23.8c-0.4-3.3-0.6-6.8-0.6-10.1
        c-0.1-17.8,0-35.6,0-53.4C622.7,176.8,622.7,175.9,622.7,174.5z M823.5,150c9.9,0,19.5,0,29.1,0c0.8,0,1.5,0,2.5,0c0,6.8,0,13.5,0,20.1c7.2-14.4,19-21.2,35-22
        c0,10.7,0,21.3,0,31.8c-4.5,0.5-8.8,0.8-13.2,1.5c-2.4,0.4-4.7,1.1-7,1.9c-9.7,3.7-14.6,10.9-14.6,22c-0.1,19,0,38,0,57.1
        c0,1,0,2.1,0,3.2c-10.6,0-21.1,0-31.8,0C823.5,227.1,823.5,188.7,823.5,150z M1007.1,217.2c-25,0-49.8,0-74.8,0c0.5,8.1,1.7,15.6,6.8,21.9c0.9,1.1,1.9,2,3,2.8c11.9,8.9,29.6,4,35.1-9.7
        c0.3-0.6,1.1-1.5,1.6-1.5c9.5-0.1,19.1-0.1,29.1-0.1c-0.8,3-1.4,5.7-2.3,8.3c-4.8,13.2-14.1,22.2-27.3,26.8
        c-14.7,5.1-29.5,5.1-44.2,0.2c-16-5.3-26.3-16.6-30.9-32.7c-4.6-15.8-4.5-31.8,0.1-47.5c7.2-24.2,28.2-38.6,54-37.6
        c7.3,0.3,14.5,1.3,21.4,4.1c17,6.9,25.8,20,28.7,37.6c0.4,2.6,0.8,5.2,0.8,7.8c0,2.4-0.9,4.7-1,7.1c-0.2,3.2,0,6.4-0.1,9.6
        C1007.2,215.2,1007.1,216,1007.1,217.2z M977.2,194.7c-0.4-2.8-0.6-5.3-1.1-7.9c-2-9.6-7.6-14.9-16.8-16.3c-9.4-1.4-17.5,2-21.5,10
        c-2.2,4.3-3.3,9.2-5,14.2C948,194.7,962.4,194.7,977.2,194.7z"
      />
      <polygon points="588.6,3.3 687.3,43.2 687.3,68.2 588.9,108.1 588.9,77.5 646.5,56 588.9,33.3" />
    </g>
  </SvgAnimationWrapper>
)

accenture.propTypes = {
  className: PropTypes.string,
  entered: PropTypes.bool,
  width: PropTypes.number,
  delay: PropTypes.number,
  style: PropTypes.object,
}

export default accenture
