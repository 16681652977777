import React from 'react'
import PropTypes from 'prop-types'
import SvgAnimationWrapper from '../../../components/SvgAnimationWrapper'

const forbes = ({ className, entered, width, delay }) => (
  <SvgAnimationWrapper
    entered={entered}
    className={className}
    viewBox="0 0 1454.2 380.4"
    width={width}
    strokeOffset={1950}
    delay={delay}
    strokeWidth={3}
  >
    <g>
      <path
        d="M749.5,368.6c0-1.5,0-2.8,0-4.1c0.4-25.9,0.8-51.9,1.1-77.8c0.2-16.7,0.4-33.4,0.5-50.1
		c0.1-25.6,0.2-51.3,0.1-76.9c-0.1-18.4-0.5-36.8-0.9-55.2c-0.2-10-0.5-19.9-0.8-29.9c-0.2-7.1-1.1-14-3.5-20.7
		c-1.2-3.2-2.5-6.4-4.4-9.2c-2.1-3.2-4.8-5.6-8.5-6.9c-3.3-1.2-6.8-2-10.2-2.8c-1.9-0.4-3.7-0.9-5.5-1.4c-1.8-0.5-2.9-1.7-3-3.7
		c0-2-0.3-4-0.1-6c0.2-2.5,0.6-2.9,3-3.4c4.9-1,9.8-2.1,14.8-3C745.1,15,758,12.5,771,10c17.7-3.3,35.4-6.5,53.1-9.8
		c0.8-0.1,1.6-0.2,2.3-0.3c0.9,0.6,1.2,1.5,1.3,2.4c0.1,0.8,0.1,1.6,0.1,2.4c-0.1,21.6-0.2,43.2-0.4,64.9c-0.1,11.7-0.2,23.3-0.3,35
		c0,4.4,0,8.8,0.1,13.3c0,0.9,0.1,1.8,0.2,2.9c0.7-0.1,1.3-0.1,1.8-0.3c2.3-1,4.6-2,6.9-3.1c10.9-5.1,22.3-8,34.2-8.9
		c7.4-0.6,14.8-0.9,22.3-0.7c7.6,0.2,14.9,1.8,22.1,4.2c19.3,6.5,34.7,18.3,46.8,34.5c9.2,12.3,15.5,26,19.9,40.7
		c3,9.9,4.4,20,5,30.3c0.5,8.3,0.6,16.7,0.5,25c-0.1,11.7-1.8,23.2-4.5,34.6c-2.4,10-5.3,19.8-9.7,29.1
		c-14.8,31.7-38.7,53.4-71.4,65.5c-10.3,3.8-21,6.4-31.9,7.7c-4.2,0.5-8.4,0.7-12.6,0.8c-11,0.1-21.9,0.1-32.9,0
		c-8.1-0.1-16.1-0.9-24-2.2c-11.2-1.9-22.4-3.7-33.6-5.6c-5-0.9-10-2.1-15-3.1C750.7,369.1,750.3,368.9,749.5,368.6z M827.5,142.6
		c-0.1,0.9-0.2,1.7-0.2,2.5c-0.1,3.3-0.2,6.6-0.2,9.9c-0.1,25.7-0.3,51.5-0.4,77.2c0,17.7,0.2,35.4,0.4,53.1
		c0.2,17.4,0.6,34.8,0.9,52.2c0.1,5.4,0.4,10.8,0.7,16.3c0.2,3.4,1.2,4.5,4.5,4.9c3.1,0.4,6.2,0.6,9.3,0.7c10.1,0.3,19.2-2.5,27.1-9
		c4.7-3.8,8.6-8.4,12.1-13.4c4.1-6.1,7.5-12.6,10.3-19.4c4.7-11.5,7.9-23.4,10.2-35.5c1.4-7.2,2.5-14.5,2.9-21.8
		c0.8-13.9,0.8-27.7,0-41.6c-0.1-2.7-0.3-5.4-0.7-8.1c-1.5-10.8-4-21.3-7.8-31.5c-2.8-7.6-6.4-14.7-11.2-21.3
		c-5.8-7.9-13.2-13.7-22.3-17.4c-2.2-0.9-4.4-1.4-6.7-1.6c-4.5-0.3-9-0.2-13.6,0.2C837.7,139.3,832.6,140.9,827.5,142.6z"
      />
      <path
        d="M0.7,24.4C0.1,18.7,0.2,13.1,0,7.6c2.1-0.9,4-1,5.9-1c10.5-0.1,20.9-0.2,31.4-0.2c30.7,0,61.3-0.1,92-0.1
		c54.3,0.1,108.6,0.2,162.9,0.3c0.9,0,1.8,0,2.5,0c0.9,0.8,1,1.7,1.1,2.6c0.2,2.6,0.5,5.2,0.5,7.8c0.5,25.7,0.9,51.5,1.4,77.2
		c0.1,4.5,0.2,9,0.3,13.6c0,1.1,0,2.2,0,3.3c-0.3,0.3-0.5,0.5-0.8,0.6c-4,1.2-8,2.5-12.1,3.6c-4.4,1.1-5.6,0.5-7.6-3.7
		c-1.1-2.3-2-4.6-2.9-7c-3.2-8.5-6.7-16.8-11-24.8c-3.7-6.9-8-13.5-13-19.5c-7.8-9.3-17.4-16-28.7-20.5c-5.9-2.3-12-3.3-18.2-3.7
		c-10.8-0.8-21.7-0.6-32.6-0.7c-10,0-19.9,0-29.9,0c-0.9,0-1.8,0.1-2.9,0.1c-0.2,1-0.5,2-0.6,3c-0.2,2.5-0.5,5-0.6,7.5
		c-0.3,6.3-0.6,12.7-0.7,19c-0.6,23.8-1.2,47.7-1.5,71.5c-0.2,13.6-0.1,27.2-0.1,40.7c0,0.7,0.1,1.4,0.2,2.2
		c0.7,0.1,1.2,0.2,1.8,0.2c10.1,0.1,20.1-0.3,30.1-0.9c3.2-0.2,6.4-0.5,9.6-0.7c9.1-0.7,16.4-4.9,22.3-11.8c2.9-3.4,5.1-7.2,7-11.2
		c2.7-5.7,4.5-11.6,6-17.7c0.7-2.6,1.3-5.3,2-7.8c2.5-0.9,12.2-1.4,16.9-0.9c0.1,0.5,0.2,1.1,0.3,1.7c0.1,0.6,0,1.2,0,1.8
		c0,39.3,0,78.6,0,118c0,1.2-0.1,2.4-0.1,3.7c-0.9,0.1-1.6,0.2-2.2,0.2c-4.9-0.1-9.8-0.3-14.8-0.7c-0.7-2.9-1.4-5.6-2.1-8.3
		c-1.4-6.1-3.3-12-6-17.7c-2.1-4.4-4.5-8.5-7.7-12.2c-1.3-1.5-2.8-2.9-4.3-4.3c-3.6-3.1-7.7-5.2-12.4-5.8c-4.8-0.6-9.6-1.3-14.4-1.6
		c-10-0.6-20.1-0.9-30.1-1.3c-0.6,0-1.2,0.1-1.9,0.1c-0.1,0.9-0.2,1.6-0.2,2.4c0,11,0,21.9,0.2,32.9c0.2,10.8,0.5,21.5,0.9,32.3
		c0.5,12.7,1.1,25.3,2.5,37.9c0.8,7.8,2.1,15.5,4.7,23c1.1,3.2,2.5,6.4,4.2,9.3c1.3,2.2,2.8,4.2,4.6,6c3.1,3.1,6.6,5.2,10.8,6.2
		c5.3,1.3,10.6,2.2,16,3.1c3.2,0.5,6.3,1.2,9.5,1.8c1.3,0.3,2.2,1,2.5,2.3c1,4,1.1,7.9,0.2,11.9c-0.1,0.4-0.2,0.8-0.4,1.1
		c-0.1,0.3-0.3,0.5-0.7,0.9c-1,0.1-2.1,0.2-3.1,0.2c-9.5,0.1-18.9,0.3-28.4,0.3c-39.4,0-78.8,0-118.3,0c-11.8,0-23.5-0.2-35.3-0.4
		c-1.2,0-2.4-0.1-3.6-0.2c-0.8-1.2-1-2.4-1.1-3.6c-0.1-2.7-0.1-5.4-0.1-8.1c0-2.4,1.2-4,3.5-4.7c2.3-0.6,4.7-1.2,7-1.7
		c3.9-0.8,7.9-1.6,11.8-2.4c9.3-1.9,15.6-7.5,19.9-15.7c2.3-4.4,3.7-9.1,4.8-13.9c1.6-7.5,2.5-15.1,3.1-22.7
		c2.1-26.7,2.3-53.4,2.4-80.2c0.1-29.7,0.1-59.3-0.2-89c-0.1-13.4-0.6-26.7-1.2-40.1c-0.4-9.4-1.2-18.9-2.9-28.2
		c-0.9-5.2-2.1-10.2-4.1-15.1c-1.2-3-2.7-5.8-4.5-8.5c-2.5-3.9-5.9-6.8-10-8.9c-4.3-2.2-8.8-3.4-13.5-4.1c-4.6-0.7-9.2-1.2-13.7-1.8
		C2,24.7,1.4,24.5,0.7,24.4z"
      />
      <path
        d="M516.5,242.7c-0.4,7.2-0.7,14-1.2,20.7c-0.9,11.8-3.8,23.2-7.8,34.3c-5.1,14.2-12.3,27.3-21.9,39
		c-14.7,17.9-33.2,30.2-55.1,37.5c-7.1,2.4-14.3,4.1-21.7,5.2c-3.2,0.5-6.4,0.7-9.6,0.8c-8.4,0.1-16.9,0.1-25.3,0.1
		c-16.1-0.1-31.5-3.8-46.1-10.6c-25.1-11.7-43.2-30.4-54.8-55.4c-5.8-12.5-9.4-25.6-11.7-39.2c-2.3-13.9-3-27.8-2.2-41.8
		c0.9-17.1,3.7-33.8,10.1-49.7c9.8-24.5,25.4-44.3,47.3-59.1c11.6-7.9,24.4-13,38-16.3c14.4-3.5,28.9-4.8,43.7-4.1
		c15.8,0.8,31.1,3.7,45.8,9.8c24.5,10.1,42.7,27.1,55.1,50.5c6.7,12.5,11,25.9,13.8,39.7c1.4,6.7,2.5,13.4,2.9,20.3
		C516.3,230.5,516.3,236.8,516.5,242.7z M435.4,246.5c-0.1-13.4-0.6-26.7-1.9-40.1c-0.9-9.4-2.3-18.8-4.4-28
		c-2.1-8.9-4.8-17.6-8.9-25.9c-4-8.1-9.2-15.3-16.1-21.3c-4.5-3.9-9.6-6.2-15.5-6.8c-9.6-0.9-17.9,1.6-24.8,8.5
		c-2.5,2.5-4.6,5.3-6.5,8.3c-2.8,4.5-4.9,9.2-6.7,14.1c-5,14-7.5,28.6-8.9,43.3c-1,10.4-1.3,20.9-1.3,31.3
		c-0.1,13.5-0.1,26.9,0.7,40.4c0.2,3.3,0.4,6.6,0.8,9.9c0.8,6.9,2,13.7,3.4,20.5c2,9.6,4.6,18.9,8.4,28c2.6,6.2,5.7,12.2,9.9,17.6
		c2.5,3.3,5.5,6.2,8.8,8.6c10.8,7.9,26.3,6.9,35.7-0.9c5.6-4.7,10.1-10.1,13.3-16.6c4-8.1,6.6-16.6,8.5-25.4
		c2.7-12.6,4-25.4,4.7-38.3C435,264.8,435.1,255.7,435.4,246.5z"
      />
      <path
        d="M1240.2,231.6c-51.4,0.2-102.7,0.2-154,0.6c-0.2,0.5-0.4,0.9-0.4,1.3c0.2,14.5,1.4,28.9,5.5,42.9
		c2.4,8,5.4,15.8,9.5,23.1c2.2,4,4.7,7.7,7.8,11.1c4.4,5,9.1,9.6,14.3,13.8c10,7.9,21.4,12.6,34,14.3c9.8,1.3,19.6,1.5,29.5,0.4
		c11.8-1.3,22.7-5.2,32.7-11.5c2.5-1.6,5-3.1,7.5-4.5c3.1-1.7,3.8-1.5,6.2,1.1c1.4,1.5,2.5,3.1,3.4,5c0.6,1.4,0.7,2.7-0.1,4
		c-0.8,1.3-1.5,2.6-2.5,3.8c-2.4,2.7-4.8,5.4-7.3,8c-5.7,5.6-11.9,10.6-18.4,15.1c-6.3,4.4-13.2,7.7-20.3,10.6
		c-9.7,3.9-19.7,7-30.1,8.5c-2.9,0.4-5.8,0.7-8.7,0.8c-10.5,0.4-20.9,0.5-31.3-0.4c-7.4-0.6-14.5-2.3-21.5-4.4
		c-23-6.9-42.5-19.4-58-37.8c-10.5-12.4-18.1-26.5-23.4-41.9c-2.5-7.1-4.5-14.4-5.7-21.8c-0.9-5.3-1.2-10.6-1.5-15.9
		c-0.2-3.1-0.3-6.2-0.4-9.3c-0.3-11.2,0-22.3,1.7-33.4c2-13.1,6-25.6,11.4-37.7c4.6-10.4,10.4-20.2,17.6-29
		c16.8-20.5,38.2-33.8,63.9-40.3c12.1-3.1,24.5-4.5,37-4.2c11.6,0.3,23.1,1.8,34.1,5.6c19.9,6.8,35.4,19.2,46.9,36.6
		c6.6,10,11.2,20.9,14.5,32.4c2.7,9.4,4.5,19,5.6,28.7c0.6,5.6,1.1,11.2,1.1,16.8c0,2-0.1,4-0.2,6
		C1240.6,230.4,1240.4,230.8,1240.2,231.6z M1086.4,209.6c1.3,0,2.2,0,3,0c21.9-0.2,43.8-0.8,65.7-1.5c1.6-0.1,3.2-0.1,4.8-0.3
		c0.6,0,1.1-0.2,1.8-0.3c0-1.2,0-2.2,0-3.2c-0.1-2.5-0.3-5-0.4-7.5c-0.4-10.3-1.4-20.4-3.1-30.6c-1.2-6.8-2.8-13.6-5.3-20.1
		c-1.5-3.9-3.2-7.6-5.5-11c-1.9-2.9-4.2-5.4-7-7.4c-4.2-2.8-8.7-3.9-13.8-3.1c-4.1,0.6-7.7,2.2-11,4.6c-3.6,2.6-6.6,5.9-9.2,9.5
		c-3,4.2-5.5,8.7-7.5,13.4c-7.2,16.5-10.6,33.9-12.3,51.7C1086.2,205.5,1086.4,207.3,1086.4,209.6z"
      />
      <path
        d="M1257.2,365.1c-0.7-4.3-2.3-72.9-1.9-77.9c3.4-2.5,8.4-3.7,14-3.3c0.3,0.7,0.7,1.5,1.1,2.3
		c2.4,5.7,4.7,11.5,7.3,17.2c4.2,9.2,9.2,17.9,15.4,25.8c4.3,5.5,9.2,10.4,14.9,14.4c5.8,4.1,12.2,7.1,19.2,8.5
		c5.4,1.1,11,1.3,16.5,1.1c0.8,0,1.6-0.1,2.4-0.1c9.3-0.3,17.1-4.2,23.9-10.3c6.1-5.4,9.5-12.4,10.8-20.4c0.8-4.7,0.9-9.4,0.2-14.1
		c-0.9-5.8-3.3-10.9-7.2-15.3c-2.7-3-5.9-5.5-9.2-7.8c-4.1-2.7-8.4-5.1-12.8-7.3c-9.1-4.5-18.6-8.2-28-12
		c-6.1-2.5-12.3-5.1-18.3-7.8c-8.2-3.7-15.8-8.4-22.4-14.6c-2.3-2.1-4.5-4.4-6.5-6.7c-8.4-9.4-13.3-20.5-15.2-32.9
		c-0.9-5.6-1.3-11.2-1.2-16.8c0.2-18.3,6.2-34.5,17.8-48.5c12-14.5,27.1-24.1,45-29.4c9.2-2.7,18.6-4.3,28.1-5
		c10.8-0.9,21.5-0.4,32.2,0.6c11.4,1,22.7,2.8,33.9,5.5c6.8,1.7,13.6,3.7,20,6.5c0.6,0.3,1.3,0.6,2.1,1c0.9,23.1,1.4,46.2,1.8,69.4
		c-0.7,0.4-1.2,0.7-1.9,1c-1.8,0.7-3.6,1.3-5.4,2c-0.9,0.3-1.7,0.5-2.6,0.7c-1.9,0.4-3.4-0.2-4.4-1.9c-0.7-1.2-1.4-2.5-1.9-3.8
		c-1.1-2.6-2.1-5.2-3.1-7.9c-2.9-7.3-6.1-14.4-10.1-21.2c-3.4-5.7-7.3-11.1-12.1-15.7c-9.8-9.4-21.6-14.3-35-14.8
		c-9.7-0.4-18.1,3.1-24.9,10.2c-5.9,6.2-9,13.5-9.5,21.9c-0.2,3.5-0.2,7,0.1,10.5c0.4,4.7,2.4,8.9,5.3,12.6c2.8,3.6,6,6.8,9.6,9.5
		c4.5,3.5,9.3,6.4,14.2,9.1c9.2,5,18.8,9.2,28.4,13.2c9.6,4,19,8.3,28,13.4c5.9,3.4,11.4,7.1,16.5,11.5c10,8.7,16.6,19.5,19.9,32.4
		c0.9,3.4,1.4,6.9,1.6,10.4c0.5,9,0.3,17.9-0.6,26.8c-0.2,2.1-0.6,4.2-1.2,6.2c-4.2,15.7-11.9,29.4-24,40.4
		c-8.4,7.6-17.9,13.2-28.4,17.4c-7.9,3.2-16,5.5-24.3,7.1c-2.4,0.5-4.8,0.7-7.2,0.9c-15.2,0.7-30.4,0.7-45.5,0.4
		c-3.6-0.1-7.2-0.6-10.8-1.1c-12.8-1.9-25.5-4.5-38.1-7.8c-4.9-1.3-9.7-2.7-14.3-4.8C1258.8,365.9,1258,365.5,1257.2,365.1z"
      />
      <path
        d="M672.6,357.8c0.7,3.1,0.7,11.1,0,15.6c-0.6,0.1-1.2,0.3-1.9,0.3c-3.8,0-7.6,0.1-11.5,0.1
		c-32.6,0.1-65.2,0.3-97.8,0.3c-13.5,0-26.9-0.3-40.4-0.4c-0.6,0-1.2,0-1.8,0c-2.7-0.1-3.2,0.2-3.6-3.5c-0.1-1.3-0.1-2.6,0-3.9
		c0.1-2.6,0.3-5.2,0.4-7.8c1-0.6,2.1-0.8,3.2-1c3.3-0.7,6.7-1.3,10.1-2c1.5-0.3,2.9-0.7,4.4-1.2c5.1-1.7,9-4.9,11.6-9.5
		c2-3.5,3.3-7.3,4-11.3c1-5.7,1.4-11.4,1.7-17.1c0.8-14.8,1.2-29.5,1.2-44.3c0.1-22.5,0.1-45.1-0.1-67.6c-0.1-9.2-0.6-18.5-1.1-27.7
		c-0.3-5.5-1-11-2.3-16.4c-1-4.1-2.6-8-5.3-11.4c-2.3-2.8-5.1-4.9-8.6-5.8c-3.7-0.9-7.5-1.6-11.2-2.4c-1.9-0.4-3.7-0.8-5.6-1.1
		c-1.4-0.2-2.3-1-2.3-2.4c-0.1-2.6-0.1-5.2,0-7.8c0.1-1.6,1.1-2.6,2.6-3c1.7-0.4,3.3-0.8,5-1.2c14-2.9,27.9-5.8,41.9-8.6
		c13.8-2.8,27.6-5.5,41.4-8.2c6.6-1.3,13.2-2.5,19.9-3.7c1.2-0.2,2.4-0.3,3.7,0c0.3,3.6,0,7.1-0.1,10.6c-0.1,3.6-0.3,7.2-0.4,10.9
		c-0.1,3.5-0.2,7-0.3,10.6c-0.1,3.6-0.3,7.2-0.4,10.9c-0.1,3.5-0.4,6.9-0.3,11c0.7-1,1.1-1.4,1.3-1.9c1.5-2.6,2.9-5.3,4.4-7.9
		c5-8.5,10.8-16.5,17.9-23.4c8.5-8.3,18.3-14.4,29.4-18.5c8.3-3.1,16.9-3.7,25.6-3.3c5.4,0.2,10.8,1,16,2.7c1.7,0.6,3.4,1.2,5,2
		c1,0.5,1.9,1.1,2.8,1.7c1.7,1.2,2.2,2.8,1.5,4.8c-1.2,3.5-2.3,7.1-3.6,10.6c-6.3,17.5-12.9,34.9-19.8,52.1
		c-0.7,1.8-1.5,3.5-2.3,5.2c-0.4,1-1,1.2-2,0.9c-0.9-0.2-1.7-0.5-2.6-0.9c-2.8-1.2-5.5-2.4-8.3-3.7c-6.7-3.1-13.6-5.5-20.9-6.7
		c-11.7-1.9-23-0.4-33.9,4.3c-2.6,1.1-5,2.6-7.2,4.4c-1.9,1.5-3.1,3.4-3.2,5.9c-0.1,1.8-0.4,3.6-0.4,5.4c-0.7,21-0.9,42-0.7,63
		c0.1,12.9,0.3,25.7,0.8,38.6c0.4,10,1.1,20.1,1.8,30.1c0.3,4.6,1,9.2,2.3,13.6c0.7,2.4,1.6,4.8,2.7,7c2.9,5.5,7.3,9,13.5,10
		c4.5,0.7,8.9,1.4,13.4,2c2.8,0.4,5.6,0.5,8.4,0.8C671.3,357.5,672,357.7,672.6,357.8z"
      />
    </g>
  </SvgAnimationWrapper>
)

forbes.propTypes = {
  className: PropTypes.string,
  entered: PropTypes.bool,
  width: PropTypes.number,
  delay: PropTypes.number,
}

export default forbes
