import React from 'react'
import PropTypes from 'prop-types'
import SvgAnimationWrapper from '../../../components/SvgAnimationWrapper'

const awww = ({ className, entered, width, delay }) => (
  <SvgAnimationWrapper
    entered={entered}
    className={className}
    viewBox="0 0 590.5 66.4"
    width={width}
    strokeOffset={510}
    delay={delay}
    strokeWidth={3}
  >
    <g>
      <path
        d="M338.3,48.9h-22.5l-5.4,16.5h-12L321,1.1h12.2l22.6,64.2h-12L338.3,48.9z M327,12.2c0,0-1.5,6.3-2.7,9.9
					l-5.7,17.2h16.8l-5.7-17.2c-1.2-3.6-2.5-9.9-2.5-9.9H327z"
      />
      <path
        d="M373.1,1.1h20.7c7,0,10.1,0.5,12.9,1.8c6.4,2.9,10.5,9,10.5,17.5c0,7.7-4.1,14.8-10.9,17.6v0.2
					c0,0,0.9,0.9,2.2,3.2l13.2,24h-13l-12.8-24h-11.2v24h-11.7L373.1,1.1L373.1,1.1L373.1,1.1L373.1,1.1z M395.2,31.2
					c6.2,0,10.1-3.7,10.1-10.1c0-6.1-2.6-9.9-11.8-9.9h-8.9v20.1L395.2,31.2L395.2,31.2z"
      />
      <path
        d="M441.7,1.1h21.9c19.8,0,32.7,11.8,32.7,32c0,20.3-12.9,32.2-32.7,32.2h-21.9V1.1z M462.9,55.3
					c13,0,21.3-7.7,21.3-22.2c0-14.3-8.5-22-21.3-22h-9.6v44.1H462.9L462.9,55.3z"
      />
      <path
        d="M517.2,48.9c0,0,7,6.8,16,6.8c4.9,0,9.3-2.5,9.3-7.8c0-11.5-30.3-9.5-30.3-29.2C512.2,8,521.5,0,533.8,0
					C546.4,0,553,6.9,553,6.9l-5.1,9.5c0,0-6.2-5.6-14.2-5.6c-5.4,0-9.7,3.2-9.7,7.7c0,11.4,30.2,8.6,30.2,29.1
					c0,10.2-7.8,18.8-21.2,18.8c-14.3,0-22.2-8.8-22.2-8.8L517.2,48.9z"
      />
      <path
        d="M206.7,1.1l-10.6,44.4c-0.8,3.6-1.1,6.8-1.1,6.8h-0.2c-0.1,0-0.2-3.1-1.2-6.8L182.2,1.1h-10.1l-11.6,44.4
					c-1,3.7-1.1,6.8-1.2,6.8h-0.2c0,0-0.3-3.2-1.1-6.8l-7.6-33.3l-5.7,24.3l7.2,28.8h13.5l9.9-38c1.1-4.2,1.6-8.4,1.6-8.4h0.2
					c0,0,0.5,4.2,1.6,8.4l9.9,38h13.5l16.6-64.2L206.7,1.1L206.7,1.1z"
      />
      <path
        d="M279.1,1.1l-10.6,44.4c-0.8,3.6-1.1,6.8-1.1,6.8h-0.2c-0.1,0-0.2-3.1-1.2-6.8L254.7,1.1h-10.1L233,45.5
					c-1,3.7-1.1,6.8-1.2,6.8h-0.2c0,0-0.3-3.2-1.1-6.8l-7.6-33.4l-5.7,24.3l7.3,29H238l9.9-38c1.1-4.2,1.6-8.4,1.6-8.4h0.2
					c0,0,0.5,4.2,1.6,8.4l9.9,38h13.5l16.6-64.2L279.1,1.1L279.1,1.1z"
      />
      <path
        d="M40,48.9H17.5L12,65.3H0L22.6,1.1h12.2l22.6,64.2h-12L40,48.9z M28.7,12.2c0,0-1.5,6.3-2.7,9.9l-5.7,17.2
					h16.8l-5.7-17.2c-1.2-3.6-2.5-10-2.5-10L28.7,12.2L28.7,12.2z"
      />
      <path
        d="M121.2,45.5c1,3.7,1.1,6.8,1.2,6.8h0.2c0,0,0.3-3.2,1.1-6.8l10.6-44.4h12l-16.6,64.2h-13.5l-9.9-38
					c-1.1-4.2-1.6-8.4-1.6-8.4h-0.2c0,0-0.5,4.2-1.6,8.4l-9.9,38H79.5L63.4,1.1h12l10.1,44.4c0.8,3.6,1.1,6.8,1.1,6.8h0.2
					c0.1,0,0.2-3.1,1.2-6.8L99.6,1.1h10.1L121.2,45.5z"
      />
      <path
        d="M579.4,22c6.2,0,11,5,11,11.2c0,6.3-4.9,11.2-11,11.2c-6,0-10.9-4.9-10.9-11.2C568.5,27,573.4,22,579.4,22z
				 M579.4,43.1c5.5,0,9.6-4.3,9.6-9.9c0-5.6-4.2-9.9-9.6-9.9c-5.4,0-9.5,4.3-9.5,9.9C569.9,38.8,574.1,43.1,579.4,43.1z M575.8,27
				h4.1c2.1,0,3.6,1.4,3.6,3.6c0,1.8-1.1,3-2.4,3.3V34c0,0,0.1,0.2,0.4,0.7l2.4,4.7h-1.5l-2.6-5.2h-2.7v5.2h-1.3L575.8,27L575.8,27z
				 M579.8,33.1c1.4,0,2.4-0.9,2.4-2.4c0-1.5-0.9-2.4-2.4-2.4h-2.6v4.8L579.8,33.1L579.8,33.1z"
      />
    </g>
  </SvgAnimationWrapper>
)

awww.propTypes = {
  className: PropTypes.string,
  entered: PropTypes.bool,
  width: PropTypes.number,
  delay: PropTypes.number,
}

export default awww
